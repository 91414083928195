import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UtilsService } from './../utils/utils.service';

import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PagamentoService {
  private environment = environment;

  constructor(
    private readonly http: HttpClient,
    private readonly utilsService: UtilsService
  ) {}

  public async sendPayment(dadosPagamento) {
    let url = `${this.environment.API_AWS}/api/Payment`;

    return await this.http.post<any>(url, dadosPagamento, { headers: this.utilsService.obterHeaderAuthorization() }).toPromise();
  }

  public async sendFile(faturaId) {
    let url = `${this.environment.API_AWS}/api/Payment/${faturaId}/sendfile:engloba`;
    console.log(">>> url", url);
    const ret = await this.http.post<any>(url, {}, { headers: this.utilsService.obterHeaderAuthorization() }).toPromise();
    console.log(">>> sendFile :: ret", JSON.stringify(ret));
    return ret;
  }  
}
