<section class="hero">
    <app-header></app-header>
    <div class="mt-4 pt-5"></div>
</section>

<section>
    <div class="py-5 header-faq">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 mx-auto">
                    <div class="text-center conteudo-header-faq">
                        <h2 class="my-5">Dúvidas</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="py-5 ">
    <div class="container">

        <div class="align-items-center text-center">
            <h2 class="mt-4 mb-3">Qual a sua dúvida?</h2>
            <p style="margin-bottom: 0;">
                Clique nas perguntas abaixo e entenda como o ChegaLog pode te ajudar.
            </p>
            <p style="margin: 0;" class="text-center">Não encontrou a resposta para sua dúvida, entre em contato pelo
                <a
                href="javascript:void(0);"
                (click)="contato()">
                Fale conosco.
                </a>
            </p>
        </div>
        <div class="mt-5">
            <app-accordeon></app-accordeon>
        </div>
    </div>
</section>
<section class="py-5 ">
    <div class="container">
        <div class="mt-5">
            <div class="text-center px-5">
                <h1 class="texto-otimizamos"><span class="text-primary">Otimizamos</span><br/> a sua logística de forma simples, rápida e barata!</h1>
            </div>
            <div class="my-5 text-center">
                <button class="btn btn-primary btn-lg cta px-5 m5-4 mt-lg-0" (click)="simular()">FAÇA SUA COTAÇÃO AGORA</button>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>