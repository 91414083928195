import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private router: Router

  ) { }

  ngOnInit(): void {
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  faq() {
    this.router.navigate(['/faq']);
  }

  contato() {
    window.scrollTo(0, 0);
    this.router.navigate(['/contato']);
  }

}
