import { FreteService } from './../../services/frete/frete.service';
import { UtilsService } from './../../services/utils/utils.service';
import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/services/localStorage/localstorage.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CurrencyPipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.scss']
})
export class PainelComponent implements OnInit {
  public nomeUsuario;
  public user;
  public valorTotalCobrancas = 0;
  public totalNotasFiscais = 0;
  public totalPendentes = 0;
  public totalEntregues = 0;
  public mostrarDetalhe = false;
  public ordensFaturadas = [];
  public pedidosEntregues : any[] = [];
  public chaves: any[] = [];
  public carregandoPedidosPendentes = true;
  public listaHistorico = [];
  public loadingHistorico = true;
  public arquivosEfetivados = [];
  public totalItems = [];
  public pedidosPendentes = [];
  public mostrandoPendentes = false;
  public mostrandoCancelados = false;
  public mostrandoEntregues = false;
  public itensCancelados = [];
  public totalCancelados = 0;
  public totalAguardandoProcessamento = 0;
  public totalAguardandoXML = []
  public totalFaturadas = 0;
  public totalItensTabela = 0;
  public totalNotasPendentes = 0;
  public modalRef: BsModalRef;
  public modalRefCancelamento: BsModalRef;

  public statusAguardandoXML = [];
  public cnpjsPotspeed = [];
  public loadingCancelamento = false;
  public objetoEstorno: any = {};
  public quantidadePorPagina = 5;
  public skip = 0;
  public totalPaginas = [];
  public total = 0;
  public totalPedidos = 0;
  public fretes = [];

  constructor(
    private storage: LocalStorageService,
    private usuarioService: UsuarioService,
    private router: Router,
    private modalService: BsModalService,
    private freteService: FreteService,
    private utilsService: UtilsService
  ) {
    this.user = this.storage.obterUsuario();
    this.nomeUsuario = this.user.nome.split(' ')[0];
  }
  ngOnInit() {
    this.obterUsuario();
    this.obterCnpjs();
    this.skip = this.getStatePage();
    this.quantidadePorPagina = this.getStateQtde();
    this.obterPedidos(this.skip, this.quantidadePorPagina);
    // this.modalService.onHidden.subscribe( close => {
    //   if (this.modalService.getModalsCount() == 0) {
    //     this.obterUsuario();
    //   }
    // });
  }
  reset() {
    this.ordensFaturadas = [];
    this.pedidosEntregues = [];
    this.chaves = [];
    this.carregandoPedidosPendentes = true;
    this.listaHistorico = [];
    this.loadingHistorico = true;
    this.arquivosEfetivados = [];
    this.totalItems = [];
    this.pedidosPendentes = [];
    this.mostrandoPendentes = false;
    this.mostrandoCancelados = false;
    this.mostrandoEntregues = false;
    this.itensCancelados = [];
    this.totalCancelados = 0;
    this.totalAguardandoProcessamento = 0;
    this.totalAguardandoXML = []
    this.totalFaturadas = 0;
    this.totalItensTabela = 0;
    this.totalNotasPendentes = 0;
    this.statusAguardandoXML = [];
  }
  async obterCnpjs() {
    this.cnpjsPotspeed = await this.utilsService.obterCnpjsPotSpeed().toPromise();
  }
  obterUsuario() {
    this.reset();

    //this.obterPedidos(0, this.quantidadePorPagina);

    this.usuarioService.obterUsuario(this.user._id).subscribe( res => {
      this.user = res;
      // this.user.fretes.reverse();
      this.storage.setarUsuario(this.user);

      this.arquivosEfetivados = this.user.arquivos.filter( arquivo => arquivo.efetivada && arquivo.chave).reverse()
      var indi = 0;
      // this.chaves = [];
      // this.user.fretes.map( frete => {
      //   frete.destinos.map( destino => {
      //     if (destino.pendenteNotaFiscal) {
      //     }
      //     if (destino.notaFiscal.chave) {
      //       this.chaves.push(destino.notaFiscal.chave);
      //     }
      //   });
      // })
      this.nomeUsuario = this.user.nome.split(" ")[0];

      //this.obterPedidos(0, this.quantidadePorPagina);
      // this.aguardandoXML();
      this.verificaOrdensFaturadas();
    });


  }
  verificaOrdensFaturadas() {
    // this.ordensFaturadas = this.user.fretes;
    // this.totalFaturadas = 0;
    // this.ordensFaturadas.map( frete => {
    //   this.totalFaturadas += parseFloat(frete.fatura.amount);
    // });
  }
  aguardandoXML() {
    var total = 0;
    this.totalAguardandoXML = [];

    // this.user.fretes.map( frete => {
    //   // var valorTotalEstornado = 0;
    //   frete.valorTotalEstornado = 0;
    //   var arrayCancelados = frete.destinos.filter(d => d.status == 'cancelado');
    //   arrayCancelados.map( a => {
    //     if (a.valorFrete != undefined) {
    //       frete.valorTotalEstornado += Number(a.valorFrete);
    //     }
    //   });

    //   frete.destinos.map( destino => {
    //     destino.isLoading = false;
    //     destino.errors = [];

    //     if (destino.pendenteNotaFiscal) {
    //       total++;
    //       // COLOCA APENAS OS FRETES NA LISTA QUE POSSUEM ALGUM DESTINO COM NOTA FISCAL PENDENTE
    //       if (this.totalAguardandoXML.indexOf(frete) === -1) {
    //         this.totalAguardandoXML.push(frete)
    //       }
    //       this.statusAguardandoXML.push({
    //         isLoading: false,
    //         error: ''
    //       })
    //     }
    //   });
    //   // frete['valorTotalEstornado'] = valorTotalEstornado;
    // });
    this.totalNotasPendentes = total;
  }
  groupBy = function (list, field) {
    let result = [];
    for (var i = 0; i < list.length; i++) {
      if (list[i].hasOwnProperty(field)) {
        let obj = {
          field: null,
          itens: []
        }
        //exist
        for (var ii = 0; ii < result.length; ii++) {
          if (result[ii].field == list[i][field]) {
            obj = result[ii];
            break;
          }
        }
        if (!obj.field) {
          obj.field = list[i][field];
          obj.itens.push(list[i]);
          result.push(obj);
        }
        else
          obj.itens.push(list[i]);
      }
    }
    return result;
  }
  obterPedidos(skip = 0, limit = 5, statusPedido = null) {
    this.freteService.listarPedidosPendentes(skip * limit, limit).subscribe(retorno => {
    // var chamadasChave = [];
    // var pedidos = [];
    // var lista = [];
    // var aguardandoProcessamento = []
    this.loadingHistorico = true;
    // this.pedidosPendentes = [];
    // // this.totalPendentes = 0;

    // // this.usuarioService.obterPedidoPorUsuario(this.user._id, (skip == 0 ? skip : (skip * limit)), limit, statusPedido).subscribe( retorno => {
    //   this.freteService.listarPedidos().subscribe( retorno => {

      var results = retorno.pedidos;
    //   var comPedencia = retorno.fretes?.filter(frete => frete.statusFrete == 'criado');

    //   if (comPedencia.length > 0) {
    //     this.totalNotasPendentes = comPedencia.length;
    //   }
    //   this.total = retorno.total;
    //   var totalPaginas = (this.total / limit);
    //   this.totalPaginas = [];


      // if (!statusPedido) {
        this.totalPedidos = retorno.total;
        this.totalEntregues = retorno.totalEntregues;
        this.totalPendentes = retorno.totalPendentes;
        this.totalCancelados = retorno.totalCancelados;
        this.totalNotasPendentes = retorno.totalCriados;

      // }
      this.totalPaginas = [];
      for (var i = 0; i < this.totalPedidos / this.quantidadePorPagina ;i++) {
        this.totalPaginas.push(i);
      }

      this.listaHistorico = results;

      this.carregandoPedidosPendentes = false;
      this.loadingHistorico = false;
    //   chamadasChave = [];

    // }, err => {
    //   this.carregandoPedidosPendentes = false;
    //   this.loadingHistorico = false;
    // });
    });
  }
  formatarData(stringDate) {
    var dia = stringDate.split("/")[0];
    var mes = stringDate.split("/")[1];
    var ano = stringDate.split("/")[2].split(" ")[0];
    return new Date(`${mes}/${dia}/${ano}`);
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.listaHistorico = this.arquivosEfetivados.slice(startItem, endItem);
  }
  mostrarPedidosPendentes() {
    // this.listaHistorico = pendentes;
    // this.mostrandoPendentes = true;
    // this.mostrandoEntregues = false;
    // this.mostrandoCancelados = false;

    this.obterPedidos(0, this.quantidadePorPagina, 'pendente')


  }
  mostrarTodos() {
    // this.listaHistorico = this.totalItems;
    // this.mostrandoPendentes = false;
    // this.mostrandoCancelados = false;
    // this.mostrandoEntregues = false;
    this.obterPedidos(0, this.quantidadePorPagina);
  }
  mostrarCancelados() {
    this.listaHistorico = this.itensCancelados;
    this.mostrandoCancelados = true;
    this.mostrandoPendentes = false;
    this.mostrandoEntregues = false;
  }
  mostrarEntregues() {
    // this.listaHistorico = this.pedidosEntregues;
    // this.mostrandoEntregues = true;
    // this.mostrandoPendentes = false;
    // this.mostrandoCancelados = false;
    this.obterPedidos(0, this.quantidadePorPagina, 'entregue');
  }
  abrirModalEnviarArquivo(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  abrirModalCancelarFrete(template: TemplateRef<any>, frete, destino) {
    this.objetoEstorno = {
      frete,
      destino
    }
    this.modalRefCancelamento = this.modalService.show(template, {class: 'w-350 modal-dialog-centered', ignoreBackdropClick: true});
  }
  confirmarCancelamento() {
    this.loadingCancelamento = true;

    this.usuarioService.cancelarPedido(this.user._id, this.objetoEstorno.frete._id, this.objetoEstorno.destino._id, 'cancelado', this.user, this.objetoEstorno.frete).subscribe( res => {

      if (res.n == 1) {
        this.objetoEstorno.destino.status = 'cancelado';

        this.usuarioService.solicitarEstorno(this.objetoEstorno.frete.fatura.idCobranca, this.objetoEstorno.destino.valorFrete).subscribe( vindiRes => {
          this.loadingCancelamento = false;
          this.modalRefCancelamento.hide();
        }, erroVindi => {
          this.loadingCancelamento = false;
          this.modalRefCancelamento.hide();
        });
      } else {
        this.loadingCancelamento = false;
        this.modalRefCancelamento.hide();
      }

    }, erroApi => {
      this.loadingCancelamento = false;
      this.modalRefCancelamento.hide();
    });
  }
  fecharModal(template: TemplateRef<any>) {
    this.modalRefCancelamento.hide();
  }
  fileChange(frete, destino, event) {
    const filesParaEnviar: Set<File> = new Set();
    destino.isLoading = true;

    for(let i = 0; i < event.target.files.length; i++) {

      if (event.target.files[i].type != "text/xml") {
        destino.errors.push("Arquivo inválido!");
      } else {
        filesParaEnviar.add(event.target.files[i]);
      }
    }

    if ( event.target.files.length > 0) {
      this.fazerUpload(filesParaEnviar, frete, destino);
    } else {
      event.target.value = '';
      destino.isLoading = false;
    }
  }
  async fazerUpload(arquivos, frete?, destino?) {
    let isCotacao = false;
    var progress: any = await this.freteService.uploadSimulacao(arquivos, this.user._id, isCotacao);
    var docs = [];
    let allProgressObservables = [];
    var possuiNotaDiferenteCNPJ = false;
    var possuiDuplicadas = false;
    var valorNotaDiferente = false;

    for (let key in progress) {
      allProgressObservables.push(progress[key].progress);
    }

    forkJoin(allProgressObservables).subscribe(async (end: any) => {
      for (const key in progress) {
        var chave = progress[key].doc.nfeProc.protNFe.infProt.chNFe;
        var cepNota = progress[key].doc.nfeProc.NFe.infNFe.dest.enderDest.CEP;
        var valorNota = progress[key].doc.nfeProc.NFe.infNFe.total.ICMSTot.vNF;
        // var verificarValorFrete = await this.usuarioService.calcularFrete(0, valorNota, cepNota).toPromise();
        // var cnpjTransportadoraNota = progress[key].doc.nfeProc.NFe.infNFe.transp.transporta.CNPJ
        var uFEmissor = progress[key].doc.nfeProc.NFe.infNFe.emit.enderEmit.UF;
        var cnpjsValidos = [];
        this.cnpjsPotspeed.map( pot => {
          if (pot.UF == uFEmissor) {
            cnpjsValidos.push(pot);
          }
        });
        // var mesmaTransportadora = this.usuarioService.verificarTransportadora(cnpjTransportadoraNota, cnpjsValidos);
        var mesmaTransportadora = true;
        var cepDiferente = false;

        // MESMO CNPJ NOTA vs CADASTRO
        if (!progress[key].isMesmoCNPJ) {
          possuiNotaDiferenteCNPJ = true;
          destino.errors.push("A nota fiscal não possui o mesmo CNPJ do emissor, por favor envie uma nota fiscal com o CNPJ de sua empresa.");
        }
        // POSSUI ARQUIVO DUPLICADO
        if (progress[key].duplicada) {
          possuiDuplicadas = true;
          destino.errors.push(`A chave "${chave}" já está em uso, por favor utilize outra`);
          var removerArquivo = await this.usuarioService.removerArquivosDoCliente(progress[key].idArquivo, this.user._id).toPromise();
          // SE AO TENTAR APAGAR O ARQUIVO ACONTECEU ALGUM PROBLEMA MOSTRA MENSAGEM PARA TENTAR NOVAMENTE
          if (!removerArquivo.success) {
            destino.errors.push(`Ops, houve um problema com o servidor, tente novamente em 10 minutos por favor!`);
          }
        }
        // CEP DO ARQUIVO É DIFERENTE DO CEP DA NOTA FISCAL
        if (destino.cepDestino != cepNota) {
          cepDiferente = true;
          destino.errors.push(`O CEP: ${cepNota} é diferente do CEP que você realizou a compra, por favor nos envie a nota fiscal com o endereço correto`);
          var removerArquivo = await this.usuarioService.removerArquivosDoCliente(progress[key].idArquivo, this.user._id).toPromise();
          // SE AO TENTAR APAGAR O ARQUIVO ACONTECEU ALGUM PROBLEMA MOSTRA MENSAGEM PARA TENTAR NOVAMENTE
          if (!removerArquivo.success) {
            destino.errors.push(`Ops, houve um problema com o servidor, tente novamente em 10 minutos por favor!`);
          }
        }

        if (parseFloat(destino.valorNf) != parseFloat(valorNota)) {
          var valorNotaValidacao = parseFloat(valorNota).toString().replace(".", ",");
          valorNotaDiferente = true;
          destino.errors.push(`O valor da nota fiscal de R$ ${valorNotaValidacao}  é diferente do valor que você realizou a cotação.`);
          var removerArquivo = await this.usuarioService.removerArquivosDoCliente(progress[key].idArquivo, this.user._id).toPromise();

          if (!removerArquivo.success) {
            destino.errors.push(`Ops, houve um problema com o servidor, tente novamente em 10 minutos por favor!`);
          }
        }

        // mesma transportadora
        if (!mesmaTransportadora) {
          destino.errors.push(`A transportadora informada no XML não pertence a Pot Speed, você deve emitir a nota fiscal com o CNPJ ${cnpjsValidos[0].cnpj}`);
        }

        destino.isLoading = false;

        if (!possuiNotaDiferenteCNPJ && !possuiDuplicadas && !cepDiferente && mesmaTransportadora && !valorNotaDiferente) {
          destino.pendenteNotaFiscal = false;
          var uploadEngloba = await this.usuarioService.uploadEngloba(this.user._id, [chave]).toPromise();
          this.usuarioService.atualizarArquivoPendente(this.user._id, frete._id, destino._id, progress[key].idArquivo).toPromise();
        }
        // docs.push(progress[key].doc);
        // docs[docs.length -1]._id = progress[key]._id;
        // docs[docs.length -1].tipo = progress[key].tipo;
        // docs[docs.length -1].idArquivo = progress[key].idArquivo;

      }
    });
  }
  limparErros(destino?) {
    // destino.isLoading = false;
    destino.errors = [];
  }
  limparErro(erro, erros) {
    let indice = erros.indexOf(erro);

    if (indice > -1) {
      erros.splice(indice, 1);
    }

  }
  clicouAquiInputFile(event) {
    event.srcElement.value = "";
  }

  saveState() {
    window.localStorage.setItem('painelPage', this.skip.toString());
    window.localStorage.setItem('painelQtdePage', this.quantidadePorPagina < 5 ? "5" : this.quantidadePorPagina.toString());
  }

  getStatePage() {
    return Number(window.localStorage.getItem('painelPage') ?? 1);
  }

  getStateQtde() {
    return Number(window.localStorage.getItem('painelQtdePage') ?? 5);
  }

  onChangeQuantidadePagina(value) {
    this.skip = 0;
    this.quantidadePorPagina = value;
    this.saveState();
    this.obterPedidos(this.skip, Number(value));
  }
  anterior() {
    if (this.skip > 0) {
      this.skip--
      this.saveState();
      this.obterPedidos(this.skip, this.quantidadePorPagina);
    }
  }
  proximo() {
    this.skip++
    this.saveState();
    this.obterPedidos(this.skip, this.quantidadePorPagina);
  }
  irParaPagina(pagina) {
    this.skip = pagina;
    this.saveState();
    this.obterPedidos(this.skip, this.quantidadePorPagina);
  }
}
