export interface Frete {
    id: string,
    /* DADOS DA VINDI  */
    numeroPedido?: string,
    tipoDocumentoFiscal: string,
    fatura?:  {},
    pedidos: [
        {

            numeroRastreio?: string,
            declaracao?: boolean,
            status: string,
            notaFiscal: {
                idTemporario: string
            },
            pendenteNotaFiscal: boolean,
            cepOrigem: string,
            cepDestino: string,
            // TIPO = TIPO DA MERCADORIA
            tipo: string,
            // tipoPreenchimento = Tipo do preenchimento na cotação (form, xls ou xml)
            tipoPreenchimento: string,
            valorNf: string,
            valorFrete: string,
            volumes: [
                {
                    quantidade: string,
                    peso: string,
                    altura: string,
                    largura: string,
                    comprimento: string
                }
            ]
        }
    ]
}
export class Frete {
    frete : Frete;

    constructor(frete: Frete) {
        var aFrete = frete;
        aFrete.id = (Math.floor(Math.random() * 50000) + 10000).toString();
        this.frete = aFrete;
    }
}