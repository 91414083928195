import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';
@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent implements OnInit {
  public volumes: any [] = [];
  mercadoriaForm: FormGroup;
  mercadoriasItems: FormArray;
  itensVolumes: FormArray;
  usuario;
  bookFg: FormGroup;
  allBooks = [];

  tipoCliente = {
    tipo: ''
  }

  get mercadorias(): any {
    return this.mercadoriaForm;
  }
  @Input() set mercadorias(value: any) {
    this.mercadoriaForm = value;
  }
  @Input() origem: any = {cep: ''}
  @Input() isModal = false;
  @Output() isValid = new EventEmitter();
  @Output() receberForm = new EventEmitter();

  public ngxCurrencyOptions = {
    prefix: 'R$ ',
    thousands: '.',
    decimal: ',',
    allowNegative: false,
    nullable: false,
    max: 250_000_000,
  };
  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private router: Router,
    private localStorage: LocalStorageService
  ) {
    this.mercadorias = this.mercadoriaForm;
    this.usuario = this.localStorage.obterUsuario();
  }
  ngOnInit() {
    this.mercadoriaForm = this.formBuilder.group({
      mercadoriasItems: this.formBuilder.array([this.mercadoriasItem()]),
    });

    this.mercadoriaForm.get('mercadoriasItems')['controls'][0].controls['cepOrigem'].valueChanges.subscribe( a => {
      this.mercadoriaForm.get('mercadoriasItems')['controls'].map( itemControl => {
        itemControl.controls['cepOrigem'].setValue(a, {onlySelf: true, emitEvent: false})

      });
    });
    this.mercadoriaForm.valueChanges.subscribe( x => {
      this.mercadoriaForm.valid;

      this.isValid.emit(this.mercadoriaForm.valid);
      this.receberForm.emit(this.mercadoriaForm)
    });


  //   this.mercadoriaForm.controls['param'].valueChanges.subscribe(x=> {
  //     this.formGroup.controls['param'].setValue(x, {onlySelf: true, emitEvent: false});
  //  });

  }

  getControls(formGrp: FormGroup, key: string) {
    return (<FormArray>formGrp.controls[key]).controls;
  }
  mercadoriasItem(cepOrigem?): FormGroup {
    const cep = this.usuario?.endereco?.cep || this.origem?.cep || null;

    return this.formBuilder.group({
      cepOrigem: new FormControl(cepOrigem ? cepOrigem : (cep ? cep : ''), [Validators.required], [this.usuarioService.cepValidator()]),
      cepDestino: new FormControl('', Validators.required, this.usuarioService.cepValidator()),
      valorMercadoria: new FormControl('', [Validators.min(10), Validators.required]),
      tipoMercadoria: new FormControl('', Validators.required),
      itensVolumes: this.formBuilder.array([this.volumesItems()])
    });
  }
  volumesItems(): FormGroup {
    return this.formBuilder.group({
      quantidade: new FormControl('', [Validators.min(1) ,Validators.required]),
      peso: new FormControl('', [Validators.required, Validators.max(8)]),
      altura: new FormControl('', [Validators.required, Validators.max(80)]),
      largura: new FormControl('', [Validators.required, Validators.max(80)]),
      comprimento: new FormControl('', [Validators.required, Validators.max(80)]),
    });
  }
  onSubmit(data) {
  }
  voltar() {
    this.router.navigateByUrl('/simulacao/file');
  }

  adicionarMercadoria(cepOrigem) {
    var mercadorias = this.mercadoriaForm.get('mercadoriasItems') as FormArray;
    mercadorias.push(this.mercadoriasItem(cepOrigem));
  }
  adicionarVolume(indiceMercadoria) {
    var mercadorias = this.mercadoriaForm.get('mercadoriasItems') as FormArray;
    var volumes = mercadorias.controls[indiceMercadoria].get('itensVolumes') as FormArray;
    volumes.push(this.volumesItems());
  }
  removerVolume(indiceMercadoria, indiceVolume) {
    var mercadorias = this.mercadoriaForm.get('mercadoriasItems') as FormArray;
    var volumes = mercadorias.controls[indiceMercadoria].get('itensVolumes') as FormArray;

    volumes.removeAt(indiceVolume);
  }
  removerMercadoria(i) {
    var mercadorias = this.mercadoriaForm.get('mercadoriasItems') as FormArray;
    mercadorias.removeAt(i);
    // let indice = this.mercadorias.map(function(item) {return item.id;}).indexOf(mercadoria.id);
    // this.mercadorias.splice(indice, 1);
  }
  verificarRangeCep(event, valid) {
    if (valid) {

      this.usuarioService.rangeCepPegaKi(event.target.value).subscribe( res => {
        if (res.inRange) {
          this.localStorage.setarTipoCliente('pegaki');
        } else {
          this.localStorage.setarTipoCliente('normal');
        }
      });

      this.usuarioService.buscarEnderecoByCEP(event.target.value).subscribe( retorno => {
        this.usuarioService.range(event.target.value, retorno.state).then( res => {
          if (res.success) {
            this.localStorage.setarIdTabelaPreco(res.data.idTabelaPreco);
          }
        });
      });
    }
  }
}
