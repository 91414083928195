import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RastreamentoService } from '../services/rastreamento/rastreamento.service';
import { LocalStorageService } from '../services/localStorage/localstorage.service';

@Component({
  selector: 'app-rastreamento',
  templateUrl: './rastreamento.component.html',
  styleUrls: ['./rastreamento.component.scss']
})
export class RastreamentoComponent implements OnInit {
  public pedidos: any = {};
  public rastreamento: any[] = [];
  public pesquisa = '';
  public isLoading = false;
  public nenhumPedido = false;
  public isLoadingRastreamento = false;
  public isEmbeded = false;
  public navbarOpen = false;
  @Input() importado: boolean = false;
  @Input('valorPesquisa') valorPesquisa;
  public semHeader = false;

  constructor(
    private router: Router,
    private rastreamentoService: RastreamentoService,
    private localStorage: LocalStorageService,
    private activatedRoute: ActivatedRoute
    ) {
      this.activatedRoute.queryParams.subscribe(params => {
        let embeded = params['embeded'];

        if (embeded) {
          this.isEmbeded = true;
        }

      });

    }

  ngOnInit(): void {

      if (this.importado) {
        this.isEmbeded = true;
        this.semHeader = true;

        this.pesquisa = this.valorPesquisa || '';
        if (this.pesquisa.length > 0) {
          this.pesquisar();
        }
      }
  }
  simular() {
    let user = this.localStorage.obterUsuario();
    if (user) {
      this.router.navigateByUrl('/simulacao/dados-coleta');
    } else {
      this.router.navigateByUrl('/cadastro/dados-empresa');
    }
  }
  formatarData(stringDate) {
    var dia = stringDate.split("/")[0];
    var mes = stringDate.split("/")[1];
    var ano = stringDate.split("/")[2].split(" ")[0];
    return new Date(`${mes}/${dia}/${ano}`);
  }
  verDetalhe(rastreamento) {
    this.isLoadingRastreamento = true;
    this.rastreamento = rastreamento;

    setTimeout(() => {

      this.prepararRastreamento();
      this.isLoadingRastreamento = false;
    }, 800)
  }
  prepararRastreamento() {
    this.rastreamento = this.rastreamento.filter( a => {
      return a.ocorrencia != "Consolidado" && a.ocorrencia != "Entregue (Comprovante digitalizado)" && a.ocorrencia != "Gerado ( arquivo processado )"
    });

    this.rastreamento.map( rastreio => {
      // if (rastreio.ocorrencia == "Recebido na filial da transportadora") {
      //   rastreio.ocorrencia = 'fÚDEO PARCERO';
      // }

      if (rastreio.ocorrencia == "Entregue") {
        rastreio.ocorrencia = 'Pedido entregue com sucesso!!! Até a próxima';
        rastreio.obs = '';
      }

      if (rastreio.ocorrencia == "Pedido em preparação para entrega") {
        rastreio.ocorrencia = 'Estamos preparando seu pedido para entrega. Aguarde! Em breve ele chegará até você.';
        // rastreio.obs = '';
      }

      if (rastreio.ocorrencia == "Recebido na filial da transportadora") {
        rastreio.ocorrencia = `Que ótimo! Seu pedido chegou no centro de distribuição de ${rastreio.local}`;
        // rastreio.obs = '';
      }
    });
  }
  pesquisar() {
    this.isLoading = true;
    this.rastreamento = [];
    this.pedidos = [];
    this.nenhumPedido = false;

    this.rastreamentoService.rastrear(this.pesquisa).subscribe((data) => {
      if (data.length > 0) {
        const hoje = new Date();
        this.nenhumPedido = false;

        let pedidos = data;

        this.pedidos = data.sort((a, b) => {
          var A = this.formatarData(a.data_cliente).getTime();
          var B = this.formatarData(b.data_cliente).getTime();

          return A - B;
        });
        this.pedidos[this.pedidos.length - 1].rastreamento.map( a => {
          this.rastreamento.push(a);
        });
        this.prepararRastreamento();
        // this.rastreamento.sort((a, b) => {
        //   return <any> new Date(a.data_ocorrencia_dt) - <any> new Date(b.data_ocorrencia_dt);
        // });

      } else {
        this.nenhumPedido = true;
      }
      this.isLoading = false;
    });
  }
  login() {
    this.router.navigateByUrl('/cadastro/login');
  }


  simularColeta() {
    let user = this.localStorage.obterUsuario();

    if (!user) {
      this.router.navigateByUrl('/cadastro/dados-empresa');
    } else {
      if (user.cobrancas.length > 0) {
        this.router.navigateByUrl('/cliente/painel');
      } else {
        this.router.navigateByUrl('/simulacao/file');
      }
    }
  }

  faq() {
    this.router.navigate(['/faq']);
  }


}
