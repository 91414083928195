<div class="row">
    <div class="col text-right px-5 py-4">
        <p>&nbsp;</p>
    </div>
</div>

<div class="row">
    <div class="col">
        <h2 class="text-center">Esqueci minha senha</h2>
        <p class="text-center">Enviaremos sua senha por e-mail</p>
    </div>
</div>

<div class="row justify-content-center">
    <div class="col-12 col-sm-4">
        <div class="py-5">
            <form>
                <ng-container *ngIf="!success">
                    <div class="form-group">
                      <label for="email">E-mail *</label>
                      <input type="email" class="form-control" id="email"  placeholder="E-mail" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
                    </div>
                    <div *ngIf="mensagemRetorno" class="alert alert-danger text-center" role="alert">
                        {{ mensagemRetorno }}
                    </div>
                </ng-container>
                <ng-container *ngIf="success">
                    <div class="alert alert-success text-center" role="alert">
                        Verifique seu e-mail para continuar!
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col text-center" *ngIf="!success">
                        <button type="button" (click)="enviar()" class="btn btn-primary px-5 w-100" [disabled]="email.length == 0">ENVIAR</button>
                    </div>
                    <div class="col text-center" *ngIf="success">
                        <button type="button" (click)="continuar()" class="btn btn-primary px-5 w-100" [disabled]="email.length == 0">CONTINUAR</button>
                    </div>
                </div>
                <div class="text-center" *ngIf="isLoading">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>