<!-- <div class="debugger" style="max-height: 70vh; width: 500px;">
    <pre>{{ user | json }}</pre>
</div> -->
<div class="container">
    <div class="pt-5"></div>
    <div class="row">
        <div class="col">
            <div>
                <p class="h5 m-0">Olá, <strong>{{ nomeUsuario }}</strong>, seja bem-vindo!</p>
            </div>
        </div>
    </div>

    <div class="my-3" *ngIf="totalNotasPendentes > 0">
        <div class="alert alert-warning w-100">
            <div class="row align-items-center">
                <i class="material-icons ml-3">error_outline</i>

                <div class="col">
                    <p class="m-0"><strong>Painel de pendências</strong></p>
                    Você possui <strong>{{ totalNotasPendentes }} {{ totalNotasPendentes > 1 ? 'pedidos' :
                        'pedido'}}</strong> {{ totalNotasPendentes > 1 ? 'pendentes' : 'pendente' }} aguardando XML ou
                    Declaração de conteúdo.
                </div>
                <div class="text-right align-items-center">
                    <button class="btn btn-outline-primary btn-icon mr-3" [routerLink]="['/cliente/pedidos']">
                        <i class="material-icons">attach_file</i>
                        Ver pendências
                    </button>
                </div>

                <!-- <div class="mr-3">
                    <i class="material-icons text-muted" tooltip="Sempre que este quadro estiver com o valor maior que 0 (Zero), significa que foi feita uma compra de frete por formulário ou planilha excel e o arquivo xml da NFe ainda não foi enviado e o prazo de coleta/entrega ainda não foi iniciado.">help</i>
                </div> -->

            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col">
            <div class="card">
                <i class="material-icons text-muted helper-floated"
                    tooltip="Esse quadro mostra que você já enviou os arquivos xmls das NFe e eles estão na fila de processamento das informações fiscais e de entrega.">help</i>
                <div class="card-body text-center">
                    <div class="conteudo-card">
                        <h5 class="card-title">Aguardando processamento</h5>
                        <p class="card-text h3 mb-2">{{ totalAguardandoProcessamento }}</p>
                        <!-- <small class="card-text mb-0">Valor total</small> -->
                        <!-- <p class="card-text mt-0">{{ valorTotalCobrancas | currency : 'BRL' }}</p> -->
                        <!-- <pre>{{ valorTotalCobrancas  | json}}</pre> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <i class="material-icons text-muted helper-floated"
                    tooltip="Aqui apresentamos para você a quantidade de faturas que você já comprou/solicitou em nosso sistema e valor total pago pelos fretes.">help</i>
                <div class="card-body text-center">
                    <div class="conteudo-card">
                        <h5 class="card-title">Ordens faturadas</h5>
                        <p class="card-text h3">{{ ordensFaturadas.length }}</p>
                        <p class="card-text mb-0">{{ totalFaturadas | currency : 'BRL' }}</p>
                        <!-- <small class="card-text mb-0">
                            <a href="javascript:void(0);" (click)="mostrarTodos()">Ver detalhes</a>
                        </small> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <i class="material-icons text-muted helper-floated"
                    tooltip="Pedidos Processados, são todos os arquivos de Nfe (XML) que já foram recebidos pelo nosso Sistema. É a Soma dos pedidos Cancelados, Entregues e Pendentes.">help</i>
                <div class="card-body text-center">
                    <div class="conteudo-card">
                        <h5 class="card-title">Pedidos</h5>
                        <p class="card-text h3">{{ totalPedidos }}</p>
                        <!-- <p class="card-text mb-0">&nbsp;</p> -->
                        <small class="card-text mb-0">
                            <a href="javascript:void(0);" (click)="mostrarTodos()">Ver detalhes</a>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col">
            <div class="card">
                <i class="material-icons text-muted helper-floated"
                    tooltip="Total de pedidos cancelados por algum tipo de ocorrência ou por sua solicitação.">help</i>
                <div class="card-body text-center">
                    <div class="conteudo-card">
                        <h5 class="card-title">Cancelados</h5>
                        <p class="card-text h3">{{ totalCancelados || 0 }}</p>
                        <p class="card-text mb-0">&nbsp;</p>
                        <small class="card-text mb-0" *ngIf="totalCancelados > 0">
                            <a href="javascript:void(0);" (click)="mostrarCancelados()">Ver detalhes</a>
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <i class="material-icons text-muted helper-floated"
                    tooltip="Total de pedidos entregues (encomendas), que chegaram ao destino final.">help</i>
                <div class="card-body text-center">
                    <div class="conteudo-card">
                        <h5 class="card-title text-center">Entregues</h5>
                        <p class="card-text h3 text-center">{{ totalEntregues }}</p>
                        <p class="card-text mb-0">&nbsp;</p>
                        <small class="card-text mb-0" *ngIf="totalEntregues > 0">
                            <a href="javascript:void(0);" (click)="mostrarEntregues()">Ver detalhes</a>
                        </small>
                        <!-- <p class="card-text mb-0">Valor total</p>
                        <p class="card-text mt-0">{{ totalNotasFiscais | currency : 'BRL' }}</p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <i class="material-icons text-muted helper-floated"
                    tooltip="São os pedidos que ainda então em operação (coleta, manuseio, transferência de CD, roteirização ou resolução de alguma ocorrência)">help</i>
                <div class="card-body text-center">
                    <div class="conteudo-card">
                        <div class="loading" *ngIf="carregandoPedidosPendentes">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <!-- <pre *ngFor="let pendente of pedidosPendentes">{{ pendente[0][0].codigo | json }}</pre> -->
                        <ng-container *ngIf="!carregandoPedidosPendentes">
                            <h5 class="card-title">Pendentes</h5>
                            <p class="card-text h3">{{ totalPendentes }}</p>
                            <p class="card-text mb-0">&nbsp;</p>
                            <small class="card-text mb-0">
                                <a href="javascript:void(0);" (click)="mostrarPedidosPendentes()"
                                    *ngIf="totalPendentes > 0">ver detalhes</a>
                            </small>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <pre>{{ listaHistorico | json }}</pre> -->
    <div class="my-5" *ngIf="listaHistorico.length == 0 ">
        <!-- <p class="text-center">Não possui historico</p> -->
    </div>
    <div class="mt-5">
        <div>
            <div class="row">
                <div class="col">
                    <div class="d-flex">
                        <p *ngIf="!mostrandoPendentes && !mostrandoCancelados && !mostrandoEntregues"><strong>Histórico
                                de pedidos</strong></p>
                        <div class="ml-2">
                            <i class="material-icons text-muted"
                                tooltip="Pedidos Processados, são todos os arquivos de Nfe (XML) que já foram recebidos pelo nosso Sistema.">help</i>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="mostrandoPendentes" class="col pl-0 align-items-center">
                <p class="pr-4 mr-3"><strong>Pendentes</strong></p>
                <span>
                    <p>Você está vizualisando os pendentes <a href="javascript:void(0);" (click)="mostrarTodos()">clique
                            aqui</a> para cancelar</p>
                </span>
            </div>
            <div *ngIf="mostrandoCancelados" class="col pl-0 align-items-center">
                <p class="pr-4 mr-3"><strong>Cancelados</strong></p>
                <span>
                    <p>Você está vizualisando os cancelados <a href="javascript:void(0);"
                            (click)="mostrarTodos()">clique aqui</a> para cancelar</p>
                </span>
            </div>
            <div *ngIf="mostrandoEntregues" class="col pl-0 align-items-center">
                <p class="pr-4 mr-3"><strong>Entregues</strong></p>
                <span>
                    <p>Você está vizualisando os entregues <a href="javascript:void(0);" (click)="mostrarTodos()">clique
                            aqui</a> para cancelar</p>
                </span>
            </div>
        </div>

        <div class="text-center" *ngIf="loadingHistorico">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="tabela-rastreamento" *ngIf="!loadingHistorico">
            <table>
                <thead>
                    <tr>
                        <th scope="col">Nº do pedido</th>
                        <th scope="col">Data do envio</th>
                        <th scope="col">Destino</th>
                        <th scope="col">Situação</th>
                        <th scope="col" class="text-right">Detalhes</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let historico of listaHistorico; let i = index;">
                        <tr>
                            <!-- <pre>{{historico[0][0] | json}}</pre> -->
                            <td>{{ historico.numeroPedido }}</td>
                            <td>{{ historico.dataCliente | date : 'short' }}</td>
                            <td><strong>{{ historico.cidadeDestino }}</strong> - {{ historico.estadoDestino }}</td>
                            <td>{{ historico.situacao }}</td>
                            <td class="text-right">
                                <a href="javascript:void(0);" [routerLink]="['/cliente/detalhe-historico/' + historico.numeroCliente, {
                                    destinatario: historico.destinatario,
                                    valorFrete: historico.valorFrete,
                                    cpfDestinatario: historico.cpfDestinatario,
                                    freteId: historico.pedidoId,
                                    declaracaoConteudoOuNf: historico.usarDeclaracaoConteudoOuNf,
                                    arquivoPendente: historico.arquivoPendente,
                                    temDeclaracaoConteudo: historico.temDeclaracaoConteudo,
                                    nfe: historico.nfe,
                                    numeroEtiquetas: historico.numeroEtiquetas,
                                    numeroPedido: historico.numeroPedido,
                                    codigo: historico.codigo
                                }]">Ver detalhes</a>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>

            <div class="d-flex align-items-end justify-content-end my-3">

                <div class="form-group mb-0">
                    <label for="comoConheceu">Qtd por Página</label>
                    <select class="form-control" placeholder="Selecione" [value]="quantidadePorPagina"
                        (change)="onChangeQuantidadePagina($event.target.value)">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                    </select>
                </div>

                <nav aria-label="..." class="ml-3 pt-2">
                    <ul class="pagination mb-0">
                        <li class="page-item" [ngClass]="{'disabled': skip == 0}">
                            <a class="page-link" href="javascript:void(0);" tabindex="-1"
                                (click)="anterior()">Anterior</a>
                        </li>

                        <ng-container *ngFor="let pagina of totalPaginas; let i = index;">
                            <li class="page-item active" [ngClass]="{'active': pagina == skip}" *ngIf="i < 15">
                                <a class="page-link" href="javascript:void(0);" (click)="irParaPagina(pagina)">{{ pagina
                                    + 1 }}</a>
                            </li>
                        </ng-container>
                        <div class="too-much-page">
                            <span *ngIf="totalPaginas.length > 15">...</span>
                        </div>
                        <li class="page-item">
                            <a class="page-link" href="javascript:void(0);" (click)="proximo()">Próximo</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>

<div class="mt-5 mb-0" style="margin-bottom: -70px !important;">
    <app-footer></app-footer>
</div>

<ng-template #templateConfirmarCancelamento>
    <div class="modal-body">
        <ng-container *ngIf="!loadingCancelamento">
            <h5 class="mb-3">Cancelar pedido</h5>
            <p>Você deseja realmente fazer o cancelamento ?<br> Esta é uma ação irreversivel</p>

            <div class="row" class="m-t-sm">
                <div class="col text-right">
                    <button class="btn  px-4" (click)="fecharModal(templateConfirmarCancelamento)">
                        Cancelar
                    </button>
                    <button class="btn btn-danger px-4" (click)="confirmarCancelamento()">
                        Confirmar
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="loadingCancelamento">
            <div class="my-5 text-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>