import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RastreamentoService {
  private environment = environment;
  constructor(private http: HttpClient) { }

  rastrear(cpfCnpj): Observable<any> {
    let url = `${this.environment.API_AWS}/api/Shipping/rastreamento?CpfCnpj=${cpfCnpj}`;

    return this.http.get<any>(url);
  }
}
