import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Injectable()
export class LocalStorageService {
  constructor(private route: ActivatedRoute) { }

  setarToken(token) {
    let tokenBearer = JSON.stringify(token);
    window.localStorage.setItem('potBearer', tokenBearer);
  }
  obterToken() {
    let tokenBearer = JSON.parse(window.localStorage.getItem('potBearer'));
    return tokenBearer;
  }

  setarUsuario(usuario) {
    let user = JSON.stringify(usuario);
    window.localStorage.setItem('potSpeed', user);
  }
  obterUsuario() {
    let token = JSON.parse(window.localStorage.getItem('potSpeed'));
    return token;
  }
  deslogar() {
    window.localStorage.removeItem('potBearer');
    window.localStorage.removeItem('potSpeed');
    window.localStorage.removeItem('tipoCliente');
    window.localStorage.removeItem('chegaLogCotacao');
    window.localStorage.removeItem('chegaLogFatura');
    window.localStorage.removeItem('idTabelaPreco');
  }
  setarFatura(data) {
    window.localStorage.setItem('chegaLogFatura', data);
  }
  limparFatura() {
    window.localStorage.removeItem('chegaLogFatura');
  }
  obterFatura() {
    return window.localStorage.getItem('chegaLogFatura');
  }  
  setarCotacao(data) {
    window.localStorage.setItem('chegaLogCotacao', JSON.stringify(data));
  }
  limparCotacao() {
    window.localStorage.removeItem('chegaLogCotacao');
  }
  obterCotacao() {
    var dataStorage = JSON.parse(window.localStorage.getItem('chegaLogCotacao'));
    return dataStorage || [];
  }
  setarColetas(data) {
    window.localStorage.setItem('coletas', JSON.stringify(data));
  }
  obterColetas() {
    var coletas = JSON.parse(window.localStorage.getItem('coletas'));
    return coletas || {};
  }
  setarFrete(data) {
    window.localStorage.setItem('frete', JSON.stringify(data));
  }
  obterFrete() {
    var frete = JSON.parse(window.localStorage.getItem('frete'));
    return frete || {};
  }
  limparFrete() {
    var dataStorage = JSON.parse(window.localStorage.getItem('potSpeed'));
    dataStorage['frete'] = null;
    window.localStorage.setItem('potSpeed', JSON.stringify(dataStorage));
  }

  setarIdVindi(idVindi) {
    var dataStorage = JSON.parse(window.localStorage.getItem('potSpeed'));
    dataStorage['idVindi'] = idVindi;
    window.localStorage.setItem('potSpeed', JSON.stringify(dataStorage));
  }

  setarTipoCliente(tipo) {
    // var dataStorage = JSON.parse(window.localStorage.getItem('potSpeed'));
    // dataStorage['tipoCliente'] = tipo;
    // window.localStorage.setItem('potSpeed', JSON.stringify(dataStorage));

    window.localStorage.setItem('tipoCliente', JSON.stringify(tipo));

  }

  obterTipoCliente() {
    // var dataStorage = JSON.parse(window.localStorage.getItem('potSpeed'));
    // return dataStorage.tipoCliente || {};

    var dataStorage = JSON.parse(window.localStorage.getItem('tipoCliente'));
    return dataStorage || {};
  }
  setarIdTabelaPreco(idTabelaPreco) {
    window.localStorage.setItem('idTabelaPreco', idTabelaPreco);
  }
  obterIdTabelaPreco() {
    var dataStorage = JSON.parse(window.localStorage.getItem('idTabelaPreco'));
    return dataStorage || '';
  }
}
