import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RastreamentoComponent } from './rastreamento/rastreamento.component';
import { ClienteChegalogComponent } from './cliente-chegalog/cliente-chegalog.component';
import { FaqComponent } from './faq/faq.component';
import { ContatoComponent } from './contato/contato.component';
import { EtiquetaComponent } from './etiqueta/etiqueta.component';
import { DeclaracaoConteudoComponent } from './declaracao-conteudo/declaracao-conteudo.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'rastreamento', component: RastreamentoComponent },
  { path: 'cliente-chegalog', component: ClienteChegalogComponent},
  { path: 'faq', component: FaqComponent },
  { path: 'contato', component: ContatoComponent },
  { path: 'etiqueta/:freteId', component: EtiquetaComponent },
  { path: 'declaracao-conteudo/por-frete/:freteId', component: DeclaracaoConteudoComponent },
  { path: 'declaracao-conteudo/por-fatura/:faturaId', component: DeclaracaoConteudoComponent },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload',   anchorScrolling: 'enabled',})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
