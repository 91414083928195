import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteComponent } from './cliente.component';
import { ClienteRoutingModule } from './cliente-routing.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DadosCadastraisComponent } from './dados-cadastrais/dados-cadastrais.component';
import { PainelComponent } from './painel/painel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BrowserModule } from '@angular/platform-browser';
import { DetalheHistoricoComponent } from './detalhe-historico/detalhe-historico.component';
var options: Partial<IConfig> | (() => Partial<IConfig>);
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { RastreamentoModule } from '../rastreamento/rastreamento.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FooterModule } from '../footer/footer.module';
import { PedidosComponent } from './pedidos/pedidos.component';
import { DeclaracaoConteudoComponent } from './declaracao-conteudo/declaracao-conteudo.component';
import { CpfCnpjPipeModule } from '../pipe/cpfCnpj.pipe.module';
import { UserDropdownModule } from '../components/user-dropdown.module';
import { DragDropClienteDirective } from './file-cliente/drag-drop-cliente.directive';
import { DragAreaClienteComponent } from './file-cliente/drag-area-cliente/drag-area-cliente.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

@NgModule({
  declarations: [
    ClienteComponent,
    DadosCadastraisComponent,
    PainelComponent,
    DetalheHistoricoComponent,
    PedidosComponent,
    DeclaracaoConteudoComponent,
    DragAreaClienteComponent,
    DragDropClienteDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ClienteRoutingModule,
    BrowserAnimationsModule,
    RastreamentoModule,
    FooterModule,
    CpfCnpjPipeModule,
    BsDropdownModule.forRoot(),
    NgxMaskModule.forRoot(options),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    UserDropdownModule
  ]
})
export class ClienteModule { }
