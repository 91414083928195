import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn} from '@angular/forms';

import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from './../../../environments/environment';
import {UtilsService} from './../utils/utils.service';

import {Cotacao, Tipo} from './../../interfaces/cotacao';

@Injectable({ providedIn: 'root' })
export class UsuarioService {
  private API = environment;
  private environment = environment;
  private tokenApiEngloba = "TEVzV0piWTA2b0VLVSsvSTNqQnRURTNoRFh0aVJBazZRVXdQanRmOWtIRk1NZy9Pb0hDRStaNGdsR2VaSVYyeGhBSzFkeHN6RzZsOUJZNU5DUTAyZEE9PQ==";

  constructor(
    private readonly http: HttpClient,
    private readonly utilsService: UtilsService
  ) { }

  public obterDetalhes() : Observable<any> {
    let url = `${this.environment.API_AWS}/api/Customer/Detail`;

    return this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() });
  }

  public obterInformacoesCompletas() : Observable<any> {
    let url = `${this.environment.API_AWS}/api/Usuario/InformacoesCompletas`;

    return this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() });
  }

  public atualizarDadosCadastrais(idUsuario, data) : Observable<any> {
    let url = `${this.environment.API_AWS}/api/Usuario/InformacoesCompletas/${idUsuario}`;

    return this.http.put<any>(url, { headers: this.utilsService.obterHeaderAuthorization() }, data);
  }

  public cadastrasInformacoesComplementares(informacoesComplementares: any): Observable<any> {
    let url = `${this.environment.API_AWS}/api/Usuario/InfoComplementaresCadastrar`;
    console.log("body", informacoesComplementares);
    return this.http.post<any>(url, informacoesComplementares, { headers: this.utilsService.obterHeaderAuthorization() });
  }

  public cadastrar(formData): Observable<any> {
    return this.http.post<any>(`${this.environment.API_AWS}/api/Usuario/UsuarioCadastrar`, formData);
  }

  public logar(username, password) : Observable<any> {
    return this.http.post<any>(`${this.environment.API_AWS}/api/Authentication/login`, { username, password })
  }

  public validarCodigoEngloba(codigo) : Observable<any> {
    let url = `${this.environment.API_AWS}/api/usuario/validarcodigoengloba?codigo=${codigo}`;

    return this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() });
  }

  public obterOpcoesComoConheceu(): Observable<any> {
    const url = `${this.environment.API_AWS}/api/ComoConheceu/ComoConheceuListar`;

    return this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() });
  }

  public obterOpcoesRepresentantes(): Observable<any> {
    const url = `${this.environment.API_AWS}/api/ComoConheceu/RepresentanteListar`;

    return this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() });
  }

  public contato(data): Observable<any> {
    const url = `${this.environment.API_AWS}/api/contato`;

    return this.http.post<any>(url, { nome: data.nome, email: data.email, celular: data.celular, mensagem: data.mensagem });
  }




  obterUsuario(id): Observable<any> {
    return this.http.get(`${this.API.API_URL}/clientes/${id}`);
  }
  verifyAccount(email, numero, nome, cpf): Observable<any> {
    // return this.http.post<any>(`${this.API.API_URL}/verify-account`, {id, phone});
    let token = this.tokenApiEngloba;
    // let url = `https://englobasistemas.com.br/financeiro/api/fretes/calcularFrete?codigo=1469&local=BR&peso=${ 0 }&peso_cubado=${ 0 }&valor=${ valor }&cep=${CEP}`;
    let url = `https://englobasistemas.com.br/Ferramentas/api/CriarConta/LoginExiste?token=${token}&email=${email}&numero=${numero}&nome=${nome}&cpf=${cpf}`

    return this.http.post<any>(url, {});
  }
  validarCodigo(email, codigo) : Observable<any> {
    let token = this.tokenApiEngloba;
    let url = `https://englobasistemas.com.br/Ferramentas/api/CriarConta/ValidarCodigo?token=${token}&email=${email}&codigo_informado=${codigo}`;
    return this.http.post<any>(url, {});
  }
  cadastrarEngloba(usuario, codigo, senha) : Observable<any> {
      let token = this.tokenApiEngloba;
      let url = `https://englobasistemas.com.br/Ferramentas/api/CriarContaRedeEntregas/SalvarCadastro?token=${token}&email=${usuario.email}&codigo_informado=${codigo}&bairro_principal=${usuario.endereco.bairro}&celular_principal=${usuario.celular}&cep_principal=${usuario.endereco.cep}&cidade_principal=${usuario.endereco.cidade}&cnpj_cpf="${usuario.cnpj}"&complemento_principal=${usuario.endereco.complemento}&email_contato_principal=${usuario.email}&endereco_principal=${usuario.endereco.endereco}&estado_principal=${usuario.endereco.estado}&ie_estadual=${usuario.inscricaoEstadual}&ie_municipal='ISENTO'&nome_contato_principal=${usuario.nome}&nome_fantasia=${usuario.nomeEmpresa}&numero_principal=${usuario.endereco.numero}&ponto_referencia_principal=&razao_social=${usuario.nomeEmpresa}&senha_informada=${senha}&telefone_1_principal=${usuario.celular}&_sp=2677&_codigo_abrangencia=364`;
      // return this.http.get(`${this.API.API_URL}/ping`);
      return this.http.post<any>(url, {});
  }
  atualizaIdEngloba(idCliente, idEngloba) : Observable<any> {
    return this.http.put<any>(`${this.API.API_URL}/cliente/${idCliente}/atualiza-id-engloba`, {idEngloba});
  }





  buscarEnderecoByCEP(cep): Observable<any> {
    return this.http.get(`https://brasilapi.com.br/api/cep/v1/${cep}`);
  }
  cepValidator() : AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      return this.buscarEnderecoByCEP(control.value).pipe(
        map( a => {
          return null
        }),
        catchError(err => {
          return of({invalido: true})
        })
      )


    };
  }
  atualizar(idUsuario, data) : Observable<any> {
    return this.http.put<any>(`${this.API.API_URL}/cliente/${idUsuario}`, data);
  }

  getSenha(senha, idCliente) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/get-senha/${idCliente}`, {senha: senha});
  }




  criarTokenCartao(dadosCartao) : Observable<any> {
    // GERADA EM 03/02/2021
    // xhifaTfz-_WhaYLPUFOQ1PH-0qt9ZkCH9_4he-CaW6E // PRODUÇÃO
    // NOVA
    // 0Kkpu7srIbI2ZrUhHSrGBcHh6RrcbSsVuJCDNln1nk4
    // MAIS NOVA AINDA
    // lxgNvXu2XFtBK8wSKjdYGukmzwbnqaoOsBYHT2S0QK4 // HOMOLOGAÇÃO
    // ANTERIOR
    // X-H-EqdzAkM2KglbO8IT0j9Ps-XqIj1zcv4roYUiy94

    var header = {
      headers: new HttpHeaders().set('Authorization', `Basic ${btoa("lxgNvXu2XFtBK8wSKjdYGukmzwbnqaoOsBYHT2S0QK4")}`)
    }

    return this.http.post<any>(`${this.API.API_URL_VINDI}/api/v1/public/payment_profiles`, {
      holder_name: dadosCartao.nomeCartao,
      card_expiration: dadosCartao.vencimento,
      card_number: dadosCartao.numeroCartao,
      card_cvv: dadosCartao.cvv,
      payment_method_code: "credit_card",
      payment_company_code: dadosCartao.bandeira
    }, header)
  }
  registrarToken(dataToken) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/registrar-token`, {idClienteVindi: dataToken.idClienteVindi, gatewayToken: dataToken.gatewayToken, holder_name: dataToken.holder_name})
  }
  cadastrarUsuarioVindi(cadastro) {
    return this.http.post<any>(`${this.API.API_URL}/cadastrar-vindi`, cadastro)
  }
  verificaVindi(email) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/verifica-vindi`, {email})
  }


  pagamento(idClienteVindi, item, parcelas) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/pagamento`, {idClienteVindi: idClienteVindi, item: item, parcelas});
  }
  validar(idCliente, codigo?): Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/cliente/validar`, {idCliente, codigo});
  }
  gravarCotacoes(cliente, cotacoes): Observable<any> {
    var cotacoesParaEnviar = [];

    cotacoes.map( cotacao => {
      cotacoesParaEnviar.push({
        idCliente: cliente._id,
        email: cliente.email,
        cepAtendido: cotacao.cepAtendido,
        cepOrigem: cotacao.cepOrigem,
        cepDestino:  cotacao.CEP,
        cidade: cotacao.cidade,
        estado: cotacao.estado,
        mercadoria: cotacao.mercadoria,
        tipo: cotacao.tipo,
        valor: cotacao.valor,
        valorFrete: cotacao.valorFrete.valor
      })
    });

    return this.http.post<any>(`${this.API.API_URL}/cliente/cotacoes`, { cotacoes: cotacoesParaEnviar });
  }
  obterNumeroPorChave(chave) {
    function direita(texto, posicao) {
        return texto.substr(texto.length - posicao);
    }
    function esquerda(texto, posicao) {
        return texto.substr(0, posicao);
    }
    return direita(esquerda(chave, 34), 9)
  }
  removerArquivosDoCliente(idArquivo, idCliente) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/remover-arquivo`, {idArquivo, idCliente});
  }
  // limparNotasNaoEfetivadas(idCliente) : Observable<any> {
  //   return this.http.post<any>(`${this.API.API_URL}/limpar-notas`, {idCliente})
  // }
  uploadEngloba(idCliente, chaves, frete?: any ) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/upload-engloba`, {idCliente, chaves, frete})
  }
  atualizarFaturas(idCliente, bill, frete) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/cliente/${idCliente}/fatura`, {bill, frete})
  }
  recuperarSenhaByEmail(email): Observable<any> {
    return this.http.post<any>(`${this.API.API_AWS}/api/Usuario/EsqueciMinhaSenha`, { email })
  }
  obterSuporteComercial(): Observable<any> {
    return this.http.get(`${this.API.API_URL}/suporte-comercial`)
  }
  obterAbrangencia(ufOrigem, estado, regiao, origem, cidade): Observable<any> {
    return this.http.get(`${this.API.API_URL}/abrangencia/${ufOrigem}/${estado}/${regiao}/${cidade}?origem=${origem}`)
  }
  obterPontosPegaki(zona): Observable<any> {
    return this.http.get(`${this.API.API_URL}/pontos-pegaki/${zona}`)
  }
  async prepararCotacao(docs, usuario) {
    let cotacoes : any = [];
    let erros : any[] = [];
    var cnpjsPotSpeed = await this.utilsService.obterCnpjsPotSpeed().toPromise();

    console.time();
    for (let i = 0; i < docs.length; i++) {
      if (docs[i].tipo == 'xls') {
        let quantidade = docs[i]["QTDE DE ITENS"];
        let peso = docs[i]['PESO (KG)'];
        let altura = docs[i]['ALTURA (CM)'];
        let largura = docs[i]['LARGURA (CM)'];
        let comprimento = docs[i]['COMPRIMENTO (CM)'];

        let endereco = await this.buscarEnderecoByCEP(docs[i]["CEP DESTINO"]).toPromise().catch(error => {
          if (error.status == 404) {
            erros.push({erro: `CEP Não encontrado '${docs[i]["CEP DESTINO"]}'`})
          } else {
            erros.push({erro: 'Houve algum problema com os dados enviados, por favor revise os dados e tente novamente!'})
          }
        });
        let volumes = [];
        volumes.push({
          quantidade: quantidade,
          peso: peso,
          altura: altura,
          largura: largura,
          comprimento: comprimento
        });

        if (endereco != undefined) {
          cotacoes.push(new Cotacao(<Cotacao>{
            _id: docs[i]._id,
            id: '-',
            chave: '-',
            quemEntrega: 'pot',
            numeroNFCTe: '-',
            quantidade: quantidade,
            estado: endereco.state,
            cidade: endereco.city,
            CEP: endereco.cep,
            prazo: '-',
            valor: docs[i]["VALOR TOTAL DA NFE"],
            valorFrete: { valor: 0, isLoading: true },
            cepAtendido: true,
            tipo: Tipo.XLS,
            pendenteNotaFiscal: true,
            mercadoria: {
              volumes: volumes
            }
          }).cotacao);
        }
      } else {
        if (docs[i].nfeProc) {
          let chave = docs[i].nfeProc.protNFe.infProt.chNFe;
          let cep = docs[i].nfeProc.NFe.infNFe.dest.enderDest.CEP;
          let cepOrigem = docs[i].nfeProc.NFe.infNFe.emit.enderEmit.CEP;
          let peso = docs[i].nfeProc.NFe.infNFe.transp.vol.pesoB;
          let valor = docs[i].nfeProc.NFe.infNFe.total.ICMSTot.vNF;
          let cnpjEmissor = docs[i].nfeProc.NFe.infNFe.emit.CNPJ;
          let cnpjTransportadora = docs[i].nfeProc.NFe.infNFe.transp.transporta.CNPJ;
          let cnpjUsuario = usuario.cnpj;
          let _id = docs[i]._id;
          let dadosTransportadora = docs[i].nfeProc.NFe.infNFe.transp;
          let mercadorias = docs[i].nfeProc.NFe.infNFe.det;
          let quantidade = 0;


          let volumes = [];

          if (mercadorias.length != undefined) {
            mercadorias.map( merc => {
              quantidade = quantidade + Number(merc.prod.qCom)
            });
          } else {
            mercadorias = [
              docs[i].nfeProc.NFe.infNFe.det
            ];
            quantidade = quantidade + Number(docs[i].nfeProc.NFe.infNFe.det.prod.qCom)
          }
          mercadorias.map( merc => {
            volumes.push({
              quantidade: Number(merc.prod.qCom),
              peso: parseFloat(Math.round(peso * 1000).toFixed(3)),
              altura: '0',
              largura: '0',
              comprimento: '0'
            })
          });

          let emissor = docs[i].nfeProc.NFe.infNFe.emit;
          let enderecoEmissor = emissor.enderEmit;
          let destinatario = docs[i].nfeProc.NFe.infNFe.dest
          let enderecoDestinatario = destinatario.enderDest
          var mesmaTransportadora = this.verificarTransportadora(cnpjTransportadora, cnpjsPotSpeed);
          // ADICIONA COTAÇÃO QUANDO O CNPJ DA NOTA FOR IGUAL A DO USUÁRIO
          if (cnpjEmissor == cnpjUsuario) {
            debugger;
            var valorACobrar = docs[i].nfeProc.NFe.infNFe.total.ICMSTot.vFrete ? docs[i].nfeProc.NFe.infNFe.total.ICMSTot.vProd : docs[i].nfeProc.NFe.infNFe.total.ICMSTot.vNF;
            cotacoes.push(new Cotacao(<Cotacao>{
              _id: _id,
              id: this.obterNumeroPorChave(chave),
              idArquivo: docs[i].idArquivo,
              chave: chave,
              quemEntrega: 'pot',
              numeroNFCTe: this.obterNumeroPorChave(chave),
              quantidade: quantidade,
              estado: docs[i].nfeProc.NFe.infNFe.dest.enderDest.UF,
              cidade: docs[i].nfeProc.NFe.infNFe.dest.enderDest.xMun,
              CEP: docs[i].nfeProc.NFe.infNFe.dest.enderDest.CEP,
              cepOrigem: cepOrigem,
              prazo: '-',
              valor: Number(valorACobrar),
              valorTotalNfe: Number(docs[i].nfeProc.NFe.infNFe.total.ICMSTot.vNF),
              valorFrete: { valor: 0, isLoading: true },
              cepAtendido: true,
              tipo: Tipo.XML,
              cnpjTransportadora: dadosTransportadora.transporta.CNPJ,
              enderecoCompleto: {
                logradouro: enderecoDestinatario.xLgr,
                numero: enderecoDestinatario.nro,
                bairro: enderecoDestinatario.xBairro,
                cidade: enderecoDestinatario.xMun,
                estado: enderecoDestinatario.UF,
                cep: enderecoDestinatario.CEP
              },
              mercadoria: {
                volumes: volumes
              },
              cliente: destinatario.xNome,
              pendenteNotaFiscal: docs[i].idArquivo && mesmaTransportadora ? false : true,
              produtosNFe: mercadorias
            }).cotacao);
          }
        }
      }
    }
    return {cotacoes, erros};
  }
  verificarTransportadora(cnpjParaComparar, cnpjs) {
    var retorno = false;

    for (let pot of cnpjs) {
      if (pot.cnpj == cnpjParaComparar) {
        retorno = true;
      }
    }
    return retorno;
  }
  atualizarArquivoPendente(idCliente, idFrete, idDestino, idArquivo) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/atualizar-pendente`, {idCliente, idFrete, idDestino, idArquivo})
  }
  cancelarPedido(idCliente, idFrete, idDestino, status, cliente, frete) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/atualizar-pedido-destino`, {idCliente, idFrete, idDestino, status, cliente, frete})
  }
  solicitarEstorno(idCobranca, amount) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/solicitar-estorno`, {idCobranca, amount})
  }
  obterPedidoPorUsuario(idCliente, skip, limit, statusPendente?) : Observable<any> {
    return this.http.get(`${this.API.API_URL}/cliente/${idCliente}/pedidos?skip=${skip}&limit=${limit}&statusPedido=${statusPendente}`)
  }
  rangeCepPegaKi(cep) : Observable<any> {
    return this.http.get(`${this.API.API_URL}/range-cep/${cep}`)
  }
  async range(cep, estado?) {
    //return this.http.get(`${this.API.API_URL}/range/${cep}?estado=${estado}`)
    let url = `${this.environment.API_AWS}/api/Frete/range?cep=${cep}`;

    return await this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() }).toPromise();
  }
  obterPedidoPorUsuarioPorNumeroPedido(idCliente, numeroPedido) : Observable<any> {
    return this.http.get(`${this.API.API_URL}/pedido/${idCliente}/${numeroPedido}`);
  }
  salvarDeclaracao(idCliente, idFrete, idDestino, formulario, itens, numeroPedido, destino) : Observable<any> {
    return this.http.post<any>(`${this.API.API_URL}/cliente/${idCliente}/declaracao`, {idCliente, idFrete, idDestino, formulario, itens, numeroPedido, destino})
  }
}


