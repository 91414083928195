import {Component, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {LocalStorageService} from '../services/localStorage/localstorage.service';
import {UsuarioService} from '../services/usuario/usuario.service';
import { FreteService } from '../services/frete/frete.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public mensagemRetornoFormulario = '';
  public retornoContato: any = {};
  public isLoadingContato = false;
  public contatoForm;
  public navbarOpen = false;
  public modalRef: BsModalRef;

  public estado = 'PR';
  public regiao = 'CAPITAL';
  public cidade = '';

  public ufOrigem = 'PR';
  public origem = '0';
  public cidadeOrigem = 'CURITIBA';
  public listaOrigens = [];
  public listaFiltradaOrigens = [];

  public ufDestino = '';
  public destino = '0';
  public cidadeDestino = '';
  public listaDestinos = [];
  public listaFiltradaDestinos = [];

  public cidades = [];
  public isLoadingAbrangencia = false;
  public timeout;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private localStorage: LocalStorageService,
    private modalService: BsModalService,
    private readonly freteService: FreteService

  ) {
    this.contatoForm = this.formBuilder.group({
      nome: '',
      email: ['',[
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]
      ],
      mensagem: '',
    });

    this.freteService.listarOrigens()
      .subscribe((origens: any) => {
        this.listaOrigens = origens;
        this.mapearOrigens();
      });

    this.freteService.listarDestino()
      .subscribe((destinos: any) => {
        this.listaDestinos = destinos;
        this.mapearDestinos();
      });
  }


  mapearOrigens() {
    this.listaOrigens.map( area => {
      if (this.ufOrigem == area.estado) {
        this.listaFiltradaOrigens = area.origens;
      }
    });
    this.atualizarOrigens();
  }

  mapearDestinos() {
    this.listaDestinos.map( area => {
      if (this.ufDestino == area.uf) {
        this.listaFiltradaDestinos = area.regiao;
      }
    });
    this.atualizarDestinos();
  }


  ngOnInit(): void {
    this.keyup()
  }
  atualizarOrigens() {
    this.origem = '0';
    this.listaOrigens.map( area => {
      if (this.ufOrigem == area.nome) {
        this.listaFiltradaOrigens = area.origens;
      }
    });
  }
  atualizarDestinos() {
    this.destino = '0';
    this.listaDestinos.map( area => {
      if (this.ufDestino == area.uf) {
        this.listaFiltradaDestinos = area.regiao;
      }
    });
  }
  onSubmit(data) {
    this.isLoadingContato = true;
    this.usuarioService.contato(data).subscribe(retorno => {
      this.retornoContato = retorno;
      if (retorno.success) {
        this.mensagemRetornoFormulario = 'Seu contato foi enviado';
      } else {
        this.mensagemRetornoFormulario = retorno.error;
      }
      this.isLoadingContato = false;
    }, (error) => {
      this.isLoadingContato = false;
    });
  }

  clienteChegaLog(){
    this.router.navigateByUrl('/cliente-chegalog');
  }
  login() {
    this.router.navigateByUrl('/cadastro/login');
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, );
  }
  onChangeSelect(event) {
    this.keyup();
  }

  onChangeSelectUFDestino(event) {
    this.atualizarDestinos();
    this.keyup();
  }

  onChangeSelectUFOrigem(event) {
    this.atualizarOrigens();
    this.keyup();
  }
  keyup() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() =>{
      this.isLoadingAbrangencia = true;

      if (this.estado != '' && this.regiao != '') {
        this.freteService.obterAbrangencia(this.ufOrigem, this.ufDestino, this.regiao, this.cidade, this.origem).subscribe( res => {
          this.cidades = res;
          this.isLoadingAbrangencia = false;
        }, (err) => {
          this.isLoadingAbrangencia = false;
        });
      } else {
        this.isLoadingAbrangencia = false;
      }
    }, 600)
  }

  faq() {
    this.router.navigate(['/faq']);
  }

  simular() {
    var estaLogado = this.localStorage.obterUsuario() ? true : false;
    if (estaLogado) {
      this.router.navigateByUrl('/simulacao/file');
    } else {
      this.router.navigateByUrl('/simulacao/preenchimento-manual');
    }
  }

}
