import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { DadosEmpresaComponent } from './dados-empresa/dados-empresa.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CadastroComponent } from './cadastro.component';
import { RouterModule } from '@angular/router';
import { CadastroRoutingModule } from './cadastro-routing.module';
import { LocalStorageService } from '../services/localStorage/localstorage.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { EsqueciSenhaComponent } from './esqueci-senha/esqueci-senha.component';
var options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    CadastroComponent,
    LoginComponent,
    DadosEmpresaComponent,
    EsqueciSenhaComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CadastroRoutingModule,
    NgxMaskModule.forRoot(options)
  ],
  exports: [],
  providers: [LocalStorageService]
})
export class CadastroModule { }
