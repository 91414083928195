<div *ngIf="!exibirDeclaracaoConteudo" class="container">
  <div class="pt-5"></div>
  <div class="row">
    <div>
      <p *ngIf="!exibirDeclaracaoConteudo && !pedidoNaoEncontrado">
        <strong>Realizando a busca dos dados da Declaração de Conteúdo. Por favor aguarde.</strong>
      </p>

      <p *ngIf="pedidoNaoEncontrado">
        <strong>Declaração de Conteúdo não encontrada.</strong>
      </p>
  </div>
  </div>
</div>

<div *ngIf="exibirDeclaracaoConteudo" class="container-declaracao">
  <div class="bloco-titulo">
    <h5> <strong>DECLARAÇÃO DE CONTEÚDO</strong></h5>
  </div>

  <div class="container-remetente-destinatario">
    <div class="bloco">
      <table class="table">
        <tr>
          <td colspan="2" class="text-center"><strong> REMETENTE </strong></td>
        </tr>
        <tr>
          <td colspan="2">Nome: {{ pedido.remetente.nome }}</td>
        </tr>
        <tr>
          <td colspan="2">Endereço: {{ pedido.remetente?.endereco }}, {{ pedido.remetente?.numero }} </td>
        </tr>
        <tr>
          <td colspan="2">Bairro: {{ pedido.remetente?.bairro }}</td>
        </tr>
        <tr>
          <td style="width: 300px;">Cidade: {{ pedido.remetente.cidade }}</td>
          <td>UF: {{ pedido.remetente.uf }}</td>
        </tr>
        <tr>
          <td>CEP: {{ pedido.remetente.cep }}</td>
          <td style="width: 190px;">CPF/CNPJ: <br>{{ pedido.remetente.cpfCnpj | cpfCnpj }}</td>
        </tr>
      </table>
    </div>

    <div class="bloco">
      <table class="table">
        <tr>
          <td colspan="5" class="text-center"><strong> DESTINATÁRIO</strong></td>
        </tr>
        <tr>
          <td colspan="2">Nome: {{ pedido.destinatario.nome }}</td>
        </tr>
        <tr>
          <td colspan="2">Endereço: {{ pedido.destinatario?.endereco }}, {{ pedido.destinatario?.numero }} </td>
        </tr>
        <tr>
          <td colspan="2">Bairro: {{ pedido.destinatario?.bairro }}</td>
        </tr>
        <tr>
          <td style="width: 300px;">Cidade: {{ pedido.destinatario.cidade }}</td>
          <td>UF: {{ pedido.destinatario.uf }}</td>
        </tr>
        <tr>
          <td>CEP: {{ pedido.destinatario.cep }}</td>
          <td style="width: 190px;">CPF/CNPJ: <br>{{ pedido.destinatario.cpfCnpj | cpfCnpj }}</td>
        </tr>
      </table>
    </div>
  </div>

  <div class="container-bens">
    <table class="tabela-bens">
      <thead>
        <tr>
          <th colspan="4" class="titulo-bens">IDENTIFICAÇÃO DOS BENS</th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th class="titulo-bens">ITEM</th>
          <th class="titulo-bens">CONTEUDO</th>
          <th class="titulo-bens">QUANTIDADE</th>
          <th class="titulo-bens">VALOR</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of pedido.identificacaoBens; let i = index;">
          <td>{{ i + 1 }}</td>
          <td style="width: 500px;">{{ item.conteudo }}</td>
          <td>{{ item.quantidade }}</td>
          <td>{{ item.valor | currency: 'BRL' }}</td>
        </tr>
        <ng-container *ngIf="pedido.identificacaoBens.length < 10">
          <tr *ngFor="let i of [1, 2, 3, 4, 5]">
            <td>&nbsp;</td>
            <td style="width: 500px;">&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        </ng-container>
        <tr>
          <td colspan="2" class="totais"> TOTAIS &nbsp;</td>
          <td>{{ itensTotais }}</td>
          <td>{{ valorTotalItens | currency : 'BRL' }}</td>
        </tr>
        <tr>
          <td colspan="2" class="totais"> PESO TOTAL (kg) &nbsp;</td>
          <td colspan="2"> {{ pesoTotalItens }} </td>
        </tr>
      </tbody>
  </table>
</div>

<div class="container-assinatura">
  <p class="assinatura-titulo">DECLARAÇÃO</p>
  <p class="assinatura-conteudo"> &nbsp; &nbsp; &nbsp;Declaro que não me enquadro no conceito de contribuinte previsto no art. 4º da Lei Complementar nº 87/1996,
    uma vez que não realizo, com habitualidade ou em volume que caracterize intuito comercial, operações de circulação
    de mercadoria, ainda que se iniciem no exterior, ou estou dispensado da emissão da nota fiscal por força da legislação
    tributária vigente, responsabilizando-me, nos termos da lei e a quem de  direito, por informações inverídicas. <br>
    &nbsp; &nbsp; &nbsp;Declaro ainda que não estou postando conteúdo inflamável, explosivo, causador de combustão espontânea, tóxico, corrosivo,
    gás ou qualquer outro conteúdo que constitua perigo, conforme o art. 13 da Lei Postal nº 6.538/78.
  </p>
  <p class="assinatura">
    ______________________________________ , _______ de _______________________ de _____________
    ______________________________________________________
  </p>
    <div class="assinatura-ref">
      Assinatura do Declarante/Remetente
    </div>
  </div>

  <div class="container-observacao">
    <p><strong>OBSERVAÇÃO: </strong></p>
    <p>
      Constitui crime contra a ordem tributária suprimir ou reduzir tributo, ou contribuição social e
      qualquer acessório (Lei 8.137/90 Art. 1º, V).
    </p>
  </div>
</div>

