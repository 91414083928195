import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';

export const cnpjValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  var cnpj = control.value.replace(/[^\d]+/g,'');

  if(cnpj == '') return {invalido: true};

  if (cnpj.length != 14)
      return {invalido: true};

  // Elimina CNPJs invalidos conhecidos
  if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return {invalido: true};

  // Valida DVs
  var tamanho = cnpj.length - 2
  var numeros = cnpj.substring(0,tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
          pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0))
      return {invalido: true};

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
          pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1))
        return {invalido: true};

  return null;
};
@Component({
  selector: 'app-dados-coleta',
  templateUrl: './dados-coleta.component.html',
  styleUrls: ['./dados-coleta.component.scss']
})
export class DadosColetaComponent implements OnInit {
  public dadosColetaForm;
  public isLoading = false;
  public loadingCep = false;
  public formulario;
  public preenchimentoObrigatorio = undefined;
  public requiredColeta = false;
  public usuario;
  public frete;
  public isLogado;

  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    this.obterDetalhesUsuario();

    this.usuario = this.localStorageService.obterUsuario();
    console.log(">>> dados usuario::" + JSON.stringify(this.usuario));

    this.preenchimentoObrigatorio = this.usuario.dadoColetaObrigatorio || !this.usuario.cnpj;
    console.log(">>> verificando preenchimento obrigatório...", this.preenchimentoObrigatorio);

    this.frete = this.localStorageService.obterFrete();
    console.log(">>> buscou frete...", JSON.stringify(this.frete));

    if (!this.preenchimentoObrigatorio) {
      console.log(">>> vai chamar dados destinatario...");
      //this.router.navigateByUrl('/simulacao/dados-destinatarios');
      if (this.usuario.declaracao && this.usuario.notafiscal && this.frete.pedidos[0].tipoPreenchimento != "xml") {
        console.log(">>> desvio para passar pela seleção NFe/Declaração...");
        this.router.navigateByUrl('/simulacao/documento-fiscal');
      } else {
        if (this.frete.tipoDocumentoFiscal == 'declaracao') {
          console.log(">>> vai direto para o pagamento...");
          this.router.navigateByUrl('/simulacao/dados-destinatarios');
        } else {
          console.log(">>> vai direto para o pagamento...");
          this.router.navigate(['simulacao', 'pagamento']);
        }

      }
    }

    this.dadosColetaForm = this.formBuilder.group({
      cnpj: [this.usuario.cnpj || '', [Validators.required, cnpjValidator]],
      inscricaoEstadual: [this.usuario.cliente ? this.usuario.cliente.inscricaoEstadual : null || '', Validators.required],
      razaoSocial: [this.usuario.cliente ? this.usuario.cliente.razaoSocial : null || '', Validators.required],
      cnae: [this.usuario.cliente ? this.usuario.cliente.cnae : null || ''],
      cep: [(this.usuario.endereco ? this.usuario.endereco.cep : (this.usuario.coletas?.length > 0 ? this.usuario.coletas[0]?.cepOrigem : null)) || '', Validators.required],
      endereco: [(this.usuario.endereco ? this.usuario.endereco.logradouro : null) || '', Validators.required],
      numero: [(this.usuario.endereco ? this.usuario.endereco.numero : null) || '' , Validators.required],
      complemento: [(this.usuario.endereco ? this.usuario.endereco.complemento : null) || ''],
      bairro: [(this.usuario.endereco ? this.usuario.endereco.bairro : null ) || '', Validators.required],
      cidade: [(this.usuario.endereco ? this.usuario.endereco.cidade : null ) || '', Validators.required],
      estado: [(this.usuario.endereco ? this.usuario.endereco.estado : null ) || '', Validators.required],
      mesmoEnderecoColeta: this.usuario.mesmoEnderecoColeta || 'sim',
      tipoEmpresa: [this.usuario.cliente ? (this.usuario.cliente.cnae ? 'MEI' : 'Outra' ) : 'Outra'],
      cepColeta: '',
      enderecoColeta: '',
      numeroColeta: [''],
      complementoColeta: '',
      bairroColeta: '',
      cidadeColeta: [''],
      estadoColeta: [''],
    });
  }
  ngOnInit(): void {
    console.log(">>>--------------------- Init dados coleta");
    this.dadosColetaForm.controls['mesmoEnderecoColeta'].valueChanges.subscribe(value => {
      if (value == 'sim') {
        this.resetEnderecoColeta(false);
      } else {
        this.resetEnderecoColeta(true);
      }
    })

    if (!this.usuario.endereco && this.usuario.coletas) {
      this.procurarEndereco(this.usuario.coletas[0].cepOrigem);
    }
  }
  onSubmit(form) {
    console.log(">>> dados-coleta.onSubmit");
    this.proximo(form);

    // this.usuarioService.cadastrar(cadastroData).subscribe((data) => {
    //   this.localStorageService.setarUsuario({id: data.id, nome: data.nome, nomeEmpresa: data.nomeEmpresa, email: ''});
    // });
    // this.router.n  avigateByUrl('/cadastro/criar-login');
  }
  proximo(dadosForm) {
    console.log(">>> dados-coleta.proximo");
    const dadosComplementares = this.criarDadosComplementaresRequest(dadosForm);
    this.usuarioService.cadastrasInformacoesComplementares(dadosComplementares)
    .subscribe(() => {
      this.obterDetalhesUsuario();
      this.router.navigateByUrl('/simulacao/validar-acesso');
    });

    // this.router.navigateByUrl('/simulacao/upload-arquivo');
    // var tipoCliente = this.usuario.tipoCliente ? this.usuario.tipoCliente : this.localStorageService.obterTipoCliente();
    // debugger;
    // var dadosUsuario;
    // if (dadosForm.mesmoEnderecoColeta == 'sim') {
    //   dadosForm.cepColeta = dadosForm.cep;
    //   dadosForm.enderecoColeta = dadosForm.endereco;
    //   dadosForm.numeroColeta = dadosForm.numero;
    //   dadosForm.complementoColeta = dadosForm.complemento;
    //   dadosForm.bairroColeta = dadosForm.bairro;
    //   dadosForm.cidadeColeta = dadosForm.cidade;
    //   dadosForm.estadoColeta = dadosForm.estado;
    // }
    // dadosUsuario = {...this.usuario, ...dadosForm, tipoCliente};

    // this.usuarioService.atualizar(idUsuario, dadosUsuario).subscribe( data => {
    //   if (data._id) {
    //     let usuarioAtual = this.localStorageService.obterUsuario();
    //     let novosDados = {...usuarioAtual, ...data};
    //     debugger;
    //     this.localStorageService.setarUsuario(novosDados);
    //     this.router.navigateByUrl('/simulacao/validar-acesso');
    //   }
    // });
    // if (!this.isCamposDesabilitados()) {
    // } else {
    //   this.router.navigateByUrl('/simulacao/file');
    // }
  }
  private obterDetalhesUsuario() {
    this.usuarioService.obterDetalhes()
      .subscribe((usuario: any) => {
        this.usuario = usuario;
        this.localStorageService.setarUsuario(usuario);

        this.obterInformacoesComplementares();
      })
  }
  private obterInformacoesComplementares() {
    this.usuarioService.obterInformacoesCompletas()
      .subscribe((informacoesCompletasUsuario: any) => {
        const declaracaoConteudoNotaFiscal = informacoesCompletasUsuario.usarDeclaracaoConteudoOuNf.toUpperCase();
        if (declaracaoConteudoNotaFiscal === 'ND' || declaracaoConteudoNotaFiscal === 'DN') {
          this.usuario['declaracao'] = true;
          this.usuario['notafiscal'] = true;
        } else {
          this.usuario['declaracao'] = informacoesCompletasUsuario.usarDeclaracaoConteudoOuNf.toUpperCase() === 'D' ? true : false;
          this.usuario['notafiscal'] = informacoesCompletasUsuario.usarDeclaracaoConteudoOuNf.toUpperCase() === 'N' ? true : false;
        }

        this.usuario['dadoColetaObrigatorio'] = informacoesCompletasUsuario.apresentarFormularioInfoComplementares;
        this.usuario['endereco'] = informacoesCompletasUsuario.endereco;
        this.usuario['cliente'] = informacoesCompletasUsuario.cliente;
        this.usuario['usarDeclaracaoConteudoOuNf'] = informacoesCompletasUsuario.usarDeclaracaoConteudoOuNf;

        this.localStorageService.setarUsuario(this.usuario);
      });
  }
  private criarDadosComplementaresRequest(dadosForm) {
    const dadosComplementares = {
      endereco: {
        cep: dadosForm.cep,
        logradouro: dadosForm.endereco,
        numero: dadosForm.numero,
        complemento: dadosForm.complemento,
        bairro: dadosForm.bairro,
        cidade: dadosForm.cidade,
        estado: dadosForm.estado,
        enderecoTipoId: 'b3ad4d56-7d3e-11ed-b1eb-123e30198a11',
        clienteId: this.usuario.cliente?.id ?? 0,
      },
      cliente: {
        id: this.usuario.cliente?.id ?? 0,
        nome: this.usuario.cliente?.nome ?? dadosForm.razaoSocial,
        cnpjCpf: dadosForm.cnpj,
        inscricaoEstadual: dadosForm.inscricaoEstadual,
        razaoSocial: dadosForm.razaoSocial,
        cnae: dadosForm.tipoEmpresa === 'MEI' ? dadosForm.cnae : ''
      },
    };

    this.usuario.endereco = dadosComplementares.endereco;
    this.usuario.cliente = dadosComplementares.cliente;
    this.localStorageService.setarUsuario(this.usuario);

    return {
      cnpjCpf: dadosComplementares.cliente.cnpjCpf,
      inscricaoEstadual: dadosComplementares.cliente.inscricaoEstadual,
      razaoSocial: dadosComplementares.cliente.razaoSocial,
      cnae: dadosComplementares.cliente.cnae,
      cep: dadosComplementares.endereco.cep,
      logradouro: dadosComplementares.endereco.logradouro,
      numero: dadosComplementares.endereco.numero,
      complemento: dadosComplementares.endereco.complemento,
      bairro: dadosComplementares.endereco.bairro,
      cidade: dadosComplementares.endereco.cidade,
      estado: dadosComplementares.endereco.estado
    }
  }

  private isCamposDesabilitados() {
    let camposEmpresa = this.desabilitaCamposEmpresa();
    let camposEndereco = this.desabilitaCamposEndereco();
    let camposEnderecoColeta = this.desabilitaCamposEnderecoColeta();

    return camposEmpresa && camposEndereco && camposEnderecoColeta;
  }
  focusOutCep(event) {
    if (event.target.value.length > 8 ) {
      this.procurarEndereco(event.target.value);
    }
  }
  focusOutCepColeta(event) {
    if (event.target.value.length > 8 ) {
      this.procurarEnderecoColeta(event.target.value);
    }
  }
  procurarEndereco(cep) {
    this.loadingCep = true;
    this.usuarioService.buscarEnderecoByCEP(cep).subscribe( res => {
      this.dadosColetaForm.controls['endereco'].setValue(res.street);
      this.dadosColetaForm.controls['bairro'].setValue(res.neighborhood);
      this.dadosColetaForm.controls['cidade'].setValue(res.city);
      this.dadosColetaForm.controls['estado'].setValue(res.state);
      this.loadingCep = false;

    }, (erro) => {
      this.loadingCep = false;
    });
  }
  procurarEnderecoColeta(cep) {
    this.loadingCep = true;
    this.usuarioService.buscarEnderecoByCEP(cep).subscribe( res => {
      this.dadosColetaForm.controls['enderecoColeta'].setValue(res.street);
      this.dadosColetaForm.controls['bairroColeta'].setValue(res.neighborhood);
      this.dadosColetaForm.controls['cidadeColeta'].setValue(res.city);
      this.dadosColetaForm.controls['estadoColeta'].setValue(res.state);
      this.loadingCep = false;

    }, (erro) => {
      this.loadingCep = false;
    });
  }
  resetEnderecoColeta(enable) {
    if (enable) {
      this.dadosColetaForm.controls.cepColeta.enable();
      this.dadosColetaForm.controls.enderecoColeta.enable();
      this.dadosColetaForm.controls.numeroColeta.enable();
      this.dadosColetaForm.controls.bairroColeta.enable();
      this.dadosColetaForm.controls.cidadeColeta.enable();
      this.dadosColetaForm.controls.estadoColeta.enable();
    } else {
      this.dadosColetaForm.controls.cepColeta.disable();
      this.dadosColetaForm.controls.enderecoColeta.disable();
      this.dadosColetaForm.controls.numeroColeta.disable();
      this.dadosColetaForm.controls.bairroColeta.disable();
      this.dadosColetaForm.controls.cidadeColeta.disable();
      this.dadosColetaForm.controls.estadoColeta.disable();
    }
  }
  desabilitaCamposEmpresa() {
    return this.dadosColetaForm.value.cnpj != undefined && this.dadosColetaForm.value.cnpj != null && this.dadosColetaForm.value.cnpj != ''
  }
  desabilitaCamposEndereco() {
    return this.dadosColetaForm.value.cep != undefined && this.dadosColetaForm.value.cep != null && this.dadosColetaForm.value.cep != ''
  }

  desabilitaCamposEnderecoColeta() {
    return this.dadosColetaForm.value.enderecoColeta != undefined && this.dadosColetaForm.value.enderecoColeta != null && this.dadosColetaForm.value.enderecoColeta != ''
  }
}
