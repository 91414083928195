import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-dados-empresa',
  templateUrl: './dados-empresa.component.html',
  styleUrls: ['./dados-empresa.component.scss']
})
export class DadosEmpresaComponent implements OnInit {
  cadastroForm;
  public isLoading = false;
  public visiblePassword = false;
  public loadingSuporte = true;
  public comoConheceuOpcoes = [];
  public representanteOpcoes = [];

  @ViewChild('temmplateModalEmailExistente') temmplateModalEmailExistente: TemplateRef<any>;

  modalRef: BsModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private modalService: BsModalService
  ) {
    this.cadastroForm = this.formBuilder.group({
      nomeEmpresa: '',
      nome: '',
      email: ['',[
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]
      ],
      senha: ['',
        [
          Validators.required,
          Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/)
        ]
      ],
      celular: '',
      comoConheceu: '',
      nomeRepresentante: ''
    });
  }

  ngOnInit(): void {
    this.obterComoConheceu();
    this.obterRepresentante();
  }

  onSubmit(cadastroData) {
    this.isLoading = true;
    const obj = {
      "nome": cadastroData.nome,
      "nomeEmpresa": cadastroData.nomeEmpresa,
      "comoConheceuId": cadastroData.comoConheceu,
      "celular": cadastroData.celular,
      "email": cadastroData.email,
      "senha": cadastroData.senha
    }
    this.usuarioService.cadastrar(obj).subscribe((data) => {
      //this.localStorageService.setarToken(data.token);
      //this.localStorageService.setarUsuario(obj);
      //this.router.navigate(['/simulacao/file']);

      if (data.exists) {
        this.abrirModalEmailExistente();
      } else {
        this.acessarLogin();
      }


      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }
  acessarLogin() {
    if (this.modalRef) {
      this.cancelarModal();
    }

    this.router.navigate(['/cadastro/login']);
  }
  acessarEsqueciMinhaSenha() {
    if (this.modalRef) {
      this.cancelarModal();
    }

    this.router.navigate(['cadastro/esqueci-senha']);
  }

  private obterComoConheceu() {
    this.usuarioService.obterOpcoesComoConheceu()
      .subscribe((res) => this.comoConheceuOpcoes = res);
  }
  private obterRepresentante() {
    this.usuarioService.obterOpcoesRepresentantes()
      .subscribe(res => {
        this.representanteOpcoes = res;
        this.loadingSuporte = false;
      }, (err) => {
        this.loadingSuporte = false;
      });
  }

  abrirModalEmailExistente() {
    if (!this.modalRef) {
      this.modalRef = this.modalService.show(this.temmplateModalEmailExistente, {class: 'w-450'});
    }
  }
  cancelarModal() {
    this.modalRef.hide();
  }
}
