 <!-- Menu -->
 <header style="background: #fff;" class="fixed-top shadow-sm">
    <nav class="navbar navbar-expand-xl py-3">
        <div class="container-fluid container-header">
            <div class="mr-3">
                <a href="/">
                    <img src="/assets/images/chegalog.svg" height="50">
                </a>
            </div>

            <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                (click)="navbarOpen = !navbarOpen">
                <span class="material-icons">
                    menu
                </span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'show': navbarOpen}">
                <span class="links">
                    <a [routerLink]="['']" fragment="servicos" class="mx-3">Serviços</a>
                    <a [routerLink]="['']" fragment="como-funciona" class="mx-3">Como Funciona</a>
                    <a [routerLink]="['']" fragment="areaAtuacao" class="mx-3">Área de Atuação</a>
                    <a [routerLink]="['']" fragment="conheca" class="mx-3">Sobre</a>
                    <a href="#perguntas" routerLink="/faq" class="mx-3">Dúvidas</a>
                </span>

                <span class="mr-auto"></span>

                <span class="links">
                    <a routerLink="/rastreamento" class="mx-3">Rastrear Encomenda</a>
                </span>

                <span class="links">
                    <ng-container *ngIf="!isLogado">
                        <button type="button" (click)="login()" class="btn btn-outline-primary btn-md">
                            Entrar
                        </button>
                        <button type="button" class="btn btn-primary btn-md" (click)="simularColeta()" id="simularColeta">
                            Simule seu frete
                        </button>

                    </ng-container>
                    <user-dropdown *ngIf="isLogado"></user-dropdown>
                </span>
            </div>
        </div>
    </nav>
</header>