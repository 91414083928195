<div class="container-user-dropdown" dropdown [ngClass]="{'absolute': absolute}" *ngIf="isLogado">
    <div class="header-user user-select-none" dropdownToggle aria-controls="dropdown-basic">

        <span>Olá, {{ user.nome }}</span>
        <i class="material-icons chevron">arrow_drop_down</i>
    </div>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
        role="menu" aria-labelledby="button-basic">
        <li role="menuitem">
            <a class="dropdown-item botao-menu"  [routerLink]="['/cliente/painel']">
                <i class="material-icons">insights</i>
                Painel
            </a>
        </li>

        <li role="menuitem">
            <a class="dropdown-item botao-menu"  [routerLink]="['/simulacao/file']">
                <i class="material-icons">table_view</i>
                Simular
            </a>
        </li>
        <li class="divider dropdown-divider"></li>
        <li role="menuitem">
            <a class="dropdown-item botao-menu" href="javascript:void(0);" (click)="sair()">
                <i class="material-icons">exit_to_app</i>
                Sair
            </a>
        </li>
    </ul>
</div>