<div class="col">
    <form [formGroup]="mercadoriaForm">
        <div formArrayName="mercadoriasItems">
            <div *ngFor="let item of mercadoriaForm.get('mercadoriasItems')['controls'];let i = index;">
                <div [formGroupName]="i">
                    <div class="d-flex align-items-center mt-4 mb-3"  *ngIf="i > 0">
                        <i class="material-icons text-danger btn-icon mr-2" (click)="removerMercadoria(i)">delete</i>
                        <strong>Pedido {{ i + 1 }}</strong>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-3" >
                            <div class="form-group">
                                <!-- <pre>{{ mercadoriaForm.value | json }}</pre> -->
                                <label for="cepOrigem">CEP de origem *</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="cepOrigem"
                                    mask="00000-000"
                                    placeholder="Ex: 06550-000"
                                    formControlName="cepOrigem"
                                    [readonly]="usuario?.endereco?.cep.length > 0 || i > 0"
                                    [ngClass]="{ 'is-invalid': item?.controls.cepOrigem.errors && (item?.controls.cepOrigem.dirty || item?.controls.cepOrigem.touched) }"
                                    (focusout)="verificarRangeCep($event, item?.controls.cepOrigem.valid)">
                                    <div *ngIf="item?.controls.cepOrigem.errors?.invalido">
                                        <span class="text-danger">CEP inválido</span>
                                    </div>

                                <!-- <div *ngIf="(origem.cep.length > 0 || i > 0)">
                                    <span>{{ mercadoriaForm.get('mercadoriasItems')['controls'][0].controls.cepOrigem.value  }}</span>
                                    <input
                                        type="hidden"
                                        class="form-control"
                                        id="cepOrigem"
                                        mask="00000-000"
                                        placeholder="Ex: 06550-000"
                                        formControlName="cepOrigem">

                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Valor automatico"
                                        readonly
                                        mask="00000-000"
                                        [ngModelOptions]="{standalone: true}"
                                        [ngModel]="mercadoriaForm.get('mercadoriasItems')['controls'][0].controls.cepOrigem.value" />
                                </div> -->
                              </div>
                        </div>
                        <div class="col-12 col-sm-3">
                            <div class="form-group">
                              <label for="cepDestino">CEP de destino *</label>
                              <input
                                    type="text"
                                    class="form-control"
                                    id="cep"
                                    mask="00000-000"
                                    placeholder="Ex: 06550-000"
                                    formControlName="cepDestino"
                                    [ngClass]="{ 'is-invalid': item.controls.cepDestino.errors && (item.controls.cepDestino.dirty || item.controls.cepDestino.touched) }">
                                    <div *ngIf="item.controls.cepDestino.errors?.invalido">
                                        <span class="text-danger">CEP inválido</span>
                                    </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3">
                            <div class="form-group">
                              <label for="tipoMercadoria">Tipo de mercadoria *</label>
                              <input
                                type="text"
                                class="form-control"
                                id="tipoMercadoria"
                                placeholder="Cadeira, Eletrônicos, ..."
                                formControlName="tipoMercadoria"
                                [ngClass]="{ 'is-invalid': item.controls.tipoMercadoria.errors && (item.controls.tipoMercadoria.dirty || item.controls.tipoMercadoria.touched) }">
                            </div>
                        </div>
                        <!-- <input currencyMask [(ngModel)]="value" min="-10.50" max="100.75" /> -->

                        <div [ngClass]="{'col': isModal, 'col-12 col-sm-3': !isModal}">

                            <div class="d-flex align-items-center">
                                <div class="form-group">
                                    <label for="valorMercadoria">Valor total do pedido *</label>
                                    <input
                                    #valueInput
                                    type="tel"
                                    class="form-control text-left"
                                    id="valorMercadoria"
                                    placeholder="R$ 0,00"
                                    currencyMask
                                    [options]="ngxCurrencyOptions"
                                    formControlName="valorMercadoria"
                                    [ngClass]="{ 'is-invalid': item.controls.valorMercadoria.errors && (item.controls.valorMercadoria.dirty || item.controls.valorMercadoria.touched) }">
                                </div>
                                <i
                                    class="material-icons text-muted ml-2 mt-2"
                                    tooltip="Valor máximo da cobertura do seguro R$1000,00 ">help</i>
                            </div>
                        </div>


                    </div>
                    <div class="my-3 d-flex">
                        <div class="mr-3" *ngIf="i == 0">
                            <img src="assets/images/box.svg" width="50" height="50" />
                        </div>
                        <div>
                            <div class="d-flex align-items-center">
                                <strong class="m-0">Volume(s) do pedido</strong>
                                <!-- <i class="material-icons text-muted ml-2" tooltip="Um Pedido é uma Nota Fiscal, Volumes são as embalagens individuais com as mesma características no mesmo pedido.">help</i> -->
                            </div>
                            <small class="text-muted" *ngIf="i == 0">Sua embalagem não pode ultrapassar <strong>80cm</strong> em qualquer das dimenções * (A x L x C) e no máximo <strong>8kg</strong>.</small>
                        </div>
                    </div>

                    <div formArrayName="itensVolumes">
                        <div *ngFor="let itemVolume of item.get('itensVolumes').controls; index as j">
                            <!-- <pre>itemVolume.get('quantidade').value: {{ itemVolume.get('quantidade').value | json }}</pre>
                            <pre>mercadoriaForm.get('mercadoriasItems').controls[i]: {{ mercadoriaForm.get('mercadoriasItems').controls[i].value | json }}</pre>
                            <pre>itemVolume.controls.quantidade.validator: {{itemVolume.controls.quantidade.validator | json }}</pre>
                            <pre>item.get('itensVolumes').controls.length: {{ item.get('itensVolumes').controls.length | json }}</pre> -->
                            <div [formGroupName]="j">

                                <div class="row">
                                    <div class="col-6 col-sm" >
                                        <div class="form-group mb-0">
                                            <label for="quantidade">
                                                {{ isModal ? 'Qtde *' : 'Qtde. de Volumes *'  }}
                                            </label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="quantidade"
                                                placeholder="qtde"
                                                min="1"
                                                max="100"
                                                formControlName="quantidade"
                                                [ngClass]="{ 'is-invalid': itemVolume.controls.quantidade.errors && (itemVolume.controls.quantidade.dirty || itemVolume.controls.quantidade.touched) }">

                                          </div>
                                    </div>

                                    <div class="col-6 col-sm-2">
                                        <div class="form-group mb-0">
                                            <label for="pesoVolume">
                                                {{ isModal ? 'Peso' : 'Peso (Max 8 kg)' }} *
                                            </label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="pesoVolume"
                                                placeholder="8kg"
                                                formControlName="peso"
                                                [ngClass]="{ 'is-invalid': itemVolume.controls.peso.errors && (itemVolume.controls.peso.dirty || itemVolume.controls.peso.touched) }">
                                        </div>
                                    </div>

                                    <div class="row col-12 col-sm-7 align-items-end">
                                        <div class="col-12 col-sm">
                                            <div class="form-group mb-0">
                                                <label for="altura">
                                                    {{ isModal ? 'Altura' : 'Altura (cm)' }} *
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="altura"
                                                    placeholder="cm"
                                                    formControlName="altura"
                                                    min="0"
                                                    max="80"
                                                    [ngClass]="{ 'is-invalid': itemVolume.controls.altura.errors && (itemVolume.controls.altura.dirty || itemVolume.controls.altura.touched) }">
                                              </div>
                                        </div>
                                        <div class="pb-2 hide-mobile d-none d-sm-block">
                                            <span><strong>x</strong></span>
                                        </div>
                                        <div class="col-12 col-sm">
                                            <div class="form-group mb-0">
                                                <label for="largura">
                                                    {{ isModal ? 'Largura' : 'Largura (cm)' }} *
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="largura"
                                                    placeholder="cm"
                                                    formControlName="largura"
                                                    [ngClass]="{ 'is-invalid': itemVolume.controls.largura.errors && (itemVolume.controls.largura.dirty || itemVolume.controls.largura.touched) }">
                                            </div>
                                        </div>
                                        <div class="pb-2 d-none d-sm-block">
                                            <span><strong>x</strong></span>
                                        </div>
                                        <div class="col">
                                            <div class="form-group mb-0">
                                                <label for="comprimento">
                                                    {{ isModal ? 'Compri.' : 'Comp. (cm)' }} *
                                                    </label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="comprimento"
                                                    placeholder="cm"
                                                    formControlName="comprimento"
                                                    [ngClass]="{ 'is-invalid': itemVolume.controls.comprimento.errors && (itemVolume.controls.comprimento.dirty || itemVolume.controls.comprimento.touched) }">
                                              </div>
                                        </div>

                                    </div>
                                    <div class="align-items-end d-none d-sm-flex " style="width: 80px;">
                                        <i class="material-icons btn-icon p-1" *ngIf="j > 0 || item.get('itensVolumes').controls.length > 1" (click)="removerVolume(i, j)">close</i>
                                        <i class="material-icons btn-icon p-1" *ngIf="j == item.get('itensVolumes').controls.length - 1" (click)="adicionarVolume(i);">add</i>
                                    </div>
                                    <div class=" d-sm-none p-3 w-100">
                                        <button type="button" class="btn btn-outline-danger w-100 d-flex align-items-center justify-content-center mb-3" *ngIf="j > 0 || item.get('itensVolumes').controls.length > 1" (click)="removerVolume(i, j)"><i class="material-icons">close</i> REMOVER VOLUME</button>
                                        <button type="button" class="btn btn-outline-primary w-100" *ngIf="j == item.get('itensVolumes').controls.length - 1" (click)="adicionarVolume(i);">+ ADICIONAR VOLUME</button>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>
    <div>
        <button class="btn btn-link-primary" (click)="adicionarMercadoria(mercadoriaForm.get('mercadoriasItems')['controls'][0].controls.cepOrigem.value)">+ Adicionar outro pedido</button>
    </div>
</div>