<section class="hero">
    <header class="fixed-top" style="background-color: #fff;" >
        <nav class="navbar navbar-expand-xl py-4">
            <div class="container-fluid container-header">

                <div class="mr-3">
                    <a href="/">
                        <img src="/assets/images/chegalog.svg" height="50">
                    </a>
                </div>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"  (click)="navbarOpen = !navbarOpen">
                    <span class="material-icons">menu</span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'show': navbarOpen}">
                    <span class="mr-auto"></span>
                    <span class="links">
                        <button type="button" class="btn btn-outline-primary" (click)="login()">
                            Cliente ChegaLog
                        </button>
                        <button type="button" class="btn btn-primary" (click)="simular()">
                            Simule seu frete
                        </button>
                    </span>
                </div>
            </div>
        </nav>
    </header>
    <div class="mt-5 pt-5"></div>
</section>

<section>
    <div class="py-5 container-conteudo-chegalog">
        <div class="container pt-4">
            <div class="row align-items-center">
                <div class="col-10 mx-auto">
                    <div class="text-center conteudo-cliente-chegalog">
                        <h2 class="my-4 mt-5">Sabemos que cada negócio tem suas necessidades. Por isso, estudamos os pedidos dos nossos clientes de todos o país e desenvolvemos o <strong>ChegaLog</strong></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="py-5 my-4">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-9 mx-auto">
                <div class="text-justify">
                    <p class="mt-3">
                        Após 7 anos de existência e ótimos serviços prestados, crescimento sustentável e reconhecimento dos clientes, a POTSPEED desenvolveu um estudo aprofundado em sua carteira de clientes e tomou a decisão de mais uma vez inovar.
                    </p>
                    <p>
                        Percebeu que poderia continuar a contribuir ainda mais com o mercado de entrega de pequenas encomendas, principalmente para os pequenos e médios empreendedores digitais.
                    </p>
                    <p>
                        E com o objetivo de oferecer um serviço de logística de coleta e entrega otimizados de forma simples, rápida e barata para os pequenos e médios empreendedores que buscam competitividade e parceria para seu crescimento, nasce o ChegaLog.
                    </p>
                    <p>
                        O ChegaLog, tem uma forma diferente de calcular o valor do frete, e é com esse diferencial que a POTSPEED irá conquistar e fidelizar, com o mesmo padrão de qualidade apresentado e comprovado por grandes empreendedores, novos clientes.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="py-5 my-4 espaco-grid-imagem">
    <div class="container">
        <div class="row">
            <div class="col-8 mx-auto">
                <p class="h2  text-center">
                    Queremos velocidade não só na entrega, mas também na sua cotação.
                </p>
                <p class="h2 mb-5 text-center">
                    Aqui tudo é muito prático e rápido.
                </p>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container py-4 mt-5">
        <div class="row align-items-start">
            <div class="col-12 col-lg-4 text-center px-5">
                <div class="mb-3">
                    <div class="container-numeracao">
                        <span class="numeracao">1</span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="72px" height="72px" viewBox="0 0 48 48" data-numeracao="1">
                        <g stroke-width="2" transform="translate(0, 0)">
                            <polyline points="17 46 5 46 5 2 41 2 41 17" fill="none" stroke="#1055BD" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="miter"></polyline>
                            <polygon data-color="color-2" points="30 43 23 45 25 38 40 23 45 28 30 43" fill="none" stroke="#1055BD" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="miter"></polygon>
                        </g>
                    </svg>
                </div>
                <div>
                    <p class="h5 text-center px-5">
                       Faça o seu cadastro e da sua empresa.
                    </p>
                </div>
            </div>
            <div class="col-12 col-lg-4 text-center px-5">
                <div class="mb-3">
                    <div class="container-numeracao">
                        <span class="numeracao">2</span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="72px" height="72px" viewBox="0 0 64 64" data-numeracao="2">
                        <g stroke-width="2" transform="translate(0, 0)"><line data-cap="butt" fill="none" stroke="#1055BD" stroke-width="2" stroke-miterlimit="10" x1="32" y1="48" x2="32" y2="26" stroke-linejoin="miter" stroke-linecap="butt"></line>
                        <polyline fill="none" stroke="#1055BD" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" points="22,36 32,26
                            42,36 " stroke-linejoin="miter"></polyline>
                        <path data-color="color-2" fill="none" stroke="#1055BD" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" d="M40,52h12
                            c5.6-0.5,10-5.3,10-11c0-5.8-4.4-10.5-10.1-11c-1-10.1-9.5-18-19.9-18c-9,0-16.6,6-19.1,14.2C6.7,27.2,2,32.5,2,39
                            c0,6.8,5.3,12.5,12,13h10" stroke-linejoin="miter"></path></g>
                    </svg>
                </div>
                <div>
                    <p class="h5 px-4">
                        Importe seus arquivos (CTE e NFE)
                    </p>
                </div>
            </div>
            <div class="col-12 col-lg-4 text-center px-5">
                <div class="mb-3">
                    <div class="container-numeracao">
                        <span class="numeracao">3</span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="72px" height="72px" viewBox="0 0 48 48" data-numeracao="3">
                        <g stroke-width="2" transform="translate(0, 0)"><circle fill="none" stroke="#1055BD" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" cx="24" cy="24" r="22" stroke-linejoin="miter"></circle>
                        <line data-color="color-2" fill="none" stroke="#1055BD" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" x1="24" y1="10" x2="24" y2="38" stroke-linejoin="miter"></line>
                        <path data-color="color-2" fill="none" stroke="#1055BD" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" d="
                            M30.75,14.438c-3.242-1.783-13.453-3.112-13.453,3.026c0,7.348,12.967,4.755,12.967,11.238S21.835,34.699,16,32.591" stroke-linejoin="miter"></path></g>
                    </svg>
                </div>
                <div>
                    <p class="h5 px-4">
                        Saiba os valores dos fretes em segundos.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="row align-items-center">
        <div class="col">
            <div class="text-center">
                <button class="btn btn-primary btn-lg cta px-5 my-5" (click)="simular()">FAÇA SUA COTAÇÃO AGORA</button>
            </div>
        </div>
    </div>
</div>
<!--
<section>
    <carousel [noPause]="false" [(activeSlide)]="activeSlide" [showIndicators]="false">
         <div >
            <ol class="carousel-indicators">
                <li *ngFor="let slide of slides; let i = index" [class.active]="i === activeSlide" [attr.aria-pressed]="i === activeSlide" (click)="switchSlide(i)"></li>
            </ol>
        </div>
        <slide *ngFor="let slide of slides">
          <div class="text-center py-5 text-black" style="background-color: #dedede;">
            <h2 class="my-4 pb-5">Depoimentos</h2>
            <div class="lead">
                <p class="col-12 col-lg-5 col-md-5 col-xs-1 mx-auto mb-5">
                    {{slide.conteudo}}
                </p>
                <div class="mb-4">
                    <span style="font-weight: 500;"> {{slide.autor}}</span> <br>
                    <span> {{slide.cargo}}</span>
                </div>
            </div>
          </div>
        </slide>

      </carousel>
</section> -->

<section class="py-5 mt-4">
    <div class="text-center px-3">
        <h2>Empresas que confiam na Pot Speed</h2>
        <p>Grandes marcas contam com as soluções da Pot Speed para manter a excelência em seus serviços e atendimento.</p>
    </div>

    <div class="container py-4">
        <div class="lista-empresas d-flex justify-content-center">
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/via-varejo.jpg" height="45">
            </div>
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/dafiti_logo.jpg" height="45">
            </div>
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/kanui_logo.jpg" height="45">
            </div>
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/loghaus_logo.jpg" height="45">
            </div>
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/marisa_logo.jpg" height="45">
            </div>
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/centauro.jpg" height="45">
            </div>
        </div>
        <div class="lista-empresas d-flex justify-content-center">
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/posthaus_logo.jpg" height="45">
            </div>
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/privalia_logo.jpg" height="45">
            </div>
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/toy_mania_logo.jpg" height="45">
            </div>
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/tricae_logo.jpg" height="45">
            </div>
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/westwing_logo.jpg" height="45">
            </div>
            <div class="empresa mx-3">
                <img src="assets/images/logos-parceiros/magazine_luisa.jpg" height="45">
            </div>
        </div>
    </div>
</section>


<app-footer></app-footer>