import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SimulacaoRoutingModule } from './simulacao-rounting.module';
import { UploadArquivoComponent } from './upload-arquivo/upload-arquivo.component';
import { DadosColetaComponent } from './dados-coleta/dados-coleta.component';
import { DadosDestinatarioComponent } from './dados-destinatario/dados-destinatario.component';
import { SimulacaoComponent } from './simulacao.component';
import { FileComponent } from './file/file.component';
import { DragDropDirective } from '../drag-drop.directive';
import { CotacaoComponent } from './cotacao/cotacao.component';
import { PagamentoComponent } from './pagamento/pagamento.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AuthGuardService } from '../guards/auth-guard.service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
var options: Partial<IConfig> | (() => Partial<IConfig>);
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxCurrencyModule } from "ngx-currency";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PreenchimentoManualComponent } from './preenchimento-manual/preenchimento-manual.component';
import { FormularioComponent } from './preenchimento-manual/formulario/formulario.component';
import { DragAreaComponent } from './file/drag-area/drag-area.component';
import { TermoModule } from '../termo/termo.module';
import { CadastroSimulacaoComponent } from './cadastro/cadastro-simulacao.component';
import { ValidarAcessoComponent } from './cadastro/validar-acesso/validar-acesso.component';
import { DocumentoFiscalComponent } from './documento-fiscal/documento-fiscal.component';
import { UserDropdownModule } from '../components/user-dropdown.module';

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};

@NgModule({
  declarations: [
    SimulacaoComponent,
    UploadArquivoComponent,
    DadosColetaComponent,
    DadosDestinatarioComponent,
    FileComponent,
    DragDropDirective,
    PreenchimentoManualComponent,
    CotacaoComponent,
    PagamentoComponent,
    FormularioComponent,
    DragAreaComponent,
    CadastroSimulacaoComponent,
    ValidarAcessoComponent,
    DocumentoFiscalComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CommonModule,
    BrowserModule,
    CollapseModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SimulacaoRoutingModule,
    TermoModule,
    NgxMaskModule.forRoot(options),
    ProgressbarModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    UserDropdownModule
  ]
})
export class SimulacaoModule { }
