import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { LocalStorageService } from 'src/app/services/localStorage/localstorage.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';


@Component({
  selector: 'app-cadastro-simulacao',
  templateUrl: './cadastro-simulacao.component.html',
  styleUrls: ['./cadastro-simulacao.component.scss']
})
export class CadastroSimulacaoComponent implements OnInit {
  cadastroForm;
  public isLoading = false;
  public visiblePassword = false;
  public loadingSuporte = true;
  public comoConheceuOpcoes = [];
  public representanteOpcoes = [];

  @ViewChild('temmplateModalEmailExistente') temmplateModalEmailExistente: TemplateRef<any>;

  modalRef: BsModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private modalService: BsModalService
  ) {
    this.cadastroForm = this.formBuilder.group({
      nomeEmpresa: '',
      nome: '',
      email: ['',[
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]
      ],
      senha: ['',
        [
          Validators.required,
          Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/)
        ]
      ],
      celular: '',
      comoConheceu: '',
      nomeRepresentante: ''
    });
  }

  ngOnInit(): void {
    this.obterComoConheceu();
    this.obterRepresentante();
  }

  onSubmit(cadastroData) {
    this.isLoading = true;

    const obj = {
      'nome': cadastroData.nome,
      'nomeEmpresa': cadastroData.nomeEmpresa,
      'comoConheceuId': cadastroData.comoConheceu,
      'celular': cadastroData.celular,
      'email': cadastroData.email,
      'senha': cadastroData.senha
    }

    this.usuarioService.cadastrar(obj).subscribe((data) => {
      var statusCode = data.status;

      if (data.exists) {
        this.abrirModalEmailExistente();
        // this.router.navigate(['/cadastro/login'], {state: { usuario: data.usuario }});
        // this.router.navigate(['/cadastro/login','user', 11])
      } else {
        this.localStorageService.setarUsuario(data);
        this.realizarLoginAutomatico(obj.email, obj.senha)
        this.isLoading = false;

        // this.router.navigateByUrl('/simulacao/validar-acesso');
      }
    }, (err) => {
      this.isLoading = false;
    });
  }
  realizarLoginAutomatico(email, senha) {
      this.isLoading = true;
      this.usuarioService.logar(email, senha).subscribe((res) => {
        this.localStorageService.setarToken(res.token);
        this.setarDadosUsuario();
      }, (err) => {
        this.isLoading = false;
        console.log(err);
      });
  }
  setarDadosUsuario() {
    this.usuarioService.obterDetalhes()
      .subscribe((usuario: any) => {
        this.localStorageService.setarUsuario(usuario);

        this.router.navigateByUrl('/simulacao/cotacao');
        this.isLoading = false;
      })
  }
  acessarLogin() {
    if (this.modalRef) {
      this.cancelarModal();
    }

    this.router.navigate(['/cadastro/login']);
  }
  acessarEsqueciMinhaSenha() {
    if (this.modalRef) {
      this.cancelarModal();
    }

    this.router.navigate(['cadastro/esqueci-senha']);
  }

  private obterComoConheceu() {
    this.usuarioService.obterOpcoesComoConheceu()
      .subscribe((res) => this.comoConheceuOpcoes = res);
  }
  private obterRepresentante() {
    this.usuarioService.obterOpcoesRepresentantes()
      .subscribe(res => {
        this.representanteOpcoes = res;
        this.loadingSuporte = false;
      }, (err) => {
        this.loadingSuporte = false;
      });
  }

  abrirModalEmailExistente() {
    if (!this.modalRef) {
      this.modalRef = this.modalService.show(this.temmplateModalEmailExistente, {class: 'w-450'});
    }
  }
  cancelarModal() {
    this.modalRef.hide();
  }
}
