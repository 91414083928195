import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FreteService } from 'src/app/services/frete/frete.service';
import { LocalStorageService } from 'src/app/services/localStorage/localstorage.service';

@Component({
  selector: 'app-detalhe-historico',
  templateUrl: './detalhe-historico.component.html',
  styleUrls: ['./detalhe-historico.component.scss']
})
export class DetalheHistoricoComponent implements OnInit {
  public ocorrencias:any[] = []
  public isLoading = true;
  public chave;
  public declaracaoConteudoOuNf;
  public arquivoPendente;
  public temDeclaracaoConteudo;
  public destinatario = '';
  public valorFrete = 0;
  public cpfDestinatario = '';
  public numeroPedido = '';
  public freteId = '';
  public modalRef: BsModalRef;
  public etiquetas = 1;
  public usuario;
  public nfe;
  public numero = '';
  public codigo = '';

  public docs: any[] = [];
  public uploaded = false;
  public fileToUpload: File = null;
  public formUpload;
  public progress;
  public files: Set<File> = new Set();
  public uploading = false;
  public possuiDuplicadas = false;
  public possuiNotaDiferenteCNPJ = false;
  public isLogado = false;

  private sub: any;

  @ViewChild('file', { static: false }) file;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private localStorage: LocalStorageService,
    private freteService: FreteService
  ) {

    this.chave = this.route.snapshot.paramMap.get('chave');
    this.usuario = this.localStorage.obterUsuario();

    this.formUpload = this.formBuilder.group({
      fileUpload: ''
    });
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.destinatario = params.destinatario;
      this.valorFrete = params.valorFrete;
      this.cpfDestinatario = params.cpfDestinatario;
      this.freteId = params.freteId;
      this.declaracaoConteudoOuNf = params.declaracaoConteudoOuNf;
      this.arquivoPendente = params.arquivoPendente == "true";
      this.temDeclaracaoConteudo = params.temDeclaracaoConteudo == "true";
      this.nfe = params.nfe;  
      this.numero = params.numeroPedido;  
      this.codigo = params.codigo;    
      this.etiquetas = parseInt(params.numeroEtiquetas ?? "0")   
      this.isLoading = false;
      this.freteService.listarOcorrenciasPedido(this.freteId, this.nfe).subscribe(lista => {
        this.ocorrencias = lista;
      })
    });
    

  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  voltar() {
    this.router.navigateByUrl('/cliente/painel')
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  terminouUpload(event) {

    this.possuiNotaDiferenteCNPJ = false;
    if (event.possuiNotaDiferenteCNPJ) {
      this.possuiNotaDiferenteCNPJ = true;
    }
    this.uploading = false;

    this.uploaded = false;

    if (event.docs.length > 0) {
      this.docs = event.docs;
      this.uploaded = true;
    }
  }
}
