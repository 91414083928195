<p>
    ChegaLog é o nome de uma nova e inovadora modalidade de serviço oferecida pela POT SPEED DISTRIBUIÇÃO DE ENCOMENDAS EIRELI, cujo objetivo é atender as demandas logísticas de pequenas e médias empresas do e-commerce, de forma simples, descomplicada e sem intermediários. O próprio USUÁRIO, você, gerencia a entrega dos seus pedidos no painel de controle ChegaLog.
</p>
<p>
    Estes “Termos de Uso” que seguem, se aplicam aos serviços oferecidos pelo ChegaLog, com sua revolucionária plataforma, destinada a coletar e entregar pequenas encomendas nos municípios da abrangência nela discriminadas. Após a leitura Obrigatória do mesmo, o USUÁRIO desses serviços deve, se estiver de pleno acordo com TODAS as condições nele  estabelecidas, clicar na tecla indicada concordando com os “Termos de Uso”, e caso NÃO concorde, deverá sair da plataforma e não prosseguir na aceitação, com o que não poderá fazer uso dos serviços do ChegaLog.
</p>
<p><strong>1 – CADASTRAMENTO</strong></p>
<p>
    Atualmente os serviços oferecidos pelo ChegaLog se destinam exclusivamente às pessoas jurídicas, devidamente e regularmente registradas nos respectivos órgãos jurídicos governamentais e que estejam com todos os seus direitos de exercer suas respectivas atividades em dia.
</p>
<p>
    Ao se cadastrar para utilizar os serviços do ChegaLog, o USUÁRIO deve fornecer todas as informações solicitadas e manter este cadastro sempre atualizado.
</p>

<p>
    A qualquer instante este cadastramento e registro pode, ao exclusivo critério dos gestores do ChegaLog, ser suspenso ou cancelado, desde que sejam identificadas informações incorretas ou inverídicas, atos em desacordo com estes Termos de Uso ou em desacordo com qualquer legislação municipal, estadual ou federal, que regem e regulamentam as operações comerciais ora contratadas, ou por qualquer motivo que desabone a conduta do USUÁRIO.
</p>
<p>
    Na hipótese de suspensão ou cancelamento do registro, automaticamente todas as operações serão suspensas e as mercadorias devolvidas ao USUÁRIO, sem nenhum direito de retratação ou penalidade.
</p>


<strong>2 – CONFIDENCIALIDADE</strong>

<p>
    Todas as informações relativas as operações contratadas, devem ser tratadas com absoluta confidencialidade tanto pelo USUÁRIO como pelo ChegaLog e respectivos colaboradores, sob pena de imediato rompimento dos serviços prestados, subsistindo a confidencialidade por 24(vinte e quatro) meses após a desvinculação das partes, com exceção da determinação de apresentação das informações por determinação judicial, quando a confidencialidade será quebrada apenas nos limites de tal determinação, podendo a parte que for prejudicada pela quebra indevida de confidencialidade ser responsabilizada em perdas e danos de acordo com a legislação civil.
    O USUÁRIO é o único responsável pelo acesso a plataforma do ChegaLog, devendo tratar suas informações e senhas no mais absoluto sigilo, não fornecendo nunca a terceiros qualquer acesso ou informação, que possibilite utilização ilegal desta plataforma e seus dados.
    Ao aceitar estes Termos de Uso, o USUÁRIO autoriza o ChegaLog a receber, analisar, tratar, processar e armazenar todos os seus dados, sempre com a finalidade de atender as necessidades da operação e seus registros.
</p>
<strong>3 – REGRAS DE FUNCIONAMENTO</strong>

<p><strong>3.1 – COLETA</strong></p>
<p>
    Em todos os lugares onde o ChegaLog disponibilizar suas coletas, ela se dará sempre em dias úteis, após a aprovação da solicitação. Deve sempre ser no endereço comercial do USUÁRIO, aquele cadastrado no sistema, em locais de fácil e rápido acesso.

</p>
<p>
    Caso este endereço comercial seja em locais de riscos ou que implique em demora para ser acessado, como em centros comerciais, esta informação deve ser previamente fornecida na solicitação da coleta e, a seu exclusivo critério, o ChegaLog pode recusar a mesma.
</p>
<p>
    O USUÁRIO só deve solicitar coleta, para os pedidos destinados aos municípios atendidos pelo ChegaLog, relacionados e disponíveis na plataforma. Na eventualidade do USUÁRIO possibilitar a coleta de pedidos para praças não relacionadas na plataforma, eles serão devolvidos ao mesmo, sem restituição do valor pago, de forma a compensar a falha e a operação de devolução.
</p>
<p>
    É de total responsabilidade do USUÁRIO, acondicionar seus pedidos de forma a garantir a proteção dos mesmos e eles devem estar sempre com as Notas Fiscais afixadas do lado externo dos volumes, dentro de sacos plásticos comumente denominados de “canguru”.
</p>
<p>
    A responsabilidade do conteúdo dos volumes é totalmente do USUÁRIO, uma vez que este conteúdo não pode ser acessado e conferido pelo ChegaLog.
</p>
<p>
    Os limites do formato e do peso estão descritos na plataforma, na solicitação das coletas.
</p>
<p>
    O ChegaLog pode rejeitar e não se responsabilizar por qualquer volume com problema de embalagem, de fragilidade, aqueles com inadequação aos limites de formato e peso, nocividade da embalagem ou do conteúdo ou que faça parte da relação de produtos que não transporte. Ver item 6 deste Termos de Uso.
</p>
<p><strong>3.2 – ENTREGA</strong></p>
<p>
    Após a coleta, os volumes serão transferidos para o Centro de Distribuição do ChegaLog, onde serão etiquetados, roteirizados, paletizados, expedidos e transferidos para suas cidades de destino. Nestas cidades serão finalmente entregues por parceiros distribuidores aos clientes finais.
</p>
<p>
    Serão realizadas até três tentativas de entrega. Se após estas tentativas a entrega ainda assim for frustrada, os pedidos serão devolvidos aos USUÁRIOS, sendo cobrado por esta operação o mesmo valor de uma entrega.
</p>
<p>
    Todas as etapas do processo podem ser acompanhadas não só pelo USUÁRIO, como também pelos clientes finais. Hoje quase a totalidade das informações de entrega são atualizadas em tempo real, através do uso de aplicativos mobiles e GPS.
</p>
<p>
    Caso haja contradição entre a informação de entrega e uma eventual alegação de não recebimento pelo cliente, é feita uma acareação entre o entregador e o cliente, de forma a que se eliminem as divergências e na hipótese do ChegaLog concluir pela falha operacional, o valor do produto será ressarcido ao USUÁRIO, pelo mesmo valor nominal que consta na nota fiscal para o produto em questão.  O prazo para acareação é de até sete dias úteis.
</p>
<p>
    Da mesma forma que a abrangência do ChegaLog, os prazos padrão previstos de entrega para cada localidade destino, estão sempre disponíveis na plataforma e são atualizados a medida que seja necessário.
</p>
<p>
    O ChegaLog não se responsabiliza por eventuais atrasos dos pedidos em virtude de acidentes, paralizações nas rodovias, greves, atrasos dos distribuidores parceiros ou quaisquer outros fatos fortuitos que atrapalhem ou impeçam as entregas.
</p>


<p><strong>4 – SEGURO DOS PEDIDOS</strong></p>
<p>
    O ChegaLog tem para todos os pedidos dos serviços contratados seguro e gerenciamento de risco contra acidentes e roubos, com seguradora idônea e reconhecida do mercado. Caso ocorra algum sinistro, uma franquia de 20% (vinte por cento) é de responsabilidade do Usuário, mas 80% (oitenta por cento) do valor da nota fiscal dos produtos será ressarcido ao Usuário.
    Importante: o valor do seguro já está incluso no frete contratado.
</p>
<p><strong>5 – FRETE INOVADOR</strong></p>

<p>
    O ChegaLog vem com uma solução realmente inovadora no mercado do e-commerce. É de fato uma ideia revolucionária, fácil de ser compreendida e muito rápida de ser aplicada no cálculo do frete.
</p>
<p>
    O algoritmo do modelo ChegaLog consegue o equilíbrio entre o valor pago pelas empresas com menor valor agregado de seus produtos e as empresas com produtos de maior valor, evitando a distorção de se pagar o mesmo preço para produtos de alto e baixo valor agregado.
</p>
<p>
    Como referido, o valor do seguro e o gerenciamento de risco de toda operação já estão embutidos no frete, outra simplificação.
</p>
<p>
    Os únicos valores que serão agregados ao frete serão o ICMS e o ISS, respeitando a legislação vigente no estado ou município.
</p>


<p>
    <strong>
        6 – PRODUTOS NÃO TRANSPORTADOS
    </strong>
</p>

<p>
    O ChegaLog não transporta os seguintes tipos de produto:
</p>
<ul>
    <li>
        alimentos perecíveis
    </li>
    <li>
        animais, seres vivos
    </li>
    <li>
        antiguidades, obras de arte, objetos raros e valiosos
    </li>
    <li>
        armas, suas réplicas, munições
    </li>
    <li>
        cargas radiativas ou nucleares
    </li>
    <li>
        cigarros
    </li>
    <li>
        drogas ilícitas
    </li>
    <li>
        jóias, pedras preciosas ou semi-preciosas
    </li>
    <li>
        líquidos e materiais que são inflamáveis, explosivos ou precisem de refrigeração
    </li>
    <li>
        medicamentos, vacinas, humanos ou animais
    </li>
    <li>
        metais preciosos
    </li>
    <li>
        objetos de vidro, cristal ou porcelana
    </li>
    <li>
        produtos químicos
    </li>
    <li>
        produtos, drogas ou substâncias ilícitas, proibidas ou não autorizadas pela legislação vigente
    </li>
    <li>
        restos fúnebres
    </li>
    <li>
        valores monetários, ações, títulos, notas promissórias, escrituras e similares
    </li>
    <li>
        ou todo e qualquer carga considerada ilegal pela legislação vigente.
    </li>
</ul>
<p>
    Caso o USUÁRIO tenha dúvidas sobre se determinado produto pode ser transportado ou não, deve previamente consultar o Departamento Comercial do ChegaLog.
</p>


<p>
    <strong>
        7 – DISPOSIÇÕES FINAIS
    </strong>
</p>

<p>
    O presente “Termos de Uso” deve ser cumprido e nortear as relações e ações entre o ChegaLog e o USUÁRIO.
</p>
<p>
    As comunicações ou notificações entre ChegaLog e o USUÁRIO, que sejam relativas aos serviços contratados, devem ser tratadas por escrito e de modo formal.
</p>
<p>
    Eventuais divergências entre o ChegaLog e o USUÁRIO, que não encontra acordo entre as Partes, serão submetidas ao Foro da Comarca Central de São Paulo.
</p>
<p>
    O ChegaLog poderá a qualquer instante alterar, acrescentar ou suprimir as presentes cláusulas, visando a atualização, otimização e melhoria dos serviços prestados, entrando em vigência de imediato.
</p>

