<!-- <div style="border: 1px solid red; padding: .5rem;">
    <div class="mb-3">
        <small class="text-danger ">drag-area</small>
    </div>
    <pre>uploading: {{ uploading | json }}</pre>
    <pre>uploaded: {{ uploaded | json }}</pre>
    <pre>possuiNotaDiferenteCNPJ: {{ possuiNotaDiferenteCNPJ | json }}</pre>
    <pre>possuiDuplicadas: {{ possuiDuplicadas | json }}</pre>
    <pre>docs.length {{ docs.length | json }}</pre>
</div> -->
<!-- <pre>{{tiposArquivos | json}}</pre> -->
<div
    *ngIf="usuario?.ativo"
    class="drop-area"
    appDnd
    (fileDropped)="onFileDropped($event)"
    [ngClass]="{
        'uploaded': uploaded && !possuiDuplicadas && !possuiNotaDiferenteCNPJ && !possuiVolumeIcorreto,
        'arquivos-duplicados': possuiDuplicadas,
        'notas-diferentes': possuiNotaDiferenteCNPJ
    }">
    <i class="material-icons text-muted helper-floated" tooltip="Ganhe tempo com suas cotações de frete. Selecione ou arraste arquivos xml ou xlsx  para dentro da linha pontilhada e o sistema irá calcular a cotação dos fretes dos seus pedidos.
    Obs.: O modelo do excel está no botão logo abaixo.  Caso use XML da NFe, o campo remetente deve estar com o CNPJ que você usou no nosso cadastro.
    " *ngIf="!uploaded">help</i>

    <input *ngIf="!uploaded" type="file" #file id="file" (change)="fileChange()" multiple accept="{{tiposArquivos}}" required />

    <ng-container>

        <div class="text-center w-100" *ngIf="possuiDuplicadas ? true : (!uploaded || possuiNotaDiferenteCNPJ)">
            <ng-container *ngIf="!uploaded">
                <ng-container *ngIf="!uploading">
                    <div class="row">
                        <div class="col text-center">
                            <img src="assets/images/tipos-arquivos.png" class="mb-3 img-mobile" width="241" height="130" />
                            <button class="btn btn-primary px-4"><label for="file" class="m-0">Selecione arquivo do seu computador</label></button>
                        </div>
                        <div class="col-1 d-flex align-items-center justify-content-center container-ou">
                            <span class="ou">
                                <span class="texto">ou</span>
                            </span>
                        </div>
                        <div class="col text-center align-items-center justify-content-center d-flex arraste-mobile">

                            <div class="text-center">
                                <img src="assets/images/seta-upload.png" />
                                <!-- <img src="assets/images/nuvem.svg" /> -->
                                <div class="mt-3">
                                    <!-- <button class="btn btn-link px-4"><label for="file" class="m-0">SELECIONE ARQUIVO DO SEU COMPUTADOR</label></button> -->
                                    <p class="texto-escolher"><i>Arraste e solte os arquivos XML ou XLS para carregar.<br /> (Máximo de 50 arquivos)</i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="uploading">
                    <div class="text-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div>
                            <p>Aguarde estamos processando todos os arquivos</p>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <!-- <ng-container *ngIf="possuiDuplicadas || uploading"> -->

            <div class="text-center" *ngIf="possuiDuplicadas">
                <div class="text-center mb-4">
                    <i class="material-icons text-danger">error_outline</i>
                    <p>Ops, você tentou subir arquivos que já foram processados.<br />Por favor envie arquivos diferentes<br /></p>
                    <!-- <a href="javascript:void(0);" (click)="alterarArquivo()">Alterar arquivo</a> -->
                </div>
            </div>


            <ng-container>
                <div class="text-left pb-2" *ngFor="let file of files" style="min-width: 416px;">
                    <small>{{ file.name }}</small>
                    <!-- {{ (progress[file.name].progress | async) == 100 ? (progress[file.name].duplicada  ? 'danger' : 'success') : 'warning'}} -->
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- <pre>progress[file.name].duplicada: {{ progress[file.name].duplicada | json }}</pre> -->
                            <progressbar
                                *ngIf="progress"
                                [type]="tipoProgressBar(progress[file.name].progress | async, progress[file.name].duplicada, progress[file.name].isMesmoCNPJ )"
                                [value]="progress[file.name].progress | async "
                                [striped]="(progress[file.name].progress | async) == 100 ? false : true">
                                <ng-container *ngIf="!progress[file.name].duplicada">
                                    <span [ngClass]="{'text-hide': !progress[file.name].isMesmoCNPJ}">{{ progress[file.name].progress | async }}%</span>
                                </ng-container>

                                <span *ngIf="progress[file.name].duplicada">ARQUIVO DUPLICADO</span>
                                <span *ngIf="!progress[file.name].duplicada && !progress[file.name].isMesmoCNPJ && (progress[file.name].progress | async) == 100 && !uploading">{{progress[file.name].msg}}</span>
                            </progressbar>

                        </div>
                        <i
                            class="material-icons botao-remover"
                            *ngIf="!progress[file.name].isMesmoCNPJ && possuiNotaDiferenteCNPJ || progress[file.name].duplicada"
                            (click)="removerItemCotacao(progress[file.name], file);$event.stopPropagation();"
                            tooltip="Remover">delete</i>
                        <!-- <div>
                        </div> -->
                    </div>
                </div>
            </ng-container>

        </div>

    </ng-container>
    <div class="text-center" *ngIf="possuiVolumeIcorreto">
        <div class="text-center mb-4">
            <i class="material-icons text-danger">error_outline</i>
            <p>Ops, o arquivo que você enviou possui os dados de volume incorretos, altura, largura, comprimento ou peso estão diferentes dos valores que aceitamos.<br /></p>
            <a href="javascript:void(0);" (click)="alterarArquivo()">Alterar arquivo</a>
        </div>
    </div>

    <ng-container *ngIf="uploaded && !possuiDuplicadas && !possuiNotaDiferenteCNPJ && !possuiVolumeIcorreto">
        <div class="text-center">
            <i class="material-icons">check</i>
            <p>Arquivo(s) carregados com sucesso.<br /></p>
            <a href="javascript:void(0);" (click)="alterarArquivo()">Alterar arquivo</a>
        </div>
    </ng-container>
    <ng-container *ngIf="possuiNotaDiferenteCNPJ">
        <div class="text-center">
            <!-- <i class="material-icons">check</i> -->
            <!-- <p>Arquivo(s) carregados com sucesso.<br /></p> -->
            <a href="javascript:void(0);" (click)="alterarArquivo()">Alterar arquivo</a>
        </div>
    </ng-container>

</div>
<!-- <pre>uploaded: {{ uploaded | json }}</pre>
<pre>!possuiDuplicadas: {{ !possuiDuplicadas | json  }}</pre>
<pre>!possuiNotaDiferenteCNPJ: {{ !possuiNotaDiferenteCNPJ | json  }}</pre> -->