import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {forkJoin} from 'rxjs';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';
import {FreteService} from './../../../services/frete/frete.service';

@Component({
  selector: 'app-drag-area-cliente',
  templateUrl: './drag-area-cliente.component.html',
  styleUrls: ['./drag-area-cliente.component.scss']
})
export class DragAreaClienteComponent implements OnInit {
  // public files: any[] = [];
  public docs: any[] = [];
  public uploaded = false;
  public fileToUpload: File = null;
  public formUpload;
  public progress;
  public files: Set<File> = new Set();
  public uploading = false;
  public usuario;
  public possuiDuplicadas = false;
  public possuiNotaDiferenteCNPJ = false;
  public possuiVolumeIcorreto = false;

  @ViewChild('file', { static: false }) file

  @Output() finish = new EventEmitter<any>();
  @Input() isModal = false;
  @Input() tiposArquivos = '';
  @Input() freteId = '';


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private localStorage: LocalStorageService,
    private freteService: FreteService
  ) {
    this.formUpload = this.formBuilder.group({
      fileUpload: ''
    });
    this.usuario = this.localStorage.obterUsuario();
  }

  ngOnInit(): void {
    var extencoes = ['.xml', '.xls', '.xlsx'];
    if (this.tiposArquivos.length == 0) {
      extencoes.map( ex => {
        this.tiposArquivos  += ex + ',';
      });
    } else {
      var tipos: any = this.tiposArquivos
      var listaTipos = '';
      tipos.map( t => {
        listaTipos += `.${t}${(tipos.length == 1 ? '' : ',')}`
      });
      this.tiposArquivos = listaTipos;
    }

  }
  ngAfterViewInit() {}

  // BOTÃO VOLTAR
  public voltar() {
    // this.router.navigateByUrl('/simulacao/dados-coleta');
    window.history.back();
  }

  // change do arquivo
  /**
  * PASSO 1
  */
  onFileDropped(files) {
    let arquivos = files;
    this.fileChange(arquivos);
  }
  /**
  * PASSO 2
  */
  async fileChange(filesParams?) {
    if (!this.uploaded) {
      this.uploading = true;
      const files: { [key: string]: File } = (filesParams ? filesParams.length > 0 : null) ? filesParams : this.file.nativeElement.files;
      for (let key in files) {
        if (!isNaN(parseInt(key))) {
          this.files.add(files[key]);
        }
      }

      await this.fazerUpload();
    }
  }
  /**
  * PASSO 3
  */
  async fazerUpload() {
    this.uploading = true;
    this.uploaded = false;

    this.progress = await this.freteService.uploadPorFrete(this.files, this.freteId);
    // console.log(this.progress);

    for (const key in this.progress) {
      this.progress[key].progress.subscribe(val => console.log(val));
    }

    let allProgressObservables = [];

    for (let key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
    }

    forkJoin(allProgressObservables).subscribe(async (end: any) => {

      for (const key in this.progress) {

        // if (!this.progress[key].isMesmoCNPJ) {
        //   this.possuiNotaDiferenteCNPJ = true;
        // }
        // if (this.progress[key].duplicada) {
        //   this.possuiDuplicadas = true;
        // }

        // if (this.progress[key].tipo == 'xls') {
        //   this.progress[key].doc.map(doc => {
        //     if (!this.volumeValido(doc)) {
        //       this.possuiVolumeIcorreto = true;
        //     }
        //   });
        // }


        // Se for XML O DOC É O PROPRIO OBJETO
        // if (this.progress[key].tipo == 'xml') {
        //   this.docs.push(this.progress[key].doc);
        //   this.docs[this.docs.length - 1]._id = this.progress[key]._id;
        //   this.docs[this.docs.length - 1].tipo = this.progress[key].tipo;
        //   this.docs[this.docs.length - 1].idArquivo = this.progress[key].idArquivo;
        // }
        // SE FOR XLS CADA DOC POSSUI LINHAS
        // if (this.progress[key].tipo == 'xls') {
        //   this.progress[key].doc.map(doc => {
        //     let itemDoc = doc;
        //     itemDoc._id = 'item-' + Math.floor(Math.random() * 50000) + 10000;
        //     itemDoc.tipo = this.progress[key].tipo;
        //     if (this.itemValido(doc)) {
        //       this.docs.push(doc);
        //     }
        //   });
        // }
      }

      this.uploading = false;
      this.uploaded = true;
      if (!this.possuiDuplicadas && !this.possuiVolumeIcorreto) {
        this.finish.emit({
          success: true,
          possuiNotaDiferenteCNPJ: this.possuiNotaDiferenteCNPJ,
          docs: this.docs
        });
      }

      console.timeEnd();
    }, erro => {
      debugger;
      this.uploading = false;
      this.uploaded = false;
      this.finish.emit({success: false});
    });
  }
  itemValido(item) {
    if (item["CEP DESTINO"] && item["CEP ORIGEM"] && item["VALOR TOTAL DA NFE"] && item["VOLUMES"]) {
      return true;
    }
    return false;
  }
  volumeValido(item) {
    // possuiVolumeIcorreto
    var altura = item["ALTURA (CM)"];
    var largura = item["LARGURA (CM)"];
    var comprimento = item["COMPRIMENTO (CM)"];
    var peso = item["PESO (KG)"];
    var max = 80;
    if ( altura <= max && largura <= max && comprimento <= max && peso <= 8000 ) {
      return true;
    }
    return false;
  }
  alterarArquivo() {
    this.uploaded = false;
    this.uploading = false;
    this.docs = [];

    if (!this.isModal) {
      this.localStorage.limparCotacao();
    }
    this.files = new Set();
    this.possuiDuplicadas = false;
    this.possuiNotaDiferenteCNPJ = false;
    this.possuiVolumeIcorreto = false;
  }
  tipoProgressBar(progresso, duplicada, isMesmoCNPJ) {

    if (duplicada == true) {
      return 'danger'
    }
    if (isMesmoCNPJ == false) {
      return 'danger'
    }
    if (progresso == 100 && isMesmoCNPJ == true) {
      return 'success'
    }
    return 'warning';
  }
  acessarModoManual() {
    this.router.navigateByUrl('simulacao/preenchimento-manual');
  }
  removerItemCotacao(progressItem, arquivo) {

    // removerVolume(mercadoria, volume) {
    //   let indice = mercadoria.volumes.map(function(item) {return item.id;}).indexOf(volume.id);
    //   mercadoria.volumes.splice(indice, 1);
    // }

    this.possuiDuplicadas = false;
    this.possuiNotaDiferenteCNPJ = false;

    for (const key in this.progress) {
      if (key == arquivo.name) {
        delete this.progress[key]
      }
    }

    for (const key in this.progress) {
      if (!this.progress[key].isMesmoCNPJ) {
        this.possuiNotaDiferenteCNPJ = true;
      }
      if (this.progress[key].duplicada) {
        this.possuiDuplicadas = true;
      }
    }
    var indiceDocs = this.docs.map(function(item) { return item._id}).indexOf(progressItem._id);
    this.docs.splice(indiceDocs, 1);
    this.files.delete(arquivo);


    if (this.files.size == 0) {
      this.uploaded = false;
      this.uploading = false;
      this.docs = [];
      this.files = new Set();
      this.possuiDuplicadas = false;
      this.possuiNotaDiferenteCNPJ = false;
      // this.alterarArquivo();
    }
    if (!this.possuiNotaDiferenteCNPJ && !this.possuiDuplicadas) {
      this.finish.emit({
        success: true,
        docs: this.docs
      });
    }
  }
}
