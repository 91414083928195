import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-validar-acesso',
  templateUrl: './validar-acesso.component.html',
  styleUrls: ['./validar-acesso.component.scss']
})
export class ValidarAcessoComponent implements OnInit {

  @ViewChildren('input') inputs: QueryList<ElementRef>;
  public listInputs = [0, 0, 0, 0, 0, 0];
  public usuario: any = {};
  public initialPhone;
  public lastPhone;
  public codeValidate = "";
  public validado = false;
  public codigoReenviado = false;
  public isLoading = true;
  public isCodigoInvalido = false;
  public codigo_informado = "";

  private eventosPopstateSubject: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private localStorage: LocalStorageService
  ) { }

  ngOnInit(): void {
    history.pushState(null, '');
    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.eventosPopstateSubject))
      .subscribe(() => history.pushState(null, ''));

    this.isLoading = false;
  }

  keyUp(event, input) {
    if (input.nextElementSibling != null) {
      input.nextSibling.focus();
    }

    this.validarCodigoDigitado();
  }

  validarCodigo() {
    this.usuarioService.validarCodigoEngloba(this.codigo_informado)
      .subscribe((res) => {
        if (res === true) {
           this.router.navigate(['/simulacao/dados-destinatarios']);
        } else {
          this.isCodigoInvalido = true;
        }
      });
  }


  // simularColeta() {
  //   this.isLoading = true;
  //   let texto = '';

  //   this.inputs.map( i => {
  //     texto = texto + i.nativeElement.value;
  //   });
  //   this.codigo_informado = texto;

  //   this.usuarioService.validarCodigo(this.usuario.email, this.codigo_informado).subscribe(async (res) => {
  //     var retorno = res;
  //     var senha = await this.usuarioService.getSenha(this.usuario.senha, this.usuario.id).toPromise();

  //     this.validarParaContinuar(this.usuario.id, this.codigo_informado);


  //   }, erro => {
  //     this.isCodigoInvalido = true;
  //     this.isLoading = false;
  //     this.validado = false;
  //     this.limparCampos();
  //   });


  //   /** CAMINHO FELIZ **/
  //   // this.usuarioService.validar(this.usuario.id).subscribe(res => {
  //   //   this.isLoading = false;
  //   //   if (res.ativo) {
  //   //     this.localStorage.setarUsuario(res);
  //   //     this.router.navigateByUrl('/simulacao/dados-coleta');
  //   //   }

  //   // }, error => this.isLoading = false);
  // }
  // validarParaContinuar(id, codigo) {
  //    /** CAMINHO FELIZ **/
  //    this.usuarioService.validar(id, codigo).subscribe(res => {
  //     this.isLoading = false;
  //     if (res.ativo) {
  //       let usuarioAtual = this.localStorage.obterUsuario();
  //       let novoUsuario = {...usuarioAtual, ...res};
  //       this.isLoading = false;
  //       this.validado = false;

  //       this.localStorage.setarUsuario(novoUsuario);
  //       // if (novoUsuario.tipoEmpresa == 'MEI') {
  //       //   this.router.navigateByUrl('/simulacao/documento-fiscal');
  //       // } else {
  //       //   this.router.navigateByUrl('/simulacao/pagamento');
  //       // }
  //     }
  //   }, error => {
  //     this.isLoading = false;
  //     this.validado = false;
  //   });
  // }
  // iniciaVerificacao(){

  //   this.isLoading = true;
  //   this.usuarioService.verifyAccount(this.usuario.email, this.usuario.celular, this.usuario.nome, this.usuario.cnpj).subscribe((res) => {
  //     // Enviou codigo
  //     this.isLoading = false;
  //   }, erro => {
  //     debugger;
  //     var str = erro.error;
  //     var jaCadastrado = /(E-mail infomado já foi cadastrado)/g.test(str);
  //     if (jaCadastrado) {
  //       this.validarParaContinuar(this.usuario.id, "0");
  //     }
  //     this.isLoading = false;
  //   });
  // }
  async validarCodigoDigitado() {
    let texto = '';

    await this.inputs.map( i => {
      texto = texto + i.nativeElement.value;
    });

    if (texto.length == 6) {
      this.validado = true;
      this.codigo_informado = texto;
    } else {
      this.validado = false;
    }
  }
  // reenviarCodigo() {
  //   this.limparCampos();
  //   this.codigoReenviado = true;
  //   this.iniciaVerificacao();
  // }
  limparCampos() {
    this.inputs.map(i => {
      i.nativeElement.value = null;
    });
  }
}
