<div class="container" *ngIf="preenchimentoObrigatorio === undefined">
    <div class="text-center py-5">
        <p class="titulo-paginas">Aguarde...</p>
    </div>
</div>

<div class="container" *ngIf="preenchimentoObrigatorio === true">
    <div class="text-center py-5">
        <p class="titulo-paginas">Alguns dados da sua coleta</p>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-sm-7">
            <div class="pb-5">
                <form [formGroup]="dadosColetaForm" (onSubmit)="onSubmit(dadosColetaForm.value)">

                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="cnpj">CNPJ *</label>
                                <input type="text" class="form-control" id="cnpj" mask="00.000.000/0000-99"
                                    placeholder="--.---.---/----_--" formControlName="cnpj" required
                                    [ngClass]="{ 'is-invalid': dadosColetaForm.controls.cnpj.errors && (dadosColetaForm.controls.cnpj.dirty || dadosColetaForm.controls.cnpj.touched) }">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="inscricaoEstadual">Inscrição estadual *</label>
                                <input type="text" class="form-control" id="inscricaoEstadual"
                                    placeholder="Inscrição Estadual" formControlName="inscricaoEstadual" required
                                    [ngClass]="{ 'is-invalid': dadosColetaForm.controls.inscricaoEstadual.errors && (dadosColetaForm.controls.inscricaoEstadual.dirty || dadosColetaForm.controls.inscricaoEstadual.touched) }">
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="razaoSocial">Razão social *</label>
                        <input type="text" class="form-control" id="razaoSocial" placeholder="Razão Social"
                            formControlName="razaoSocial" required
                            [ngClass]="{ 'is-invalid': dadosColetaForm.controls.razaoSocial.errors && (dadosColetaForm.controls.razaoSocial.dirty || dadosColetaForm.controls.razaoSocial.touched) }">
                    </div>

                    <div class="d-flex">

                        <div>
                            <div class="mr-5">
                                <div>
                                    <label>Sua empresa é MEI?</label>
                                </div>
                                <div class="d-flex mt-2">
                                    <div class="custom-control custom-radio mr-3">
                                        <input type="radio" class="custom-control-input" id="sim" name="tipoEmpresa"
                                            formControlName="tipoEmpresa" [value]="'MEI'">
                                        <label class="custom-control-label" for="sim">Sim</label>
                                    </div>

                                    <div class="custom-control custom-radio">
                                        <input type="radio" class="custom-control-input" id="nao" name="tipoEmpresa"
                                            formControlName="tipoEmpresa" [value]="'Outra'">
                                        <label class="custom-control-label" for="nao">Não</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="dadosColetaForm.value.tipoEmpresa == 'MEI'">
                            <label for="razaoSocial">CNAE *</label>
                            <input type="text" class="form-control" id="razaoSocial" placeholder="0000-0/00"
                                formControlName="cnae" mask="0000-0/00"
                                [required]="dadosColetaForm.value.tipoEmpresa == 'MEI'"
                                [ngClass]="{ 'is-invalid': dadosColetaForm.controls.cnae.errors && (dadosColetaForm.controls.cnae.dirty || dadosColetaForm.controls.cnae.touched) }">
                        </div>



                    </div>

                    <div class="mt-4">
                        <label>Endereço</label>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-3">
                            <div class="form-group">
                                <label for="cep">CEP *</label>
                                <input type="text" class="form-control" id="cep" mask="00000-000" placeholder="CEP"
                                    formControlName="cep" required (blur)="focusOutCep($event)"
                                    [ngClass]="{ 'is-invalid': dadosColetaForm.controls.cep.errors && (dadosColetaForm.controls.cep.dirty || dadosColetaForm.controls.cep.touched) }">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="endereco">Endereço *</label>
                                <input type="text" class="form-control" id="endereco" placeholder="Av, Rua, Viela, etc"
                                    formControlName="endereco" required [disabled]="loadingCep"
                                    [ngClass]="{ 'is-invalid': dadosColetaForm.controls.endereco.errors && (dadosColetaForm.controls.endereco.dirty || dadosColetaForm.controls.endereco.touched) }">
                            </div>
                        </div>
                        <div class="col-12 col-sm-2">
                            <div class="form-group">
                                <label for="numero">Número *</label>
                                <input type="text" class="form-control" id="numero" placeholder="Nº"
                                    formControlName="numero" required
                                    [ngClass]="{ 'is-invalid': dadosColetaForm.controls.numero.errors && (dadosColetaForm.controls.numero.dirty || dadosColetaForm.controls.numero.touched) }">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="complemento">Complemento</label>
                                <input type="text" class="form-control" id="complemento"
                                    placeholder="Ex: Casa B, Apt 28" formControlName="complemento">
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="bairro">Bairro *</label>
                                <input type="text" class="form-control" id="bairro" placeholder="Bairro"
                                    formControlName="bairro" required
                                    [ngClass]="{ 'is-invalid': dadosColetaForm.controls.bairro.errors && (dadosColetaForm.controls.bairro.dirty || dadosColetaForm.controls.bairro.touched) }">
                            </div>
                        </div>

                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="cidade">Cidade *</label>
                                <input type="text" class="form-control" id="cidade" placeholder="Cidade"
                                    formControlName="cidade" required
                                    [ngClass]="{ 'is-invalid': dadosColetaForm.controls.cidade.errors && (dadosColetaForm.controls.cidade.dirty || dadosColetaForm.controls.cidade.touched) }">
                            </div>
                        </div>
                        <div class="col-12 col-sm-2">
                            <div class="form-group">
                                <label for="estado">Estado *</label>
                                <select id="estado" class="form-control" name="estado" formControlName="estado" required
                                    [ngClass]="{ 'is-invalid': dadosColetaForm.controls.estado.errors && (dadosColetaForm.controls.estado.dirty || dadosColetaForm.controls.estado.touched) }">
                                    <option value="AC">AC</option>
                                    <option value="AL">AL</option>
                                    <option value="AP">AP</option>
                                    <option value="AM">AM</option>
                                    <option value="BA">BA</option>
                                    <option value="CE">CE</option>
                                    <option value="DF">DF</option>
                                    <option value="ES">ES</option>
                                    <option value="GO">GO</option>
                                    <option value="MA">MA</option>
                                    <option value="MT">MT</option>
                                    <option value="MS">MS</option>
                                    <option value="MG">MG</option>
                                    <option value="PA">PA</option>
                                    <option value="PB">PB</option>
                                    <option value="PR">PR</option>
                                    <option value="PE">PE</option>
                                    <option value="PI">PI</option>
                                    <option value="RJ">RJ</option>
                                    <option value="RN">RN</option>
                                    <option value="RS">RS</option>
                                    <option value="RO">RO</option>
                                    <option value="RR">RR</option>
                                    <option value="SC">SC</option>
                                    <option value="SP">SP</option>
                                    <option value="SE">SE</option>
                                    <option value="TO">TO</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row my-3" *ngIf="false">
                        <div class="col-12 col-sm-6">
                            <label>Este também é o endereço da coleta?</label>
                        </div>
                        <div class="col-12 col-sm-6 row ml-0">
                            <div class="custom-control custom-radio mr-3">
                                <input type="radio" class="custom-control-input" id="sim" name="mesmoEnderecoColeta"
                                    [value]="'sim'" formControlName="mesmoEnderecoColeta">
                                <label class="custom-control-label" for="sim">Sim</label>
                            </div>

                            <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="nao" name="mesmoEnderecoColeta"
                                    [value]="'nao'" formControlName="mesmoEnderecoColeta">
                                <label class="custom-control-label" for="nao">Não</label>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="dadosColetaForm.value.mesmoEnderecoColeta == 'nao'">
                        <div class="mt-4">
                            <label>Endereço da coleta</label>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-3">
                                <div class="form-group">
                                    <label for="cepColeta">CEP *</label>
                                    <input type="text" class="form-control" id="cepColeta" mask="00000-000"
                                        placeholder="CEP" formControlName="cepColeta"
                                        (blur)="focusOutCepColeta($event)">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <label for="enderecoColeta">Endereço</label>
                                    <input [attr.disabled]="desabilitaCamposEnderecoColeta()" type="text"
                                        class="form-control" id="enderecoColeta" placeholder="Endereço"
                                        formControlName="enderecoColeta" required
                                        [ngClass]="{ 'is-invalid': dadosColetaForm.controls.enderecoColeta.errors && (dadosColetaForm.controls.enderecoColeta.dirty || dadosColetaForm.controls.enderecoColeta.touched) }">
                                </div>
                            </div>
                            <div class="col-12 col-sm-2">
                                <div class="form-group">
                                    <label for="numeroColeta">Número</label>
                                    <input [attr.disabled]="desabilitaCamposEnderecoColeta()" type="text"
                                        class="form-control" id="numeroColeta" placeholder="Nº"
                                        formControlName="numeroColeta" required
                                        [ngClass]="{ 'is-invalid': dadosColetaForm.controls.numeroColeta.errors && (dadosColetaForm.controls.numeroColeta.dirty || dadosColetaForm.controls.numeroColeta.touched) }">
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="form-group">
                                    <label for="complementoColeta">Complemento</label>
                                    <input [attr.disabled]="desabilitaCamposEnderecoColeta()" type="text"
                                        class="form-control" id="complementoColeta" placeholder="Ex: Casa B, Apt 28"
                                        formControlName="complementoColeta">
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <label for="bairroColeta">Bairro</label>
                                    <input [attr.disabled]="desabilitaCamposEnderecoColeta()" type="text"
                                        class="form-control" id="bairroColeta" placeholder="Bairro"
                                        formControlName="bairroColeta" required
                                        [ngClass]="{ 'is-invalid': dadosColetaForm.controls.bairroColeta.errors && (dadosColetaForm.controls.bairroColeta.dirty || dadosColetaForm.controls.bairroColeta.touched) }">
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="form-group">
                                    <label for="cidadeColeta">Cidade</label>
                                    <input [attr.disabled]="desabilitaCamposEnderecoColeta()" type="text"
                                        class="form-control" id="cidadeColeta" placeholder="Cidade"
                                        formControlName="cidadeColeta" required
                                        [ngClass]="{ 'is-invalid': dadosColetaForm.controls.cidadeColeta.errors && (dadosColetaForm.controls.cidadeColeta.dirty || dadosColetaForm.controls.cidadeColeta.touched) }">
                                </div>
                            </div>
                            <div class="col-12 col-sm-2">
                                <div class="form-group">
                                    <label for="estadoColeta">Estado</label>
                                    <select [attr.disabled]="desabilitaCamposEnderecoColeta()" id="estadoColeta"
                                        class="form-control" name="estadoColeta" formControlName="estadoColeta" required
                                        [ngClass]="{ 'is-invalid': dadosColetaForm.controls.estadoColeta.errors && (dadosColetaForm.controls.estadoColeta.dirty || dadosColetaForm.controls.estadoColeta.touched) }">
                                        <option value="AC">AC</option>
                                        <option value="AL">AL</option>
                                        <option value="AP">AP</option>
                                        <option value="AM">AM</option>
                                        <option value="BA">BA</option>
                                        <option value="CE">CE</option>
                                        <option value="DF">DF</option>
                                        <option value="ES">ES</option>
                                        <option value="GO">GO</option>
                                        <option value="MA">MA</option>
                                        <option value="MT">MT</option>
                                        <option value="MS">MS</option>
                                        <option value="MG">MG</option>
                                        <option value="PA">PA</option>
                                        <option value="PB">PB</option>
                                        <option value="PR">PR</option>
                                        <option value="PE">PE</option>
                                        <option value="PI">PI</option>
                                        <option value="RJ">RJ</option>
                                        <option value="RN">RN</option>
                                        <option value="RS">RS</option>
                                        <option value="RO">RO</option>
                                        <option value="RR">RR</option>
                                        <option value="SC">SC</option>
                                        <option value="SP">SP</option>
                                        <option value="SE">SE</option>
                                        <option value="TO">TO</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </form>

            </div>
        </div>
    </div>
</div>

<div class="action-bottom-fixed d-flex">
    <div class="container d-flex">
        <!-- <button type="button" class="btn btn-link-primary">CANCELAR</button> -->
        <span class="flex-fill"></span>
        <!-- <button class="btn btn-primary" (click)="onSubmit(dadosColetaForm.value);" [disabled]="!dadosColetaForm.valid">PRÓXIMO</button> -->
        <button id="IDCNPJ" class="btn btn-primary" [disabled]="!dadosColetaForm.valid || isLoading"
            (click)="proximo(dadosColetaForm.value)">Enviar</button>
    </div>
</div>