<div class="container">
    <div class="text-center py-5">
        <p class="titulo-paginas">Como você deseja simular sua coleta?</p>
    </div>

    <div class="row">
        <div class="col-12 col-sm-9">
            <div class="escolher" [ngClass]="{'selected': isUpload}" (click)="isUpload = true;">
                <div class="text-center">
                    <img src="assets/images/nuvem.svg" />
                    <div class="mt-3">
                        <p class="texto-escolher">Simule seus fretes importando um ou mais arquivos XML, CTE, NFe ou XLSX</p>
                    </div>
                </div>

            </div>
            <div class="text-center py-4">
                <p>Não possui um arquivo para importação? <a href="javascript:void(0);">Baixe uma amostra de planilha.</a></p>
            </div>

        </div>
        <div class="col-12 col-sm-3">
            <div class="escolher" [ngClass]="{'selected': !isUpload}" (click)="isUpload = false;">
                <div class="text-center">
                    <img src="assets/images/i-add.svg" />
                    <div class="mt-3">
                        <p>Simular informando os dados manualmente</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<div class="action-bottom-fixed d-flex">
    <button type="button" class="btn btn-outline-primary" (click)="voltar()">VOLTAR</button>
    <button type="button" class="btn btn-link-primary">CANCELAR</button>
    <span class="flex-fill"></span>
    <button class="btn btn-primary" (click)="gerar()">GERAR COTAÇÃO</button>
</div>