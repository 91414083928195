import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LocalStorageService } from './../localStorage/localstorage.service';

import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  private environment = environment;

  constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService
  ) {}

  public obterHeaderAuthorization(isMultipart = false): HttpHeaders {
    const tokenBearer = this.localStorageService.obterToken();
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `bearer ${tokenBearer}`);

    if (!isMultipart) {
      headers = headers.append('Content-Type', 'application/json');
    }

    return headers ;
  }

  public obterCnpjsPotSpeed() : Observable<any> {
    let url = `${this.environment.API_AWS}/api/Util/getPotCnpj`;

    return this.http.get(url, { headers: this.obterHeaderAuthorization() });
  }
}
