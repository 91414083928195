import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorage/localstorage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public navbarOpen = false;
  public isLogado = false;
  constructor(
    public router: Router,
    private localStorage: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.isLogado = this.localStorage.obterUsuario() ? true : false;
  }
  login() {
    this.router.navigateByUrl('/cadastro/login');
  }
  simularColeta() {

    var estaLogado = this.localStorage.obterUsuario() ? true : false;
    if (estaLogado) {
      this.router.navigateByUrl('/simulacao/file');
    } else {
      this.router.navigateByUrl('/simulacao/preenchimento-manual');
    }
  }

}
