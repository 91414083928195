<footer class="footer d-flex align-items-center">
    <div class="container-fluid footer-mobile">
        <div class="container">
            <div>
                <div class="d-flex align-items-center justify-content-center ">
                        <a href="/" class="logo">
                            <img
                                src="/assets/images/chegalog.svg"
                                width="96"
                                height="35"
                            >
                        </a>
                        <p class="mx-4 mt-2 text-center">um serviço</p>
                        <a href="/" class="logo">
                            <img
                                src="/assets/images/LOGOTIPO-POT-SPEED-1@2x.png"
                                width="96"
                                height="35"
                            >
                        </a>
                </div>
                <div class="text-center ">

                    <small class="m-0">
                        &#169; 2020 Pot Speed. Desenvolvido por
                        <a
                            href="http://www.bweb.com.br" target="_blank" class="pr-4">
                            Bweb
                        </a>
                        <a
                            href="javascript:void(0);"
                            (click)="openModal(template)">
                            Termo de uso
                        </a>  |
                        <a
                            href="javascript:void(0);"
                            (click)="contato()"
                            fragment="contato">
                            Fale conosco
                        </a>  |
                        <a
                            href="https://www.potspeed.com.br" target="_blank">
                            Acessar Pot Speed
                        </a>
                    </small>
                </div>
            </div>
        </div>
    </div>
</footer>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">ChegaLog – Termos de uso</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-termo></app-termo>
    </div>
</ng-template>