<div class="container">
    <div class="text-center py-sm-5 margin-p-mobile">
        <p class="titulo-paginas p-texto">Olá, seja bem-vindo ao painel de cotação e contratação de frete do ChegaLog. </p>
        <span class='lead'>A partir de agora você poderá simular sua cotação de frete.<br /> Escolha abaixo a melhor opção para você.</span>
    </div>
    <div class="row">
        <div class="col">
            <app-drag-area (finish)="terminouUpload($event)"></app-drag-area>
            <div class="row container-mobile" *ngIf="!uploaded">
                <div class="col" [ngClass]="{'col-6 mx-auto': !usuario?.ativo}">
                    <div class="card" (click)="acessarModoManual()">
                        <div class=" d-flex align-items-center p-3">
                            <div class="mr-3">
                                <img src="assets/images/icone-file.png" width="36"/>
                            </div>
                            <span class="w-100">Preencher manualmente a cotação</span>
                            <i class="material-icons text-muted" tooltip="Ao clicar nesse botão você será direcionado para um formulário padrão de cotação de frete, porem com algumas facilidades para garantir velocidade no seu trabalho..">help</i>
                        </div>
                    </div>
                </div>
                <div class="col bloco-mobile" *ngIf="usuario.ativo">
                    <a href="https://storage.googleapis.com/chegalog/outros/modelo_upload_envios.xls" class="no-link">
                        <div class="card">
                            <div class=" d-flex align-items-center p-3">
                                <div class="mr-3">
                                    <img src="assets/images/icone-xls.png" width="36"/>
                                </div>
                                <span class="w-100">Baixar modelo de planilha XLSX</span>
                                <i class="material-icons text-muted" tooltip="Caso você tenha diversos pedidos para diversos locais e quer ganhar tempo com a cotação, você poderá preencher e salvar os dados nessa planilha modelo e fazer o upload. O sistema irá fazer o calculo de cada um dos destinos dos seus pedidos.">help</i>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4 text-center">
        <h5 class="p-3 bg-primary text-white">CONTRATE O FRETE E PAGUE EM ATÉ 3X SEM JUROS!</h5>
    </div>
</div>
<div class="action-bottom-fixed d-flex">
    <div class="container d-flex footer-mobile">
        <button type="button" class="btn btn-outline-primary btn-voltar" (click)="voltar()">VOLTAR</button>
        <span class="flex-fill"></span>
        <span>
            <button
                [tooltip]="possuiNotaDiferenteCNPJ ? 'Você enviou notas que não são suas, por favor envie notas que você é o emissor' : ''"
                class="btn btn-primary"
                (click)="gerarCotacao()"
                [disabled]="!uploaded || possuiNotaDiferenteCNPJ || possuiDuplicadas" triggers="pointerenter:pointerout">
                GERAR COTAÇÃO
            </button>
        </span>
    </div>
</div>