import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorage/localstorage.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss']
})
export class UserDropdownComponent implements OnInit {
  public user;
  public isLogado = false;
  @Input('absolute') absolute = false;
  private homeComponent = HomeComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService,
    private usuarioService: UsuarioService
  ) {

  }

  ngOnInit(): void {
    this.user = this.localStorage.obterUsuario();
    this.isLogado = this.user ? true : false;

    // this.router.events.subscribe((val) => {
    //   if (val instanceof ActivationEnd) {
    //       console.log('componentName', val.snapshot.component['name']);
    //   }
    // });
  }
  sair() {
    var currentComponent = this.route.component;
    this.localStorage.deslogar();
    this.router.navigateByUrl('/', {skipLocationChange: true});

    if (currentComponent == this.homeComponent) {
      window.location.reload();
    }
  }
}
