import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-arquivo',
  templateUrl: './upload-arquivo.component.html',
  styleUrls: ['./upload-arquivo.component.scss']
})
export class UploadArquivoComponent implements OnInit {

  isUpload = true;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  gerar() {
    if (this.isUpload) {
      this.router.navigateByUrl('/simulacao/file');
    } else {
      this.router.navigateByUrl('/simulacao/embalagem');
    }
  }
  voltar() {
    this.router.navigateByUrl('/simulacao/dados-coleta');
  }

}
