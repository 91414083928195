<div class="container">
  <div class="text-center py-5">
    <p class="titulo-paginas" *ngIf="this.dadosCotacoes.length === 1">Alguns dados do Destinatário</p>
    <p class="titulo-paginas" *ngIf="this.dadosCotacoes.length > 1">Alguns dados dos Destinatários</p>
  </div>

  <div class="row justify-content-center">
    <div class="col-12 col-sm-7">
      <div class="pb-5">
        <form [formGroup]="dadosDestinatarioForm">
          <div formArrayName="destinatarioItems">
            <div *ngFor="let item of dadosDestinatarioForm.get('destinatarioItems')['controls']; let i = index;">
              <div class="card mb-5">
                <div class="card-body">
                  <h5 class="card-title">{{ i + 1 }} - ID: {{ this.dadosCotacoes[i]._id }} - {{
                    this.dadosCotacoes[i].quantidade }} x {{ this.dadosCotacoes[i].mercadoria.tipo }}</h5>

                  <div [formGroupName]="i">
                    <input type="text" id="cotacaoId" formControlName="cotacaoId" hidden>

                    <div class="row">
                      <div class="col-12 col-sm-4">
                        <div class="form-group">
                          <label for="cpfCnpj">CPF/CNPJ *</label>
                          <input type="text" class="form-control" id="cpfCnpj" mask="CPF_CNPJ" formControlName="cpfCnpj"
                            required
                            [ngClass]="{ 'is-invalid': item.controls.cpfCnpj.errors && (item.controls.cpfCnpj.dirty || item.controls.cpfCnpj.touched) }">
                        </div>
                      </div>

                      <div class="col-12 col-sm-8">
                        <div class="form-group">
                          <label for="nome">Nome *</label>
                          <input type="text" class="form-control" id="nome" placeholder="João da Silva"
                            formControlName="nome" required
                            [ngClass]="{ 'is-invalid': item.controls.nome.errors && (item.controls.nome.dirty || item.controls.nome.touched) }">
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-3">
                        <div class="form-group">
                          <label for="cep">CEP *</label>
                          <input type="text" class="form-control" id="cep" mask="00000-000" placeholder="CEP"
                            formControlName="cep" readonly
                            [ngClass]="{ 'is-invalid': item.controls.cep.errors && (item.controls.cep.dirty || item.controls.cep.touched) }">
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="logradouro">Endereço *</label>
                          <input type="text" class="form-control" id="logradouro" placeholder="Av, Rua, Viela, etc"
                            formControlName="logradouro"
                            [ngClass]="{ 'is-invalid': item.controls.logradouro.errors && (item.controls.logradouro.dirty || item.controls.logradouro.touched) }">
                        </div>
                      </div>

                      <div class="col-12 col-sm-2">
                        <div class="form-group">
                          <label for="numero">Número *</label>
                          <input type="text" class="form-control" id="numero" placeholder="Nº" formControlName="numero"
                            required
                            [ngClass]="{ 'is-invalid': item.controls.numero.errors && (item.controls.numero.dirty || item.controls.numero.touched) }">
                        </div>
                      </div>

                      <div class="col-12 col-sm-4">
                        <div class="form-group">
                          <label for="complemento">Complemento</label>
                          <input type="text" class="form-control" id="complemento" placeholder="Ex: Casa B, Apt 28"
                            formControlName="complemento">
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="bairro">Bairro *</label>
                          <input type="text" class="form-control" id="bairro" placeholder="Bairro"
                            formControlName="bairro"
                            [ngClass]="{ 'is-invalid': item.controls.bairro.errors && (item.controls.bairro.dirty || item.controls.bairro.touched) }">
                        </div>
                      </div>

                      <div class="col-12 col-sm-4">
                        <div class="form-group">
                          <label for="cidade">Cidade *</label>
                          <input type="text" class="form-control" id="cidade" placeholder="Cidade"
                            formControlName="cidade"
                            [ngClass]="{ 'is-invalid': item.controls.cidade.errors && (item.controls.cidade.dirty || item.controls.cidade.touched) }">
                        </div>
                      </div>

                      <div class="col-12 col-sm-2">
                        <div class="form-group">
                          <label for="uf">Estado *</label>
                          <select id="uf" class="form-control" name="uf" formControlName="uf"
                            [ngClass]="{ 'is-invalid': item.controls.uf.errors && (item.controls.uf.dirty || item.controls.uf.touched) }">
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="DF">DF</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MT">MT</option>
                            <option value="MS">MS</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PR">PR</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="action-bottom-fixed d-flex">
  <div class="container d-flex">
    <span class="flex-fill"></span>
    <button class="btn btn-primary" [disabled]="!dadosDestinatarioForm.valid || isLoading"
      (click)="onSubmit(dadosDestinatarioForm.value)">Enviar</button>
  </div>
</div>
