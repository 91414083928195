<div *ngIf="!exibirEtiquetas" class="container">
  <div class="pt-5"></div>
  <div class="row">
    <div>
      <p *ngIf="!exibirEtiquetas && !pedidoNaoEncontrado">
        <strong>Realizando a busca dos dados das Etiquetas. Por favor aguarde.</strong>
      </p>

      <p *ngIf="pedidoNaoEncontrado">
        <strong>Etiqueta não encontrada.</strong>
      </p>
  </div>
  </div>
</div>

<div *ngIf="exibirEtiquetas" class="container-lista-pedidos">
  <div class="container-qrcode" *ngFor="let etiqueta of etiquetas; let i = index;">
    <div class="qrcode-column">
      <ngx-qrcode
        *ngIf="etiqueta.numeroPedido"

        ([value])="etiqueta.numeroPedido"
        [width]="75"
        cssClass="aclass"
        errorCorrectionLevel="L">
      </ngx-qrcode>

      <div class="container-codigos">
          <div class="codigo">
              <img src="/assets/images/logo.png" width="150" height="50" />
              <ngx-barcode [(bc-value)]="etiqueta.chaveNFe" [bc-height]="55" [bc-width]="1.5" [bc-font-size]="16" [bc-display-value]="true"></ngx-barcode>
          </div>
          <div class="codigo">
              <img src="/assets/images/chegalog.svg" width="150" height="50" />
              <ngx-barcode [(bc-value)]="etiqueta.numeroPedido" [bc-height]="55" [bc-display-value]="true" [bc-font-size]="16"></ngx-barcode>
          </div>
      </div>
      <div class="text-right">
          <span class="texto-volume">Volume: {{ i + 1 }} / {{ etiquetas.length }}</span>
      </div>
    </div>

    <div class="qrcode-column">
      <p><strong>Destinatário</strong></p>
      <p>{{ etiqueta.destinatario?.nome }} ({{ etiqueta.destinatario?.cpfCnpj | cpfCnpj }})</p>
      <p *ngIf="etiqueta.destinatario?.telefone">Tel: {{ etiqueta.destinatario?.telefone }}</p>
      <p>{{ etiqueta.destinatario?.endereco }}, {{ etiqueta.destinatario?.numero }} - {{ etiqueta.destinatario?.bairro }}</p>
      <p>{{ etiqueta.destinatario?.complemento }}</p>
      <p>{{ etiqueta.destinatario?.cep }}  - {{ etiqueta.destinatario?.cidade }} - {{ etiqueta.destinatario?.uf }}</p>
    </div>

    <div class="qrcode-column">
      <p><strong>Remetente</strong></p>
      <p>{{ etiqueta.remetente.nome }} ({{ etiqueta.remetente?.cpfCnpj | cpfCnpj }})</p>
      <p *ngIf="etiqueta.destinatario?.telefone">Tel: {{ etiqueta.remetente.telefone }}</p>
      <p>{{ etiqueta.remetente.endereco }}, {{ etiqueta.remetente.numero }} - {{ etiqueta.remetente.bairro }}</p>
      <p>{{ etiqueta.remetente.complemento }}</p>
      <p>{{ etiqueta.remetente.cep }}  - {{ etiqueta.remetente.cidade }} - {{ etiqueta.remetente.uf }}</p>
    </div>
  </div>
</div>
