import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/app/services/localStorage/localstorage.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { forkJoin, of } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-declaracao-conteudo',
  templateUrl: './declaracao-conteudo.component.html',
  styleUrls: ['./declaracao-conteudo.component.scss']
})
export class DeclaracaoConteudoComponent implements OnInit {

  public declaracoConteudoForm;
	public declaracao;
  public declaracoConteudo: FormGroup;
  public expandidoCollapse1: boolean = true;
  public expandidoCollapse2: boolean = true;
  public expandidoCollapse3: boolean = true;
  public usuario;
  public idFrete;
  public idCliente;
  public idDestino;
  public destino;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private storage: LocalStorageService,
    private usuarioService: UsuarioService
  ) {
    var metaDeclaracao = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.metaDeclaracao : null;

    if (metaDeclaracao) {
      this.idFrete = metaDeclaracao.idFrete;
      this.idCliente = metaDeclaracao.idCliente;
      this.idDestino = metaDeclaracao.idDestino;
      this.destino = metaDeclaracao.destino;

    } else {
      this.router.navigateByUrl('/cliente/pedidos');
    }

    this.declaracoConteudoForm = this.formBuilder.group({
      nome: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      telefone: [''],
      cep: ['', Validators.required],
      endereco: ['', Validators.required],
      numero: ['', Validators.required],
      complemento: [''],
      bairro: ['', Validators.required],
      cidade: ['', Validators.required],
      estado: ['', Validators.required],
      obs: [''],
      tipoDocumentoCliente: ''
    });

    this.usuario = this.storage.obterUsuario();
  }

  ngOnInit(): void {
    this.declaracoConteudo = this.formBuilder.group({
      itemRows: this.formBuilder.array([this.initItemRows()])
    });
  }
  focusOutCep(event) {
    if (event.target.value.length > 8 ) {
      this.procurarEndereco(event.target.value);
    }
  }
  procurarEndereco(cep) {

    this.usuarioService.buscarEnderecoByCEP(cep).subscribe( res => {
      this.declaracoConteudoForm.controls['endereco'].setValue(res.street);
      this.declaracoConteudoForm.controls['bairro'].setValue(res.neighborhood);
      this.declaracoConteudoForm.controls['cidade'].setValue(res.city);
      this.declaracoConteudoForm.controls['estado'].setValue(res.state);
    }, (erro) => {

    });
  }

  get formArr() {
    return this.declaracoConteudo.get('itemRows') as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
      item: ['', Validators.required],
      valor: ['', Validators.required],
      qtd: ['',  Validators.required]
    });
  }

  addNewRow() {
    const control = <FormArray>this.declaracoConteudo.get('itemRows');
    control.push(this.initItemRows());
  }

  deleteRow(index: number) {
    const control = <FormArray>this.declaracoConteudo.get('itemRows');
    control.removeAt(index);
  }

  onSubmit(form) {

  }

  expandirCollapse1(){
    this.expandidoCollapse1 = !this.expandidoCollapse1;
  }

  expandirCollapse2(){
    this.expandidoCollapse2 = !this.expandidoCollapse2;
  }

  expandirCollapse3(){
    this.expandidoCollapse3 = !this.expandidoCollapse3;
  }

  voltar() {
    this.router.navigateByUrl('/cliente/pedidos')
  }
  salvarEGerar(){
    var itens = this.declaracoConteudo.value.itemRows;
    var dadosForm = this.declaracoConteudoForm.value;
    var itensParaEnviar = [];

    itens.map(obj => {
      itensParaEnviar.push({descricao: obj.item, valor: obj.valor, quantidade: obj.qtd});
    });
    this.usuarioService.salvarDeclaracao(this.idCliente, this.idFrete, this.idDestino, dadosForm, itensParaEnviar, this.destino.numeroPedido, this.destino).subscribe( res => {

      if (res.success) {
        window.open(`http://localhost:4200/#/declaracao-conteudo/${this.idCliente}/${this.destino.numeroPedido}`, '_blank');
        this.voltar();
      }
    });
  }
}






