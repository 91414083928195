<!-- <div class="container">
  <div class="text-center py-sm-5 margin-p-mobile">
    <p class="h5 mb-4">Detalhes do pedido</p>
  </div>

  <div class="row">
    <div class="col">
        <div class="row container-mobile">
            <div class="col" [ngClass]="{'col-6 mx-auto': !usuario?.ativo}">
                <div class="card">
                    <div class=" d-flex align-items-center p-3">
                        <div class="mr-3">
                            <img src="assets/images/icone-file.png" width="36"/>
                        </div>
                        <span class="w-100">Preencher manualmente a cotação</span>
                        <i class="material-icons text-muted" tooltip="Ao clicar nesse botão você será direcionado para um formulário padrão de cotação de frete, porem com algumas facilidades para garantir velocidade no seu trabalho..">help</i>
                    </div>
                </div>
            </div>
            <div class="col bloco-mobile" *ngIf="usuario.ativo">
                <a href="https://storage.googleapis.com/chegalog/outros/modelo_upload_envios.xls" class="no-link">
                    <div class="card">
                        <div class=" d-flex align-items-center p-3">
                            <div class="mr-3">
                                <img src="assets/images/icone-xls.png" width="36"/>
                            </div>
                            <span class="w-100">Baixar modelo de planilha XLSX</span>
                            <i class="material-icons text-muted" tooltip="Caso você tenha diversos pedidos para diversos locais e quer ganhar tempo com a cotação, você poderá preencher e salvar os dados nessa planilha modelo e fazer o upload. O sistema irá fazer o calculo de cada um dos destinos dos seus pedidos.">help</i>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
  </div>
</div> -->



<div>
  <div class="container">
    <div class="pt-5"></div>
    <p class="h5 mb-4">Detalhes do pedido</p>



      <!-- <ng-container *ngIf="ocorrencias.length == 0">
        <div class="d-flex align-items-center justify-content-center">
          <i class="material-icons mr-3 text-warning">warning</i>
          <p class="my-5 h5 text-center">Não há ocorrencias para a chave: {{ chave }}</p>
        </div>
      </ng-container> -->

    <ng-container>
      <div class="card-item-historico">
        <div class="mr-4 d-flex align-items-center">
          <span class="mr-3" style="font-size: 30px;">#</span>
          <span>Codigo: <strong>{{ codigo }}</strong></span>
        </div>

        <div class="mx-3">
          <span>Numero pedido: <strong>{{ numero }}</strong></span>
        </div>

        <div class="mx-3 d-flex align-items-center">
          <img src="assets/images/man-23.svg" width="21" height="21" class="mr-3"/>
          <span>{{ destinatario.length > 0 ? destinatario : 'AGUARDE ...'}}</span>
        </div>

        <div style="flex: 1"></div>

        <div class="d-flex align-items-center">
          <span>Total: <strong>{{ valorFrete | currency: 'BRL' }}</strong></span>
        </div>
      </div>

      <div class="row">
        <div class="col">

            <app-drag-area-cliente *ngIf="arquivoPendente" (finish)="terminouUpload($event)" [freteId]="freteId"></app-drag-area-cliente>

            <div class="row container-mobile">
                <div *ngIf="temDeclaracaoConteudo" class="col" [ngClass]="{'col-6 mx-auto': !usuario?.ativo}">
                  <a [href]="'#/declaracao-conteudo/por-frete/' + freteId" class="no-link" target="_blank">
                    <div class="card">
                        <div class=" d-flex align-items-center p-3">
                            <div class="mr-3">
                                <img src="assets/images/icone-file.png" width="36"/>
                            </div>
                            <span class="w-100">Baixar Declaração de Conteúdo</span>
                            <i class="material-icons text-muted" tooltip="Realiza a impressão da Declaração dos Conteúdos listados para fixação nos pacotes a serem enviados.">help</i>
                        </div>
                    </div>
                  </a>
                </div>
                <div class="col bloco-mobile" *ngIf="usuario.ativo && etiquetas > 0">
                    <a [href]="'#/etiqueta/' + freteId" class="no-link" target="_blank">
                        <div class="card">
                            <div class=" d-flex align-items-center p-3">
                                <div class="mr-3">
                                    <img src="assets/images/box-coleta.svg" width="36"/>
                                </div>
                                <span class="w-100">Baixar etiquetas</span>
                                <i class="material-icons text-muted" tooltip="Realiza a impressão das etiquetas para fixação nos pacotes a serem enviados.">help</i>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
      </div>

      <div class="mt-3">
        <div class="text-center mt-5" *ngIf="isLoading">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div class="tabela-rastreamento" *ngIf="!isLoading">
          <table>
            <thead>
              <tr>
                <th scope="col">Data ocorrência</th>
                <th scope="col">Data do cadastro</th>
                <th scope="col">Ocorrência</th>
                <!-- <th scope="col" class="text-right">Detalhes</th> -->
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let ocorrencia of ocorrencias">
                <tr>
                  <td>{{ ocorrencia.data_ocorrencia || ocorrencia.data_ocorrencia_dt }}</td>
                  <td>{{ ocorrencia.data_cadastro }}</td>
                  <td>{{ ocorrencia.ocorrencia }}</td>
                  <!-- <td class="text-right">
                      <a href="javascript:void(0);" (click)="openModal(template)">Ver rastreamento</a>
                  </td> -->
                </tr>
                <!-- <tr>
                    <td colspan="4"><pre>{{ ocorrencia | json }}</pre></td>
                </tr> -->
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="action-bottom-fixed d-flex">
    <div class="container d-flex">
        <button type="button" class="btn btn-outline-primary" (click)="voltar()">VOLTAR</button>
        <span class="flex-fill"></span>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Rastreamento</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-rastreamento [importado]="true" [valorPesquisa]="cpfDestinatario"></app-rastreamento>
    </div>
</ng-template>
