
<div>
    <div class="container">
        <div class="pt-5"></div>

        <ng-container *ngIf="isLoading">
            <div class="text-center my-5">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="!isLoading">
            <p class="h5 mb-4 ml-3">Pedidos {{ totalAguardandoXML.length }} </p>

            <!-- <pre>{{ fretes | json }}</pre> -->
            <div *ngFor="let frete of fretes; let i = index;" class="item-enviar-arquivo">
                <ng-container>
                    <!-- <pre>{{ frete | json }}</pre> -->
                    <div class="row">
                        <div class="col">
                            <p>
                                <strong>Fatura</strong> -
                                <span>{{ frete.fatura.idBill }}</span> -
                                {{ frete.data | date : 'short' }} no valor de
                                <span class="text-primary">
                                    <strong>{{ frete.fatura.amount | currency : 'BRL' }}</strong>
                                    &nbsp;
                                </span>
                            </p>
                        </div>
                        <div class="col text-right" *ngIf="frete.valorTotalEstornado > 0">
                            <span class="text-danger">Estornado: {{ frete.valorTotalEstornado | currency : 'BRL' }}</span>
                        </div>
                    </div>

                    <div *ngFor="let destino of frete.pedidos; let d = index">
                        <div class="mb-2">
                            <small>Endereço </small>
                            <small tooltip="Número do pedido desta fatura">{{ d + 1 }}</small>
                        </div>

                        <div
                            class="destino col"
                            [ngClass]="{'sem-pendencia': !destino.pendenteNotaFiscal, 'cancelado': destino.status == 'cancelado'}"
                            tooltip="{{ destino.status == 'cancelado' ? 'Pedido Cancelado/Estornado' : (!destino.pendenteNotaFiscal ? 'Arquivo enviado ' : '')}}">

                            <div class="row">
                                <div class="col">
                                    <small class="d-block mb-1"><strong>Número pedido / NF / DC:</strong>{{ destino.numeroPedido }}</small>
                                    <small class="d-block mb-1"> <strong>Endereço</strong></small>
                                    <small class="d-block mb-1"><strong>CEP Origem: </strong> {{ destino.cepOrigem | mask : '00000-000' }}</small>
                                    <small class="d-block mb-1"><strong>CEP destino:</strong>
                                        <a
                                            href="javascript:void(0);"
                                            class="mostrar-endereco link-cep"
                                            (click)="mostrarEnderecoCompleto(destino)"
                                            tooltip="Clique aqui parar mostrar o endereço"> {{ destino.cepDestino | mask : '00000-000'}}</a></small>
                                    <small class="d-block mb-1" *ngIf="destino.enderecoCompleto"><strong>Cidade de destino: </strong>{{ destino.enderecoCompleto }}</small>
                                    <small class="d-block mb-1"><strong>Valor Nota Fiscal: </strong> {{ destino.valorNf | currency : 'BRL' }}</small>
                                    <small class="d-block mb-1"><strong>Valor Frete: </strong> {{ (destino.valorFrete ? destino.valorFrete : 0) | currency : 'BRL' }}</small>
                                    <small class="d-block mb-1"><strong>Preenchimento: </strong>{{ destino.tipoPreenchimento ? (destino.tipoPreenchimento == 'form' ? 'Formulário':   destino.tipoPreenchimento == 'xls' ? 'Planilha': 'Nota fiscal (XML)') : '-' }}</small>
                                    <small class="d-block mt-2"> <strong>Volumes:</strong> {{ destino.volumes.length }}</small>

                                    <div *ngFor="let volume of destino.volumes">
                                        <small class="mb-0"><strong>Quantidade:</strong> {{ volume.quantidade }}</small>
                                        <small class="mb-0"><strong> Peso:</strong> {{ volume.peso }}Kg</small>
                                        <small class="mb-0"><strong> Altura:</strong> {{ volume.altura }}</small>
                                        <small class="mb-0"><strong> Largura:</strong> {{ volume.largura }}</small>
                                        <small class="mb-0"><strong> Comprimento:</strong> {{ volume.comprimento }}</small>
                                    </div>
                                </div>

                                <div class="col-4">
                                    <div class="mb-3">
                                        <input
                                            type="file"
                                            #file
                                            id="file"
                                            (change)="fileChange(frete, destino, $event)"
                                            accept=".xml"
                                            required
                                            (click)="clicouAquiInputFile($event)"/>
                                            <div *ngIf="destino.errors?.length > 0">
                                                <div *ngFor="let erro of destino.errors" class="px-2">
                                                    <div class="alert alert-danger text-center w-100">
                                                        <p
                                                            class="m-0"
                                                            style="font-size: 15px;">
                                                            {{erro}}
                                                            <a
                                                                href="javascript:void(0);"
                                                                (click)="limparErro(erro, destino.errors);">
                                                                Ok, entendi
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        <ng-container *ngIf="destino.isLoading">
                                            <div class="text-center">
                                                <div class="spinner-border text-primary" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- <pre>destino.status: {{ destino.status | json}}</pre>
                                        <pre>destino.declaracao: {{ destino.declaracao | json}}</pre>
                                        <pre>destino.pendenteNotaFiscal: {{ destino.pendenteNotaFiscal | json}}</pre> -->
                                        <ng-container
                                            *ngIf="!destino.isLoading && destino.errors?.length == 0 && destino.status != 'cancelado' && !destino.declaracao && destino.pendenteNotaFiscal">
                                            <button class="btn btn-outline-primary btn-icon px-3  btn-sm mb-2" (click)="limparErros(destino); file.click()">
                                                <i class="material-icons">attach_file</i>
                                                Enviar Nota Fiscal
                                            </button>
                                        </ng-container>

                                        <ng-container *ngIf="destino.declaracao && destino.status != 'CREATED'">
                                            <button class="btn btn-outline-primary btn-icon px-3 mb-2 btn-sm d-flex" (click)="abrirFormularioDeclaracao(frete, destino)">
                                                <i class="material-icons">description</i>
                                                Declaração de conteúdo
                                            </button>
                                        </ng-container>

                                        <!-- <ng-container *ngIf="destino.declaracao">
                                            <button class="btn btn-outline-primary btn-icon px-3 mb-2 btn-sm d-flex" [routerLink]="['/cliente/declaracao-conteudo']">
                                                <i class="material-icons">description</i>
                                                Declaração de conteúdo
                                            </button>
                                        </ng-container> -->
                                        <ng-container *ngIf="destino.status == 'CREATED' ">
                                            <button
                                                class="btn btn-outline-primary btn-icon px-3 mb-2 btn-sm"
                                                (click)="imprimirDeclaracao(destino.numeroPedido)">
                                                <i class="material-icons">print</i>
                                                Emprimir declaração
                                            </button>
                                        </ng-container>

                                        <!-- <pre>{{ destino.notaFiscal | json }}</pre> -->
                                        <ng-container>
                                            <button
                                                *ngIf="destino.notaFiscal.qtdVolumes"
                                                class="btn btn-outline-primary btn-icon px-3 mb-2 btn-sm"
                                                (click)="imprimirEtiqueta(destino.numeroPedido)">
                                                <i class="material-icons">print</i>
                                                Emprimir etiqueta
                                            </button>
                                        </ng-container>

                                        <ng-container *ngIf="destino.pendenteNotaFiscal && destino.status != 'cancelado' && prazoExpirado(frete)">
                                            <button
                                                class="btn btn-outline-danger btn-icon px-3 btn-sm d-flex"
                                                (click)="abrirModalCancelarFrete(templateConfirmarCancelamento, frete, destino)"
                                                tooltip="Após o cancelamento o estorno do frete proporcioanl a este endereço será estornado">
                                                <i class="material-icons">settings_backup_restore</i>
                                                Estornar
                                            </button>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>

            <!-- <div class="d-flex align-items-end justify-content-end my-3">
                <div class="form-group mb-0">
                    <label for="comoConheceu">Qtd por Página</label>
                    <select
                        class="form-control"
                        placeholder="Selecione"
                        [value]="quantidadePorPagina"
                        (change)="onChangeQuantidadePagina($event.target.value)">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                    </select>
                </div>

                <nav aria-label="..." class="ml-3 pt-2">
                    <ul class="pagination mb-0">
                        <li class="page-item" [ngClass]="{'disabled': skip == 0}">
                            <a class="page-link" href="javascript:void(0);" tabindex="-1" (click)="anterior()">Anterior</a>
                        </li>
                        <li
                            class="page-item active"
                            [ngClass]="{'active': pagina == skip}"
                            *ngFor="let pagina of totalPaginas; let i = index;">
                            <a class="page-link" href="javascript:void(0);" (click)="irParaPagina(pagina)">{{ pagina + 1 }}</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="javascript:void(0);" (click)="proximo()">Próximo</a>
                        </li>
                    </ul>
                </nav>
            </div> -->
        </ng-container>

    </div>

</div>


<ng-template #templateConfirmarCancelamento>
    <div class="modal-body">

        <ng-container *ngIf="!loadingCancelamento">
            <h5 class="mb-3">Cancelar pedido</h5>
            <p>Você deseja realmente fazer o cancelamento ?<br > Esta é uma ação irreversivel</p>

            <div class="row" class="m-t-sm">
                <div class="col text-right">
                    <button class="btn  px-4" (click)="fecharModal(templateConfirmarCancelamento)">
                        Cancelar
                    </button>
                    <button class="btn btn-danger px-4" (click)="confirmarCancelamento()">
                        Confirmar
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="loadingCancelamento">
            <div class="my-5 text-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>