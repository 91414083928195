import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageService } from '../../services/localStorage/localstorage.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class AtivoGuardService implements CanActivate {
  private isUsuarioAtivo: boolean = false;

  constructor(
    private localStorage: LocalStorageService,
    private router: Router
    ) {
      let user = this.localStorage.obterUsuario();
      if (user.ativo) {
        this.isUsuarioAtivo = true;
      } else {
        this.router.navigateByUrl('/simulacao/validar-acesso');
        this.isUsuarioAtivo = false;
      }
  }

  canActivate() {
    return this.isUsuarioAtivo;
  }
}
