<section class="hero">
    <app-header></app-header>
    <div class="mt-4 pt-5"></div>
</section>

<section>
    <div class="py-5 container-conteudo-chegalog">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-10 mx-auto">
                    <div class="text-center conteudo-cliente-chegalog">
                        <h2 class="my-5">Fale conosco</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- PARTE DOS PLANOS E SOLUÇÕES - FORMULÁRIO -->
<div id="contato"></div>
<section class="py-5 my-5">
    <div class="text-center">
        <h2>Quer saber mais sobre nosso planos e soluções?</h2>
        <p>Entre em contato com a nossa equipe de atendimento.</p>
        <p>
            Olá, este canal é exclusivo para contato com nossa equipe de atendimento comercial.<br/>
            O ChegaLog tem como missão atender pequenos e médios empreendedores.
        </p>
        <p></p>
    </div>
    <div class="container my-5">
        <div class="text-center" *ngIf="isLoadingContato">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div
            *ngIf="retornoContato.success"
            class="alert alert-success text-center"
            role="alert">
            {{ mensagemRetornoFormulario }}
            <div class="row align-items-center">
                <div class="col">
                    <div class="text-center">
                        <button class="btn btn-primary btn-lg cta px-5 my-3 btn-mobile" (click)="simular()">FAÇA AGORA MESMO A SUA COTAÇÃO</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            *ngIf="!retornoContato.success && mensagemRetornoFormulario.length > 0"
            class="alert alert-danger text-center"
            role="alert">
            {{ mensagemRetornoFormulario }}
        </div>

        <!-- [ngClass]="{'was-validated': (contatoForm.touched || contatoForm.dirty) && !contatoForm.valid }" -->
        <div >
            <form
                novalidate
                *ngIf="!isLoadingContato && !retornoContato.success && mensagemRetornoFormulario.length === 0"
                class="contato-container"
                [formGroup]="contatoForm"
                (ngSubmit)="onSubmit(contatoForm.value)">
                <!-- CAMPOS DO FORMULÁRIO -->
                <div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="nome">Nome *</label>
                            <input
                                type="text"
                                placeholder="Nome"
                                class="form-control ng-invalid"
                                id="nome"
                                formControlName="nome"
                                [ngClass]="{ 'is-invalid': contatoForm.controls.nome.errors && (contatoForm.controls.nome.dirty || contatoForm.controls.nome.touched) }">
                        </div>

                        <!-- <div class="form-group">
                            <label for="nomeRepresentante">Quem indicou?</label>
                            <input type="text" class="form-control" id="nomeRepresentante" placeholder="Nome" formControlName="nomeRepresentante"
                            [ngClass]="{ 'is-invalid': cadastroForm.controls.nomeRepresentante.errors && (cadastroForm.controls.nomeRepresentante.dirty || cadastroForm.controls.nomeRepresentante.touched) }">
                          </div> -->
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="email">E-mail *</label>
                            <input
                                type="email"
                                placeholder="E-mail"
                                class="form-control"
                                id="email"
                                formControlName="email"
                                autocomplete="off"
                                [ngClass]="{ 'is-invalid': contatoForm.controls.email.errors && (contatoForm.controls.email.dirty || contatoForm.controls.email.touched) }">
                            <div class="invalid-feedback">
                                Por favor preencha um e-mail válido
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="celular">Celular / WhatsApp *</label>
                            <input
                                type="tel"
                                placeholder="Celular / WhatsApp"
                                class="form-control"
                                id="celular"
                                formControlName="celular"
                                mask="(00)00000-0000"
                                autocomplete="off"
                                [ngClass]="{ 'is-invalid': contatoForm.controls.celular.errors && (contatoForm.controls.celular.dirty || contatoForm.controls.celular.touched) }">
                            <div class="invalid-feedback">
                                Por favor preencha um número de celular válido
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="mensagem">Mensagem *</label>
                            <textarea
                                class="form-control"
                                placeholder="Mensagem"
                                id="mensagem"
                                rows="5"
                                formControlName="mensagem"
                                [ngClass]="{ 'is-invalid': contatoForm.controls.mensagem.errors && (contatoForm.controls.mensagem.dirty || contatoForm.controls.mensagem.touched) }">
                            </textarea>
                        </div>
                    </div>
                </div>

                <div class="text-center py-4">
                    <button type="submit" class="btn btn-primary" [disabled]="!contatoForm.valid">ENTRAR EM CONTATO</button>
                </div>
            </form>
        </div>
    </div>
</section>


<app-footer></app-footer>