import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../services/localStorage/localstorage.service';
import { UsuarioService } from '../services/usuario/usuario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cliente-chegalog',
  templateUrl: './cliente-chegalog.component.html',
  styleUrls: ['./cliente-chegalog.component.scss']
})
export class ClienteChegalogComponent implements OnInit {
  public navbarOpen = false;
  public activeSlide;

  public slides: any =
  [
    { conteudo: 'Chega de ficar inserindo informações de peso, medidas e quantidade a cada nova cotação. Aqui tudo é muito prático e rápido.Chega de ficar inserindo informações de peso, medidas e quantidade a cada nova cotação. Aqui tudo é muito prático e rápido.',
      autor: 'Alexandre Godoy',
      cargo: 'Analisa de Gestão de Pessoas - Sicred'
    },
    { conteudo: 'Chega de ficar inserindo informações de peso, medidas e quantidade a cada nova cotação. Aqui tudo é muito prático e rápido.Chega de ficar inserindo informações de peso, medidas e quantidade a cada nova cotação. Aqui tudo é muito prático e rápido.',
      autor: 'Alexandre Godoy',
      cargo: 'Analisa de Gestão de Pessoas - Sicred'
    },
    { conteudo: 'Chega de ficar inserindo informações de peso, medidas e quantidade a cada nova cotação. Aqui tudo é muito prático e rápido.Chega de ficar inserindo informações de peso, medidas e quantidade a cada nova cotação. Aqui tudo é muito prático e rápido.',
      autor: 'Alexandre Godoy',
      cargo: 'Analisa de Gestão de Pessoas - Sicred'
    },
  ];

  constructor(
    private storage: LocalStorageService,
    private usuarioService: UsuarioService,
    private router: Router
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  simular() {
    let user = this.storage.obterUsuario();
    if (user) {
      this.router.navigateByUrl('/simulacao/file');
    } else {
      this.router.navigateByUrl('/cadastro/dados-empresa');
    }
  }

  switchSlide(i){

  }
  login() {
    this.router.navigateByUrl('/cadastro/login');
  }

}
