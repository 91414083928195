import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Cotacao, Tipo} from 'src/app/interfaces/cotacao';
import {Frete} from 'src/app/interfaces/frete';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';
import {FreteService} from './../../services/frete/frete.service';
import {UtilsService} from './../../services/utils/utils.service';

@Component({
  selector: 'app-cotacao',
  templateUrl: './cotacao.component.html',
  styleUrls: ['./cotacao.component.scss']
})
export class CotacaoComponent implements OnInit {
  public docs: any[] = [];
  public cotacoes: any[] = [];
  public origem = {
    text: '',
    estado: '',
    cidade: '',
    cep: ''
  };
  public freteMinimo = 1;
  public total = {
    quantidade: 0,
    valor: 0,
    valorTotalNfe: 0,
    valorFrete: 0
  };
  public usuario;
  public cidades: any [] = [];
  public tipoCotacao: Tipo;
  public informacoesXML: any;
  public formValido = false;
  public modalAberto = false;
  public cnpjsPotSpeed = [];
  public visualizacaoModalTransportadora = false;
  // @ViewChild('file') file: HTMLElement;
  public files: Set<File> = new Set();
  public potSpeed: any;
  public xmlsValido = false;
  public todosSelecionados: boolean;

  public selectedRowIds: Set<any> = new Set<any>();
  public selectedId: string;
  foraAbrangencia = false;
  public tipoCliente;

  @ViewChild('file', { static: false }) file;
  @ViewChild('temmplateModalAdicionar') temmplateModalAdicionar: TemplateRef<any>;
  @ViewChild('temmplateModalPagarColeta') temmplateModalPagarColeta: TemplateRef<any>;
  @ViewChild('temmplateModalPendenciaTransportadora') temmplateModalPendenciaTransportadora: TemplateRef<any>;
  @ViewChild('templateModalInformacoesXML') templateModalInformacoesXML: TemplateRef<any>;
  @ViewChild('temmplateModalForaCuritiba') temmplateModalForaCuritiba: TemplateRef<any>;

  modalRef: BsModalRef;

  public novasMercadorias: any[] = [];
  public mesmaTransportadora = false;

  public cotacoesPorUpload: boolean;

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private usuarioService: UsuarioService,
    private modalService: BsModalService,
    private freteService: FreteService,
    private utilsService: UtilsService
  ) {
    let usuario = this.localStorage.obterUsuario();
    this.usuario = usuario;
    this.tipoCliente = this.usuario.tipoCliente ? this.usuario.tipoCliente : this.localStorage.obterTipoCliente();
  }

  ngOnInit(): void {
    this.cotacoes = this.localStorage.obterCotacao() || [];
    if (this.cotacoes.length > 0 && this.cotacoes[0].idArquivo) {
      this.cotacoesPorUpload = true;
    } else {
      this.cotacoesPorUpload = false;
    }

    if (this.cotacoesPorUpload) {
      this.cotacoes.map( async (cotacao, index) => {
        cotacao.selecionado = true;
        this.selectedRowIds.add(cotacao._id);
      });

      this.verificarOrigem(this.cotacoes[0]);
      this.isAllSelected();
      this.atualizaCidades();
      this.verificaTipoCotacao();
      this.atualizarTotais();
    } else {
      this.verificarOrigem(this.cotacoes[0]);

      if (this.cotacoes.length == 0) {
        this.novaSimulacao();
      } else {
        this.cotacoes.map( async (cotacao, index) => {
          cotacao.selecionado = true;
          this.selectedRowIds.add(cotacao._id);
        });
        this.atualizaCidades();
        this.obterCnpjsPotSpeed();
        this.isAllSelected();
        this.calcularFrete();
        this.verificaTipoCotacao();
      }
    }
  }

  async verificarOrigem(cotacao) {
    var retorno = await this.usuarioService.buscarEnderecoByCEP(cotacao.cepOrigem).toPromise();
    this.origem.text = `${retorno.city}, ${retorno.state}`
    this.origem.cidade = retorno.city;
    this.origem.estado = retorno.state;
    this.origem.cep = retorno.cep;

    this.verificaAbrangencia();

  }
  verificaAbrangencia() {
    const tipoCliente = this.usuario.tipoCliente ? this.usuario.tipoCliente : this.localStorage.obterTipoCliente();
    if (tipoCliente != 'pegaki') {
      this.usuarioService.range(this.origem.cep).then( res => {
        if (res.inRange == false) {
          this.foraAbrangencia = true;
          setTimeout(() => {
            this.abrirModalAbrangencia();
          }, 0)
        }
      });
    }
  }
  abrirModalAbrangencia() {
    if (!this.modalRef) {
      this.modalRef = this.modalService.show(this.temmplateModalForaCuritiba, {class: 'w-450'});
    }
  }

  atualizaCidades() {
    this.cidades = [];

    this.getSelectedRows().map( cotacao => {
      this.cidades.push(cotacao.cidade);
    });
  }

  async validarTransportadora(){
    let cnpjs = [];
    var empresasPotSpeed = await this.utilsService.obterCnpjsPotSpeed().toPromise();

    empresasPotSpeed.map( pot => {
      if (this.usuario.endereco.estado == pot.UF) {
        cnpjs.push(pot);
      }
    });


    this.cotacoes.map( cotacao => {
      let mesmaTransportadora = this.usuarioService.verificarTransportadora(cotacao.cnpjTransportadora, cnpjs);
      if (!mesmaTransportadora) {
        cotacao.transportadoraDiferente = true;
      }
    });
  }

  async obterCnpjsPotSpeed() {
    await this.utilsService.obterCnpjsPotSpeed().subscribe( res => {
      this.cnpjsPotSpeed = res;

      res.map( pot => {
        if (pot.uf == this.origem.estado) {
          this.potSpeed = pot;
        }
      });

      if (this.potSpeed == undefined) {
        this.potSpeed = res.filter( a => { a.UF == 'PR'});
      }
    });
  }
  verificaTipoCotacao() {
    var tipos = [];

    this.cotacoes.map( cotacao => {
      if (tipos.length == 0) {
        tipos.push(cotacao.tipo);
      } else {
        tipos.map( tipo => {
          if (tipo != cotacao.tipo) {
            tipos.push(cotacao.tipo);
          }
        });
      }
    });

    switch (tipos[tipos.length - 1]) {
      case Tipo.XLS:
        this.tipoCotacao = Tipo.XLS;
        break;

      case Tipo.FORM:
        this.tipoCotacao = Tipo.FORM;
        break;

      case Tipo.XML:
        this.tipoCotacao = Tipo.XML;
        break;
    }
  }
  atualizarTotais() {
    this.total.quantidade = 0;
    this.total.valor = 0;
    this.total.valorTotalNfe = 0;
    this.total.valorFrete = 0;

    this.getSelectedRows().map( cotacao => {
      this.total.quantidade += cotacao.quantidade;
      this.total.valor += cotacao.valor;
      this.total.valorTotalNfe += cotacao.valorTotalNfe;
      this.total.valorFrete += cotacao.valorFrete.valor;
    });
  }

  calcularFrete() {
    this.cotacoes.map(async(cotacao: any) => {
      const result: any = await this.freteService.calcularFrete(
        this.usuario.idTabelaPreco === null ? 2677 : this.usuario.idTabelaPreco,
        cotacao.mercadoria.tipo,
        cotacao.mercadoria.volumes[0].altura,
        cotacao.mercadoria.volumes[0].comprimento,
        cotacao.mercadoria.volumes[0].largura,
        cotacao.quantidade,
        cotacao.CEP,
        cotacao.mercadoria.volumes[0].peso,
        cotacao.valor.toString().replace('.', ','),
        Math.round(cotacao.pesoCubico)
      );
      if (result.Message) {
        this.abrirModalAbrangencia();
        cotacao.id = result.Message;
        cotacao.prazo = 0;
        cotacao.valorFrete.valor = 0;
        cotacao.valor = 0;
        cotacao.excluido = true;
        cotacao.quemEntrega = "-";  
        cotacao.valorFrete.isLoading = false;        
        return;
      }
      /* TODO impelementar no server esse código de rro
      if (result.error) {
        cotacao.cepAtendido = false;
        if (this.selectedRowIds.has(cotacao._id)) {
          this.selectedRowIds.delete(cotacao._id);
        }
      } else {
        let frete = result.frete;
        cotacao.prazo = result.prazo + 1 + (result.prazo > 0 ? ' dias': 'dia');

        cotacao.valorFrete.valor = Number(frete.replace(".", "").replace(",", "."));
        this.total.valorFrete += Number(result.frete.replace(".", "").replace(",", "."));
      }
      */
      //TODO retirar esse trecho quando o anterior for criado
      let frete = result.frete;
      cotacao.id = result.id;
      cotacao.prazo = result.prazo + 1 + (result.prazo > 0 ? ' dias': 'dia');

      cotacao.valorFrete.valor = Number(frete.replace(".", "").replace(",", "."));
      this.total.valorFrete += Number(result.frete.replace(".", "").replace(",", "."));
      cotacao.valorFrete.isLoading = false;
    });

    var precisaValidarTransportadora = false;
    this.cotacoes.map(cotacao => {
      if (cotacao.tipo == 'xml') {
        precisaValidarTransportadora = true;
      }
    });


    if (precisaValidarTransportadora) {
      this.validarTransportadora();
    }

    this.localStorage.setarCotacao(this.cotacoes);
    this.atualizarTotais();
  }

  getQueryParams( params, url ) {

    let href = url;
    //this expression is to get the query strings
    let reg = new RegExp( '[?&]' + params + '=([^&#]*)', 'i' );
    let queryString = reg.exec(href);
    return queryString ? queryString[1] : null;
  };

  public nomeEstadoSigla = async function(sigla) {
    let response = await this.usuarioService.obterEstadoPelaSigla(sigla);
    return response;
  };


  public totalSelecionado = () => {
    let itensSelecionados = this.getSelectedRows();
    let total = 0;
    itensSelecionados.map( (x: any) => {
      total += x.valor
    });
    return total;
  };
  public totalFreteSelecionado = () => {
    let itensSelecionados = this.getSelectedRows();
    let totalFrete = 0;
    itensSelecionados.map( (x: any) => {
      totalFrete += typeof x.valorFrete.valor == "string" ? parseFloat(x.valorFrete.valor.replace(".", "").replace(",", ".")) : x.valorFrete.valor;
    });
    return totalFrete;
  };

  totalFrete() {
    let totalFrete = 0;

    this.cotacoes.map( (x: any) => {
      totalFrete += typeof x.valorFrete.valor == "string" ? parseFloat(x.valorFrete.valor.replace(".", "").replace(",", ".")) : x.valorFrete.valor;
    });

    return totalFrete;
  }

  onRowClick(item: any) {
    let id = item._id;
    if (item.cepAtendido) {
      if (this.selectedRowIds.has(id)) {
        this.selectedRowIds.delete(id);
      } else {
        this.selectedRowIds.add(id);
      }
    }
    this.atualizarTotais();
    this.atualizaCidades();
  }
  rowIsSelected(id: any) {
    return this.selectedRowIds.has(id);
  }
  changeSelectAll(event) {

    if (this.todosSelecionados) {
      for (var i = 0; i < this.cotacoes.length; i++) {
        if (!this.selectedRowIds.has(this.cotacoes[i]._id)) {

          if (this.cotacoes[i].cepAtendido) {
            this.selectedRowIds.add(this.cotacoes[i]._id);
          }
        }
      }
    } else {
      for (var i = 0; i < this.cotacoes.length; i++) {
        if (this.selectedRowIds.has(this.cotacoes[i]._id)) {
          if (this.cotacoes[i].cepAtendido) {
            this.selectedRowIds.delete(this.cotacoes[i]._id);
          }
        }
      }
    }
    this.atualizarTotais();
  }
  getSelectedRows() {
    return this.cotacoes.filter(x => this.selectedRowIds.has(x._id));
  }
  isAllSelected() {
    let itensFiltrados = this.cotacoes.filter( x => x.cepAtendido);
    this.todosSelecionados = itensFiltrados.every(( item: any) => {
      return this.selectedRowIds.has(item._id)
    });
  }

  verificarTransportadora(cnpjParaComparar) {
    var retorno = false;

    for (let pot of this.cnpjsPotSpeed) {
      if (pot.cnpj == cnpjParaComparar) {
        retorno = true;
      }
    }
    this.isAllSelected();
    return retorno;

  }

  async pagar() {
    var possuiPendenciaNota = false;

    let cotacoesSelecionadas = this.getSelectedRows();

    cotacoesSelecionadas.map( cotacao => {
      if (cotacao.pendenteNotaFiscal) {
        possuiPendenciaNota = true;
      }
      if (this.verificarTransportadora(cotacao.cnpjTransportadora)) {
        this.mesmaTransportadora = true;
      }
    });

    if (possuiPendenciaNota) {
      await this.obterCnpjsPotSpeed();

      this.modalRef = this.modalService.show(this.temmplateModalPagarColeta, {class: 'w-450', backdrop  : 'static', keyboard: false});
      this.modalAberto = true;
      this.modalService.onHidden.subscribe( result => {
        this.modalAberto = false;
      });
    }

    if (this.tipoCotacao == Tipo.XML) { //TODO: VERIFICAR ESSA REGRA
      this.continuar();
    }
  }
  mostrarModalTransportadoraDiferente() {

    this.modalRef = this.modalService.show(this.temmplateModalPagarColeta, {class: 'w-450'});
    this.visualizacaoModalTransportadora = true;
  }
  novaSimulacao() {
    this.router.navigateByUrl('/simulacao/file');
  }
  cancelarModal() {
    this.modalRef.hide();
    this.resetModalUpload();
  }
  adicionarPedidos() {
    var tipos = [];

    this.cotacoes.map( cotacao => {
      if (tipos.length == 0) {
        tipos.push(cotacao.tipo);
      } else {
        tipos.map( tipo => {
          if (tipo != cotacao.tipo) {
            tipos.push(cotacao.tipo);
          }
        });
      }
    });

    switch (tipos[tipos.length - 1]) {
      case Tipo.XLS:
        this.tipoCotacao = Tipo.XLS;
        this.modalRef = this.modalService.show(this.temmplateModalAdicionar, { class: 'w-600 center overflow', ignoreBackdropClick: true });
        break;

      case Tipo.FORM:
        this.tipoCotacao = Tipo.FORM;

        this.novasMercadorias = [{
            id: Math.floor(Math.random() * 5000) + 1000,
            cepOrigem: this.usuario.endereco ? this.usuario.endereco.cep : this.cotacoes[0].cepOrigem,
            cepDestino: '',
            tipoMercadoria: '',
            valorMercadoria: '',
            volumes: [
              { nome: 'Mercadoria', id: Math.floor(Math.random() * 5000) + 1000, quantidade: '', peso: '', altura: '', largura: '',comprimento: '' }
            ]
        }];

        this.modalRef = this.modalService.show(this.temmplateModalAdicionar, { class: 'w-750 center overflow', ignoreBackdropClick: true });
        break;

      case Tipo.XML:
        this.tipoCotacao = Tipo.XML;
        this.modalRef = this.modalService.show(this.temmplateModalAdicionar, { class: 'w-600 center overflow', ignoreBackdropClick: true });
        break;
    }
  }
  async adicionarItensCotacao() {
    let cotacoes = [];
    var chavesIguais = [];

    if (this.tipoCotacao == 'xml') {

      let cotacoesAtuais = this.localStorage.obterCotacao();
      var cotacoesUploaded = await this.usuarioService.prepararCotacao(this.docs, this.usuario);

      cotacoesAtuais.map( cotacaoAtual => {
        cotacoesUploaded.cotacoes.map( (cotacaoNova, indiceNova) => {

          if (cotacaoAtual.chave == cotacaoNova.chave) {
            chavesIguais.push(cotacaoNova.chave);
            delete cotacoesUploaded.cotacoes[indiceNova];
          }
        });
      });

      if (cotacoesAtuais.length == undefined) {
        this.localStorage.setarCotacao(cotacoesUploaded.cotacoes);
      } else {
        cotacoesUploaded.cotacoes.map( itemCotacao => {
          cotacoesAtuais.push(itemCotacao);
          this.onRowClick(itemCotacao);
        });
        this.localStorage.setarCotacao(cotacoesAtuais);
        this.cotacoes = cotacoesAtuais
      }
    }
    // SE FOR XLS CADA DOC POSSUI LINHAS
    if (this.tipoCotacao == 'xls') {

      let cotacoesAtuais = this.localStorage.obterCotacao();
      var cotacoesUploaded = await this.usuarioService.prepararCotacao(this.docs, this.usuario);

      if (cotacoesAtuais.length == undefined) {
        this.localStorage.setarCotacao(cotacoesUploaded.cotacoes);
      } else {
        cotacoesUploaded.cotacoes.map( itemCotacao => {
          cotacoesAtuais.push(itemCotacao);
          this.onRowClick(itemCotacao);
        });
        this.localStorage.setarCotacao(cotacoesAtuais);
        this.cotacoes = cotacoesAtuais;


      }
    }

    // if (this.tipoCotacao == 'xls') {
    //   cotacoes = this.localStorage.obterCotacao();
    // }

    if (this.tipoCotacao == 'form') {
      for (let mercadoria of this.novasMercadorias) {
        let endereco = await this.usuarioService.buscarEnderecoByCEP(mercadoria.cepDestino).toPromise();
        let quantidadeTotal = 0
        mercadoria.itensVolumes.map(function( volume ) {
          quantidadeTotal = quantidadeTotal + Number(volume.quantidade);
        });

        cotacoes.push(new Cotacao(<Cotacao>{
          _id: (Math.floor(Math.random() * 5000) + 1000).toString(),
          id: '-',
          chave: '-',
          quemEntrega: 'pot',
          numeroNFCTe: '-',
          quantidade: quantidadeTotal,
          estado: endereco.state,
          cidade: endereco.city,
          CEP: endereco.cep,
          prazo: '-',
          valor: mercadoria.valorMercadoria,
          valorFrete: { valor: 0, isLoading: true },
          cepAtendido: true,
          tipo: Tipo.FORM,
          pendenteNotaFiscal: true,
          mercadoria: {
            tipo: mercadoria.tipoMercadoria,
            volumes: mercadoria.itensVolumes
          }
          // volumes: mercadoria.itensVolumes
        }).cotacao);
      }
      cotacoes.map( (cotacao, index) => {
        let diferente = false;
        for(let i = 0; i < this.cotacoes.length; i++) {
          if (this.cotacoes[i]._id != cotacao._id) {
            diferente = true;
          }
        }
        if (diferente) {
          this.cotacoes.push(cotacao);
          this.onRowClick(cotacao);
        }
      });
    }
    this.atualizarTotais();
    this.localStorage.setarCotacao(this.cotacoes);
    this.modalRef.hide();
    this.calcularFrete();
    this.atualizaCidades();

    if (chavesIguais.length > 0) {
      alert('VOCÊ POSSUI ARQUIVOS IGUAIS');
    }
  }
  continuar() {
    var novoFrete;
    var frete = {
      pedidos: []
    };

    if (this.modalAberto) {
      this.modalRef.hide();
    }
    let cotacoesSelecionadas = this.getSelectedRows();

    cotacoesSelecionadas.map( (cotacao : any) => {
      let volumes = [];
      let notaFiscalTemp = cotacao.transportadoraDiferente ? {} : { idTemporario: cotacao.idArquivo };

      if (cotacao.tipo == 'xls') {
        volumes.push({
          quantidade: cotacao.quantidade,
          peso: cotacao.mercadoria.volumes[0].peso,
          altura: cotacao.mercadoria.volumes[0].altura,
          largura: cotacao.mercadoria.volumes[0].largura,
          comprimento: cotacao.mercadoria.volumes[0].comprimento,
        })
      } else {
        volumes = cotacao.mercadoria.volumes
      }
      frete.pedidos.push({
        pendenteNotaFiscal: cotacao.pendenteNotaFiscal,
        cepOrigem: cotacao.cepOrigem,
        cepDestino: cotacao.CEP,
        tipo: cotacao.mercadoria ? cotacao.mercadoria.tipo :  '',
        volumes: volumes,
        notaFiscal: notaFiscalTemp,
        valorNf: cotacao.valor,
        valorFrete: cotacao.valorFrete.valor,
        tipoPreenchimento: cotacao.tipo,
        status: 'aguardando'
      })
    });
    novoFrete = new Frete(<Frete>{frete}).frete
    this.localStorage.setarColetas(cotacoesSelecionadas);
    this.localStorage.setarFrete(novoFrete.frete);

    /*TODO: VERIFICAR SE ESTA ALTERAÇÃO É A IDEAL
    if (this.usuario.ativo) {
      this.router.navigateByUrl('/simulacao/dados-coleta');
      // this.router.navigateByUrl('/simulacao/pagamento');
      return;
    }

    // this.router.navigateByUrl('/simulacao/pagamento');

    this.router.navigateByUrl('/simulacao/dados-coleta');
    */
    this.router.navigateByUrl('/simulacao/dados-coleta');
  }

  finishDragEvent(event) {
    if (event.possuiNotaDiferenteCNPJ) {
      this.xmlsValido = false;
    } else {
      this.xmlsValido = true;
    }

    if (event.docs.length > 0) {
      this.docs = event.docs;
    }
  }
  resetModalUpload() {
    this.docs = [];
    this.xmlsValido = false;
  }
  abrirModalInformacoesXML(xml) {
    this.modalRef = this.modalService.show(this.templateModalInformacoesXML, {class: 'w-450'});
    this.informacoesXML = xml;
  }
  formularioEhValido(event) {
    this.formValido = event;
  }
  receberFormulario(event) {
    var formulario = event;
    this.novasMercadorias = formulario.value.mercadoriasItems
  }
  itemValido(item) {
    if (item["CEP DESTINO"] && item["CEP ORIGEM"] && item["VALOR TOTAL DA NFE"] && item["VOLUMES"]) {
      return true;
    }
    return false;
  }
}
