export interface Cotacao {
    _id?: string,
    id?: string,
    chave?: string,
    quemEntrega: string,
    numeroNFCTe?: string,
    quantidade: number,
    pesoCubico: number,
    estado: string,
    cidade: string,
    prazo: string,
    cepOrigem: string,
    CEP: string,
    valor: number,
    valorTotalNfe: number,
    valorFrete: {
        valor: number,
        isLoading: boolean
    },
    cepAtendido: boolean,
    progress?,
    fileName?,
    tipo: Tipo,
    pendenteNotaFiscal: boolean,
    cnpjTransportadora: string,
    enderecoCompleto?: {
        logradouro: string,
        numero: string,
        bairro: string,
        cidade: string,
        estado: string,
        cep: string
    },
    cliente?: String,
    mercadoria: {
        tipo?: string,
        volumes: any
    },
    produtosNFe?: any,
    idArquivo?: string
}
export class Cotacao {
    cotacao : Cotacao;
    constructor(cot: Cotacao) {
        this.cotacao = cot;
    }
}
export enum Tipo {
    XLS = 'xls',
    FORM = 'form',
    XML = 'xml'
}
