<div class="container">
    <div class="pt-5"></div>
    <div class="row">
        <form class="col-7 mx-auto" [formGroup]="cadastroForm" (onSubmit)="onSubmit(cadastroForm.value)">
            <div *ngIf="usuario.alteracaoCadastral?.solicitou" class="alert alert-warning">
                <div class="row">
                    <div class="d-flex align-items-center">
                        <i class="material-icons ml-3 icone">warning</i>
                    </div>
                    <div class="col">
                        <span>Aguardando atualização cadastral</span><br />
                        <span>Solicitado no dia <strong>{{ usuario.alteracaoCadastral.data | date: 'shortDate' }}</strong></span>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                </div>
                <div class="">
                </div>


            </div>

            <div>
                <p><strong>Dados de contato</strong></p>
            </div>
            <div>
                <div class="form-group">
                    <label for="nomeEmpresa">Nome fantasia da empresa *</label>
                    <input
                        type="text"
                        class="form-control"
                        id="nomeEmpresa"
                        placeholder="Nome da empresa"
                        formControlName="nomeEmpresa"
                        required
                        [ngClass]="{ 'is-invalid': cadastroForm.controls.nomeEmpresa.errors && (cadastroForm.controls.nomeEmpresa.dirty || cadastroForm.controls.nomeEmpresa.touched) }">
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                        <label for="nome">Nome Completo *</label>
                        <input type="text" class="form-control" id="nome" placeholder="Nome completo do representante da empresa" formControlName="nome" required
                        [ngClass]="{ 'is-invalid': cadastroForm.controls.nome.errors && (cadastroForm.controls.nome.dirty || cadastroForm.controls.nome.touched) }">
                        </div>
                    </div>
                </div>

                <div class="form-group">
                  <label for="comoConheceu">Como conheceu o ChegaLog? *</label>
                  <select class="form-control" id="comoConheceu" formControlName="comoConheceu"
                      placeholder="Selecione" required
                      [ngClass]="{ 'is-invalid': cadastroForm.controls.comoConheceu.errors && (cadastroForm.controls.comoConheceu.dirty || cadastroForm.controls.comoConheceu.touched) }">

                      <option value="1" disabled selected>Selecione</option>
                      <ng-container *ngFor="let comoConheceu of comoConheceuOpcoes">
                        <option [value]="comoConheceu.id">{{ comoConheceu.descricao }}</option>
                      </ng-container>
                  </select>
                </div>

                <div class="row" *ngIf="cadastroForm.value.comoConheceu === 'e9b26ff8-b1fa-11ed-b5cd-123e30198a11'">
                    <div class="col d-flex text-center justify-content-center" *ngIf="loadingSuporte">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div class="col" *ngIf="!loadingSuporte">
                        <div class="form-group">
                            <label for="nomeRepresentante">Representante</label>

                            <select class="form-control" id="nomeRepresentante" formControlName="nomeRepresentante" placeholder="Selecione" required [ngClass]="{ 'is-invalid': cadastroForm.controls.nomeRepresentante.errors && (cadastroForm.controls.nomeRepresentante.dirty || cadastroForm.controls.nomeRepresentante.touched) }">
                                <option value="1" disabled selected>Selecione</option>
                                <ng-container *ngFor="let representante of representanteOpcoes">
                                    <option [value]="representante.id">{{ representante.nome }}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="cadastroForm.value.comoConheceu === 'e9b274da-b1fa-11ed-b5d3-123e30198a11'">
                    <div class="col">
                        <div class="form-group">
                        <label for="nomeRepresentante">Quem indicou?</label>
                        <input type="text" class="form-control" id="nomeRepresentante" placeholder="Nome" formControlName="nomeRepresentante"
                        [ngClass]="{ 'is-invalid': cadastroForm.controls.nomeRepresentante.errors && (cadastroForm.controls.nomeRepresentante.dirty || cadastroForm.controls.nomeRepresentante.touched) }">
                        </div>
                    </div>
                </div>


                <div class="form-group">
                    <label for="celular">Celular *</label>
                    <input type="text" class="form-control" id="celular"  placeholder="Celular" formControlName="celular" required mask="(00) 000000009"
                    [ngClass]="{ 'is-invalid': cadastroForm.controls.celular.errors && (cadastroForm.controls.celular.dirty || cadastroForm.controls.celular.touched) }">
                </div>

                <div class="form-group">
                    <label for="email">E-mail *</label>
                    <input type="email" class="form-control" id="email"  placeholder="E-mail" formControlName="email" required
                        [ngClass]="{ 'is-invalid': cadastroForm.controls.email.errors && (cadastroForm.controls.email.dirty || cadastroForm.controls.email.touched) }">
                    <div class="invalid-feedback" *ngIf="cadastroForm.controls.email.errors && (cadastroForm.controls.email.dirty || cadastroForm.controls.email.touched)">
                        Por favor preencha um e-mail válido
                    </div>
                </div>
            </div>

            <div class="mt-5">
                <p><strong>Dados da empresa</strong></p>
            </div>
            <div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label for="cnpj">CNPJ *</label>
                            <input
                                type="text"
                                class="form-control"
                                id="cnpj"
                                placeholder="--.---.---/----_--"
                                formControlName="cnpj"
                                mask="00.000.000/0000-99"
                                [ngClass]="{ 'is-invalid': cadastroForm.controls.cnpj.errors && (cadastroForm.controls.cnpj.dirty || cadastroForm.controls.cnpj.touched) }">
                          </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="inscricaoEstadual">Inscrição estadual *</label>
                          <input type="text"  class="form-control" id="inscricaoEstadual" placeholder="Inscrição Estadual" formControlName="inscricaoEstadual" required
                          [ngClass]="{ 'is-invalid': cadastroForm.controls.inscricaoEstadual.errors && (cadastroForm.controls.inscricaoEstadual.dirty || cadastroForm.controls.inscricaoEstadual.touched) }">
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="razaoSocial">Razão social *</label>
                    <input type="text"  class="form-control" id="razaoSocial"  placeholder="Razão Social" formControlName="razaoSocial" required
                    [ngClass]="{ 'is-invalid': cadastroForm.controls.razaoSocial.errors && (cadastroForm.controls.razaoSocial.dirty || cadastroForm.controls.razaoSocial.touched) }">
                </div>

                <div class="mt-4">
                    <label>Endereço</label>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-3">
                        <div class="form-group">
                            <label for="cep">CEP *</label>
                            <input
                                type="text"
                                class="form-control"
                                id="cep"
                                mask="00000-000"
                                placeholder="CEP"
                                formControlName="cep"
                                required
                                [ngClass]="{ 'is-invalid': cadastroForm.controls.cep.errors && (cadastroForm.controls.cep.dirty || cadastroForm.controls.cep.touched) }">
                          </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="endereco">Endereço *</label>
                          <input type="text"  class="form-control" id="endereco" placeholder="Av, Rua, Viela, etc" formControlName="endereco" required
                          [ngClass]="{ 'is-invalid': cadastroForm.controls.endereco.errors && (cadastroForm.controls.endereco.dirty || cadastroForm.controls.endereco.touched) }">
                        </div>
                    </div>
                    <div class="col-12 col-sm-2">
                        <div class="form-group">
                          <label for="numero">Número *</label>
                          <input type="text"  class="form-control" id="numero" placeholder="Nº" formControlName="numero" required
                          [ngClass]="{ 'is-invalid': cadastroForm.controls.numero.errors && (cadastroForm.controls.numero.dirty || cadastroForm.controls.numero.touched) }">
                        </div>
                    </div>
                    <div class="col-12 col-sm-4">
                        <div class="form-group">
                            <label for="complemento">Complemento</label>
                            <input type="text"  class="form-control" id="complemento" placeholder="Ex: Casa B, Apt 28" formControlName="complemento">
                          </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label for="bairro">Bairro *</label>
                          <input type="text"  class="form-control" id="bairro" placeholder="Bairro" formControlName="bairro" required
                          [ngClass]="{ 'is-invalid': cadastroForm.controls.bairro.errors && (cadastroForm.controls.bairro.dirty || cadastroForm.controls.bairro.touched) }">
                        </div>
                    </div>

                    <div class="col-12 col-sm-4">
                        <div class="form-group">
                            <label for="cidade">Cidade *</label>
                            <input type="text"  class="form-control" id="cidade"  placeholder="Cidade" formControlName="cidade" required
                            [ngClass]="{ 'is-invalid': cadastroForm.controls.cidade.errors && (cadastroForm.controls.cidade.dirty || cadastroForm.controls.cidade.touched) }">
                          </div>
                    </div>
                    <div class="col-12 col-sm-2">
                        <div class="form-group">
                            <label for="estado">Estado *</label>
                            <select id="estado"  class="form-control" name="estado" formControlName="estado" required
                            [ngClass]="{ 'is-invalid': cadastroForm.controls.estado.errors && (cadastroForm.controls.estado.dirty || cadastroForm.controls.estado.touched) }">
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AP">AP</option>
                                <option value="AM">AM</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="DF">DF</option>
                                <option value="ES">ES</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MT">MT</option>
                                <option value="MS">MS</option>
                                <option value="MG">MG</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PR">PR</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RS">RS</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="SC">SC</option>
                                <option value="SP">SP</option>
                                <option value="SE">SE</option>
                                <option value="TO">TO</option>
                            </select>
                          </div>
                    </div>
                </div>

            </div>

            <div *ngIf="!isLoading">
                <button class="btn btn-primary" (click)="salvar(cadastroForm.value)">SALVAR</button>
            </div>
            <div class="text-center" *ngIf="isLoading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="mt-5 mb-0" style="margin-bottom: -70px !important;">
    <app-footer></app-footer>
</div>
