<div class="accordion" id="accordionExample">
    <div class="card">
      <div class="card-header" id="heading1" (click)="itemAccordion = 0">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
            Quando será efetuada a Coleta do meu pedido?
          </button>
        </h2>
      </div>

      <div id="collapse1" class="collapse show" aria-labelledby="heading1"  data-parent="#accordionExample"  [collapse]="itemAccordion != 0" [isAnimated]="true">
        <div class="card-body">
          <p>O ChegaLog tem sua operação suportada pela POT SPEED, que atua no mercado de coleta e entregas de encomendas a quase 10 anos.</p>
          <p>O pedido de coleta é liberado após confirmação do pagamento do frete contratado sem pendência de arquivo XML (NF-e).</p>
          <p>Se a confirmação do pagamento e envio do XML (NF-e) acontecer até às 15h do dia do pedido, a coleta será agendada em 1 dia útil após a confirmação (D+1), após às 15 horas em até 2 dias úteis após a confirmação (D+2).</p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="heading2" (click)="itemAccordion = 1">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
            Qual o prazo para que meu cliente receba a encomenda?
          </button>
        </h2>
      </div>
      <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordionExample"  [collapse]="itemAccordion != 1" [isAnimated]="true">
        <div class="card-body">
          <p>O prazo de entrega depende da principalmente da origem e destino da encomenda.</p>
          <p>Para cada cidade ou região atendida, existe uma tabela de prazos com a previsão da entrega, já considerando toda movimentação necessária para encomenda.</p>
          <p>Após ter efetivado o seu pedido de frete, você terá acesso ao painel e poderá acompanhar todos os seus pedidos.</p>

        </div>
      </div>
    </div>


    <div class="card">
      <div class="card-header" id="heading4" (click)="itemAccordion = 3">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
           A POT SPEED é uma transportadora?
          </button>
        </h2>
      </div>
      <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionExample" [collapse]="itemAccordion != 3" [isAnimated]="true">
        <div class="card-body">
          <p>Sim e especializada em entregas porta a porta.</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading5" (click)="itemAccordion = 4">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
            Qual a melhor forma para entrar em contato com o ChegaLog?
          </button>
        </h2>
      </div>
      <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionExample"  [collapse]="itemAccordion != 4" [isAnimated]="true">
        <div class="card-body">
          <p>A melhor maneira de entrar em contato com o ChegaLog, é pelo formulário de “Fale Conosco” no site da POT SPEED.</p>
          <p>Nossa equipe está sempre atenta e irá retornar o mais rápido possível.</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading6" (click)="itemAccordion = 5">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
            Qual a Área de Atuação do ChegaLog?          
          </button>
        </h2>
      </div>
      <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordionExample" [collapse]="itemAccordion != 5" [isAnimated]="true">
        <div class="card-body">
          <p>Para entregas:</p>
          <p>O ChegaLog atende os estados de Alagoas, Bahia, Ceará, Maranhão, Minas Gerais, Paraíba, Pernambuco, Piauí, Paraná, Rio Grande do norte, Rios Grande do Sul, Santa Catarina, Sergipe e São Paulo.</p>
          <p>Para Coletas:</p>
          <p>Para o serviço de COLETA, inicialmente o ChegaLog irá coletar encomendas na Capital do Paraná (Curitiba) e em Santa Catarina (Joinville).</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading7" (click)="itemAccordion = 6">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
            O ChegaLog possui algum contrato com os Correios para entregas em regiões que ela não atende?
          </button>
        </h2>
      </div>
      <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordionExample" [collapse]="itemAccordion != 6" [isAnimated]="true">
        <div class="card-body">
          <p>O ChegaLog com seus parceiros opera de forma direta nas regiões e cidades da sua abrangência. São mais de 4170 (quatro mil cento e setenta) municípios. Não utiliza dos Correios nas suas entregas.</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading8" (click)="itemAccordion = 7">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
            O ChegaLog possui seguro para entregas das encomendas?
          </button>
        </h2>
      </div>
      <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordionExample" [collapse]="itemAccordion != 7" [isAnimated]="true">
        <div class="card-body">
          <p>Sim, no valor do frete contratado já está incluso o valor do seguro e gerenciamento de risco, vide <a href="javascript:void(0);" (click)="openModal(template)">Termos de Uso</a>”.</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading9" (click)="itemAccordion = 8">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
           Eu consigo rastrear meus pedidos?
          </button>
        </h2>
      </div>
      <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordionExample" [collapse]="itemAccordion != 8" [isAnimated]="true">
        <div class="card-body">
          <p>Todos os pedidos são rastreados e você pode acompanhar o passo a passo pelo nosso painel.</p>
          <p>O seu cliente também conseguirá acompanhar o pedido pelo link “Rastrear Encomenda”, na home do nosso site, basta ele colocar o CPF ou CNPJ e nós informaremos em que local a encomenda se encontra.</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading10" (click)="itemAccordion = 9">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
           Como eu Cadastro a Minha Empresa?
          </button>
        </h2>
      </div>
      <div id="collapse10" class="collapse" aria-labelledby="heading10" data-parent="#accordionExample" [collapse]="itemAccordion != 9" [isAnimated]="true">
        <div class="card-body">
          <p>O Nosso cadastro tem duas fases, na primeira pedimos que você insira os dados de contato, para nós é muito importante ter seus dados para que possamos entrar em contato caso ocorra alguma divergência em seu pedido, já na segunda fase solicitamos que você insira informações da sua empresa, que serão os dados que usaremos para emitirmos os documentos fiscais necessários para concretização do pedido.</p>
          <p>No cadastro da empresa, solicitamos que você informe o local de coleta. Caso você tenha locais diferentes, na hora do pedido você precisará apenas informar o local desejado.</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading11" (click)="itemAccordion = 10">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
            Como eu devo enviar minhas encomendas?
          </button>
        </h2>
      </div>
      <div id="collapse11" class="collapse" aria-labelledby="heading11" data-parent="#accordionExample" [collapse]="itemAccordion != 10" [isAnimated]="true">
        <div class="card-body">
          <p>Todas as encomendas devem ter o tamanho máximo de 80cmx80cmx80cm (LxAxC) e até 8 KG.</p>
          <p>A embalagem e proteção do conteúdo é de inteira responsabilidade do cliente.</p>
          <p>Toda encomenda deverá estar acompanhada pela sua respectiva Nota Fiscal, no lado externo da embalagem (Saco Canguru).</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading12" (click)="itemAccordion = 11">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
            Como eu fico sabendo o valor do Frete do meu pedido?
          </button>
        </h2>
      </div>
      <div id="collapse12" class="collapse" aria-labelledby="heading12" data-parent="#accordionExample" [collapse]="itemAccordion != 11" [isAnimated]="true">
        <div class="card-body">
          <p>Nós desenvolvemos um modelo muito simples para você ficar sabendo rapidamente o valor do frete que deseja contratar.</p>
          <p>Após ter efetivado seu cadastro, você conseguirá ter acesso ao nosso ambiente de simulação da coleta.</p>
          <p>
            Para facilitar e dar agilidade ao seu processo de cotação, o ChegaLog Disponibiliza três formas para você fazer suas 
            cotações e simular o valor do seu frete. <br>
            1a. Preencha o formulário manualmente com os dados solicitados. <br>
            2a. Baixe a planilha modelo em excel e coloque os dados nas colunas, salve e depois faça o upload, seu frete será calculado em instantes.  <br>
            3a. Basta você fazer o upload de alguns XMLs (CTe ou NFe) e o nosso sistema se encarregará de fazer os cálculos e te informar rapidamente 
            o valor do frete das suas encomendas.
          </p>
          <p>Você conseguira enviar até 50 arquivos (XML, ou excel) ao mesmo tempo e o sistema já irá mostrar o valor e prazo para as entregas.</p>
          <p>Observação: Nós aceitamos encomendas com embalagens que tenham as dimensões de 80cmx80cmx80cm (LxAxC) e até 8KG.</p>
          <p>
            Observação 2: Para compra de frete utilizando arquivos XMLs já no momento da cotação, 
            os dados das transportadora deverá estar com os dados da Pot Speed do estado (UF) de origem da COLETA. <br>
            Paraná:  <br>
            Santa Catarina: <br>
            Rio Grande do Sul: <br>
            São Paulo: <br>
            Minas Gerais: <br>
          </p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading13" (click)="itemAccordion = 12">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
            Quais as formas de pagamento do frete?
          </button>
        </h2>
      </div>
      <div id="collapse13" class="collapse" aria-labelledby="heading13" data-parent="#accordionExample" [collapse]="itemAccordion != 12" [isAnimated]="true">
        <div class="card-body">

          <p>Inicialmete O ChegaLog trabalhará apenas com cartão de crédito nas bandeiras Visa, MasterCard, Elo e Hipercard. que garantirá que sua coleta já seja programada logo após a confirmação do pagamento;</p>

          <p>
            Posteriormente serão inseridas mais duas formas de pagamentos pré-pagos:
          </p>
          <ul>
            <li>Boleto bancário que poderá levar até 3 dias úteis para ser confirmado o pagamento;</li>
            <li>Depósito bancário, que deverá ser confirmado com o envio do comprovante de depósito diretamente pelo site do internet banking. <br /></li>
          </ul>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading14" (click)="itemAccordion = 13">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
            Em que momento eu recebo a nota fiscal do serviço contratado?
          </button>
        </h2>
      </div>
      <div id="collapse14" class="collapse" aria-labelledby="heading14" data-parent="#accordionExample" [collapse]="itemAccordion != 13" [isAnimated]="true">
        <div class="card-body">
          <p>A Nota fiscal será emitida em até três dias após a confirmação da entrega da sua encomenda.</p>
          <p>As notas fiscais estarão disponíveis no nosso painel de gestão dos pedidos.</p>
        </div>
      </div>
    </div>
    
    <div class="card">
      <div class="card-header" id="heading20" (click)="itemAccordion = 20">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
            Como faço para cancelar um Frete Contratado?
          </button>
        </h2>
      </div>
      <div id="collapse20" class="collapse" aria-labelledby="heading20" data-parent="#accordionExample" [collapse]="itemAccordion != 20" [isAnimated]="true">
        <div class="card-body">
          <p>Você pode cancelar um pedido não coletado e se os XMLs (NF-e) ainda estiverem pendentes para envio. O Cancelamento é feito no seu Painel de Pendências (quadro de alerta amarelo), basta clicar em Enviar Arquivos e escolher o pedido que deseja Cancelar.</p>
          <p>O Valor referente aquele pedido será estornado do valor total do frete contratado.</p>
        </div>
      </div>
    </div>

      <div class="card">
        <div class="card-header" id="heading15" (click)="itemAccordion = 14">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
              Serei cobrado caso haja devolução de alguma encomenda?
            </button>
          </h2>
        </div>
        <div id="collapse15" class="collapse" aria-labelledby="heading15" data-parent="#accordionExample" [collapse]="itemAccordion != 14" [isAnimated]="true">
          <div class="card-body">
            <p>O Serviço ChegaLog irá fazer até 3 tentativas para entrega da encomenda, caso a encomenda não seja entregue, ela será devolvida e cobrado 100% do valor do Frete Original.</p>
            <p>Essa é apenas uma da ocorrência que levam a encomenda a ser devolvida, existem outras e cada uma estará registrada no histórico de cada um dos pedidos e poderá ser consultado no painel de controle.</p>
          </div>
        </div>
      </div>

      <div class="card">
      <div class="card-header" id="heading16" (click)="itemAccordion = 15">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
            Podemos ter mais do que três tentativas de entrega?
          </button>
        </h2>
      </div>
      <div id="collapse16" class="collapse" aria-labelledby="heading16" data-parent="#accordionExample" [collapse]="itemAccordion != 15" [isAnimated]="true">
        <div class="card-body">
          <p>Caso haja necessidade de uma nova entrega após às 3 tentativas sem sucesso, será cobrado um valor de 50% do valor do frete original, desde que na mesma cidade.</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading17" (click)="itemAccordion = 16">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
           Qual o Horário de Atendimento?
          </button>
        </h2>
      </div>
      <div id="collapse17" class="collapse" aria-labelledby="heading17" data-parent="#accordionExample" [collapse]="itemAccordion != 16" [isAnimated]="true">
        <div class="card-body">
          <p>O nosso atendimento e relacionamento com o cliente funciona de segunda a sexta-feira das 9h às 18h, exceto feriados.</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading18" (click)="itemAccordion = 17">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
            Quais as encomendas que não são aceitas pelo serviço ChegaLog?
          </button>
        </h2>
      </div>
      <div id="collapse18" class="collapse" aria-labelledby="heading18" data-parent="#accordionExample" [collapse]="itemAccordion != 17" [isAnimated]="true">
        <div class="card-body">
          <p>Verificar no “<a href="javascript:void(0);" (click)="openModal(template)">Termos de Uso</a>”</p>
        </div>
      </div>
    </div>

      <div class="card">
      <div class="card-header" id="heading19" (click)="itemAccordion = 18">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
            E se minha encomenda for extraviada?
          </button>
        </h2>
      </div>
      <div id="collapse19" class="collapse" aria-labelledby="heading19" data-parent="#accordionExample" [collapse]="itemAccordion != 18" [isAnimated]="true">
        <div class="card-body">
          <p>Todas as encomendas extraviadas serão reembolsadas aos clientes e a obrigação total máxima da TRANSPORTADORA é de pagamento e/ou indenização relativamente aos produtos e está limitada ao valor equivalente de suas respectivas notas fiscais.</p>
        </div>
      </div>
    </div>
  </div>

<ng-template #template>
  <div class="modal-header">
      <h4 class="modal-title pull-left">ChegaLog – Termos de uso</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <app-termo></app-termo>
  </div>
</ng-template>