<div class="row">
    <div class="col text-right px-5 py-4">
        <p>&nbsp;</p>
    </div>
</div>

<div class="ajuste-mobile">
    <div class="row">
        <div class="col">
            <h2 class="text-center">Login</h2>
            <div class="row justify-content-center mt-5">
                <div class="col-12 col-sm-5">
                    <div *ngIf="usuarioEncontrato" class="alert alert-warning text-center w-100">
                        <p class="m-0">Encontramos um usuário com o e-mail <strong>{{ emailUsuarioEncontrato }}</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-12 col-sm-5 container-mobile">
            <div class="py-5">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
                    <div class="form-group">
                    <label for="email">E-mail *</label>
                    <input type="email" class="form-control" id="email"  placeholder="E-mail" formControlName="email" required>
                    </div>

                    <div class="form-group mb-1">
                        <label for="senha">Senha</label>
                        <input type="password" class="form-control" id="senha"  placeholder="Senha" formControlName="senha" required>
                    </div>
                    <div class="text-right mb-3">
                        <a routerLink="/cadastro/esqueci-senha">Esqueci minha senha</a>
                    </div>

                    <div *ngIf="mensagemRetorno" class="alert alert-danger text-center" role="alert">
                        {{ mensagemRetorno }}
                    </div>
                    <div class="row" *ngIf="!isLoading">
                        <div class="col text-center">
                            <button type="submit" class="btn btn-primary btn-lg btn-block" [disabled]="isLoading">ENTRAR</button>
                        </div>
                    </div>
                    <div class="col text-center mt-3">
                        Ainda não tem um cadastro? <a href="javascript:void(0);" (click)="acessarCadastro()" class="mt-3">Cadastre-se</a>
                    </div>
                    <div class="text-center" *ngIf="isLoading">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>