import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { UtilsService } from './../utils/utils.service';

import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DeclaracaoConteudoService {
  private environment = environment;

  constructor(
    private readonly http: HttpClient,
    private readonly utilsService: UtilsService
  ) {}

  public salvarDestinatario(dadosDestinatariosRequest): Observable<any> {
    const url = `${this.environment.API_AWS}/api/DeclaracaoConteudo/SalvarDestinatario`;

    return this.http.post<any>(url, dadosDestinatariosRequest, { headers: this.utilsService.obterHeaderAuthorization() });
  }

  public buscarPorFreteOuFaturaId(declaracaoConteudoRequest: any): Observable<any> {
    const faturaId = declaracaoConteudoRequest?.faturaId;
    const freteId = declaracaoConteudoRequest?.freteId;

    const urlComplemento = faturaId ? `BuscarPorFatura/${faturaId}` : freteId ? `BuscarPorFrete/${freteId}` : undefined;
    if (urlComplemento === undefined) {
      return undefined;
    }
    const url = `${this.environment.API_AWS}/api/DeclaracaoConteudo/${urlComplemento}`;

    return this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() });
  }
}
