<div class="container">
    <div class="row">
        <div class="col text-right px-5 py-4">
            <p>&nbsp;</p>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col text-center">
            <h2>Código enviado</h2>
            <!-- <p class="mt-4">Enviaremos um código para o seu telefone <strong>({{ initialPhone }}) *****-{{ lastPhone }}</strong></p> -->
            <p class="mt-4">
                Acabamos de enviar um código de acesso para o celular que você informou.<br />
                Assim que você receber digite aqui para dar continuidade a sua simulação.
            </p>
        </div>
    </div>

    <div class="row justify-content-center">
        <div>
            <div class="py-5">
                <div class="mt-4" *ngIf="isCodigoInvalido && !codigoReenviado">
                    <div class="alert alert-danger" role="alert">
                        Código Inválido tente novamente.
                    </div>
                </div>
                <div class="codigos" *ngIf="!isLoading">
                    <input type="tel" *ngFor="let item of listInputs" #input mask="0" (keyup)="keyUp($event, input)">
                </div>
                <div class="text-center mt-5">
                    <ng-container *ngIf="!isLoading">
                        <div>
                            <button (click)="validarCodigo()" id="idConfirmaCodigo" class="btn btn-primary px-5" [disabled]="!validado">CONFIRMAR CÓDIGO</button>
                        </div>
                        <div class="mt-4">
                            <a href="javascript:void(0);">Reenviar código de acesso</a>
                            <!-- <a href="javascript:void(0);" (click)="reenviarCodigo()">Reenviar código de acesso</a> -->

                            <div class="alert alert-success" role="alert" *ngIf="codigoReenviado">
                                Codigo reenviado para o telefone: ({{ initialPhone }}) *****-{{ lastPhone }}
                            </div>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="isLoading">
                        <div class="text-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>

</div>
