import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm;
  public usuarioEncontrato = false;
  public emailUsuarioEncontrato = '';
  public mensagemRetorno = '';
  public isLoading = false;
  public usuario;


  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    let usuario = this.router.getCurrentNavigation().extras.state ? this.router.getCurrentNavigation().extras.state.usuario : null;
    if (usuario) {
      this.usuarioEncontrato = true;
      this.emailUsuarioEncontrato = usuario.email
    }
    // = this.router.getCurrentNavigation().extras.state.usuario
    this.loginForm = this.formBuilder.group({
      email: usuario ? usuario.email : '' ,
      senha: '',
    });
  }


  ngOnInit(): void {
    this.usuario = this.localStorageService.obterUsuario();
    if (this.usuario) {
      this.irPainel();
    }
  }
  onSubmit(data) {
    this.isLoading = true;
    this.usuarioService.logar(data.email, data.senha).subscribe((res) => {
      this.localStorageService.setarToken(res.token);
      this.setarDadosUsuario();
    }, (err) => {
      this.isLoading = false;
      console.log(err);
    });
  }
  setarDadosUsuario() {
    this.usuarioService.obterDetalhes()
      .subscribe((usuario: any) => {
        this.usuario = usuario;
        this.localStorageService.setarUsuario(usuario);

        this.obterInformacoesComplementares();

        this.irPainel();
        this.isLoading = false;
      })
  }
  acessarCadastro() {
    this.router.navigateByUrl('/cadastro/dados-empresa');
  }
  irPainel() {
    //this.router.navigateByUrl('/cliente/painel'); TODO Adaptar o painel para o novo backend
    this.router.navigateByUrl('/simulacao/file');
  }
  registraTabelaPreco(usuario, cep) {

    this.usuarioService.buscarEnderecoByCEP(cep).subscribe( retorno => {
      this.usuarioService.range(cep, retorno.state).then( res => {
        if (res.success) {
          var dadosUsuario = {...usuario, idTabelaPreco: res.data.idTabelaPreco }
          this.localStorageService.setarIdTabelaPreco(res.data.idTabelaPreco);

          this.usuarioService.atualizar(usuario.id, dadosUsuario).subscribe( data => {
            if (data._id) {
              this.localStorageService.setarUsuario(data);
            }
          });
        }
      });
    });
  }

  private obterInformacoesComplementares() {
    this.usuarioService.obterInformacoesCompletas()
      .subscribe((informacoesCompletasUsuario: any) => {
        const declaracaoConteudoNotaFiscal = informacoesCompletasUsuario.usarDeclaracaoConteudoOuNf.toUpperCase();
        if (declaracaoConteudoNotaFiscal === 'ND' || declaracaoConteudoNotaFiscal === 'DN') {
          this.usuario['declaracao'] = true;
          this.usuario['notafiscal'] = true;
        } else {
          this.usuario['declaracao'] = informacoesCompletasUsuario.usarDeclaracaoConteudoOuNf.toUpperCase() === 'D' ? true : false;
          this.usuario['notafiscal'] = informacoesCompletasUsuario.usarDeclaracaoConteudoOuNf.toUpperCase() === 'N' ? true : false;
        }

        this.usuario['dadoColetaObrigatorio'] = informacoesCompletasUsuario.apresentarFormularioInfoComplementares;
        this.usuario['endereco'] = informacoesCompletasUsuario.endereco;
        this.usuario['cliente'] = informacoesCompletasUsuario.cliente;
        this.usuario['usarDeclaracaoConteudoOuNf'] = informacoesCompletasUsuario.usarDeclaracaoConteudoOuNf;

        this.localStorageService.setarUsuario(this.usuario);
      });
  }
}
