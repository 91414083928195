<section class="hero">
    <app-header></app-header>

    <div class="mt-4 pt-5 d-none d-sm-none d-md-block"></div>

    <!-- Carrosel inicial -->


        <div class="container mt-5">
            <div class="row pt-5 text-center text-lg-left">
                <div class="col-12 col-lg-6 order-2 order-sm-2 order-lg-1 px-md-4">
                    <h1 class="display-5 fs-3 fs-sm-4 pt-5">
                        <span class="overflow-hidden d-inline-block">
                            <span class="d-inline-block">Solução de frete <span class="font-italic font-weight-normal">rápido</span> e
                                <span class="font-italic font-weight-normal">barato</span> para você, <span class="font-italic font-weight-normal">pequeno</span> e
                                <span class="font-italic font-weight-normal">médio empreendedor!</span>
                            </span>
                        </span>
                    </h1>
                    <div class="overflow-hidden">
                        <p class="text-sans-serif lead" data-zanim-xs='{"delay":1.2}' style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">Economize no envio de seus pedidos e <br>ganhe mais segurança e agilidade!</p>
                    </div>
                    <div class="mt-1 mt-sm-2">
                        <button
                            class="btn btn-primary btn-lg cta px-5 mt-5 mt-lg-0 btn-mobile adapt"
                            (click)="simular()" id="IDSimclienteHero">
                            Faça uma simulação agora
                        </button>
                    </div>
                </div>
                <div class="col-12 col-lg-6 order-1 order-sm-1 order-lg-1 text-sm-center">
                    <carousel>
                        <slide>
                            <div class="">
                                <img src="/assets/images/duvida.svg" alt="Onde fazer a cotação dos meus produtos" class="img-fluid w-75">
                            </div>
                        </slide>
                        <slide>
                            <div class="">
                                <img src="/assets/images/pesquisa.svg" alt="Cotação via xml e formulário" class="img-fluid w-75">
                            </div>
                        </slide>
                        <slide>
                            <div class="">
                                <img src="/assets/images/entrega.svg" alt="Entrega no destino após finalizada a cotação" class="img-fluid w-75">
                            </div>
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>

</section>

<div id="servicos"></div>
<section class="my-5 section1-mobile">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-9 mx-auto">
                <div class="text-center">
                    <h6 class="mt-5 mb-2 text-center">SERVIÇOS</h6>
                    <h2 class="h1 text-center font-weight-bold">Foque no seu principal negócio</h2>
                    <p class="lead mt-4">
                        O ChegaLog chegou para otimizar o seu tempo de postagem e deixar você focado no seu principal negócio, <strong>VENDER.</strong>
                        Nossa missao é te servir com as soluções da Pot Speed e manter a excelência nos serviços e atendimento.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-5 espaco-icon-mobile">
        <div class="row">
            <div class="col-12 col-sm-12 col-lg-4">
              <div class="border rounded mb-3 p-4 shadow-sm h-100 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                <img src="/assets/images/stock2.svg" height="60" class="pb-3"/>
                <h4 class="mb-3">Coleta</h4>
                <p class="pb-3">Com o serviço programado e otimizado a coleta das suas encomendas será feito com agilidade, qualidade e segurança.</p>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-lg-4">
              <div class="border rounded mb-3 p-4 shadow-sm h-100 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                <img src="/assets/images/delivery2.svg" height="60" class="pb-3"/>
                <h4 class="mb-3">Transporte</h4>
                <p>Contamos com uma frota dentro dos padrões de qualidade e segurança, com sistema de rastreamento instalado que atende mais de 4170 municípios do sul, sudeste e nordente do Brasil.</p>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-lg-4">
              <div class="border rounded mb-3 p-4 shadow-sm h-100 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.8s">
                <img src="/assets/images/navigation2.svg" height="60" class="pb-3"/>
                <h4 class="mb-3">Rastreio</h4>
                <p>O ChegaLog conta com um sistema completo de rastreio de encomendas, que permite o monitoramento de suas mercadorias em todas as fases do transporte, em tempo real.</p>
              </div>
            </div>
        </div>
    </div>
</section>

<section class="my-5">
    <div class="container">
        <div class="row bg-light2 py-2 px-lg-5 px-sm-4 rounded align-items-center">
            <div class="col-12 col-lg-4 mb-md-0 pb-4 pt-4">
              <img class="mx-auto d-block img-fluid" src="/assets/images/Foguete.svg" alt="Chegamos em Curitiba">
            </div>
            <div class="col-12 col-lg-8 text-center text-lg-left p-5">
                <h2 class="h3 text-center text-lg-left font-weight-bold">O ChegaLog chegou em Curitiba e Joinville!</h2>
                <p class="text-center text-lg-left">As empresas que estão nas cidade de Curitiba e na sua região metropolitana, Joinville e região, poderão agora levar suas encomendas para mais de 4170 municípios do sul, sudeste e nordeste, onde o ChegaLog está.</p>
                <div class="mx-auto d-block">
                    <button
                        class="btn btn-primary btn-lg cta px-5 btn-mobile"
                        (click)="openModal(templateModalAbrangencia)">
                        Veja a abrangência
                    </button>
                </div>
            </div>
        </div>
    </div>
  </section>


<div id="como-funciona"></div>
<section class="py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-9 mx-auto">
                <div class="text-center">
                    <h6 class="mt-5 mb-2 text-center">COMO FUNCIONA</h6>
                    <h2 class="h1 text-center font-weight-bold">Como fazer sua cotação</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5 espaco-icon-mobile">
        <div class="row">
            <div class="col-12 col-sm-12 col-lg-3">
              <div class="text-center p-4 mx-auto ">
                <img src="/assets/images/box2.svg" class="pb-3"/>
                <h6 class="mb-3 font-weight-bold">1. Informe os dados do frete e da mercadoria</h6>
                <p class="mb-0 text-muted">Preencha o formulário ou importe seus arquivos</p>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-lg-3">
              <div class="text-center p-4 mx-auto">
                <img src="/assets/images/todo2.svg" class="pb-3"/>
                <h6 class="mb-3 font-weight-bold">2. Escolha o melhor frete</h6>
                <p class="mb-0 text-muted">Veja as cotações do ChegaLog e selecione a melhor para você</p>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-lg-3">
              <div class="text-center p-4 mx-auto">
                <img src="/assets/images/delivery2.svg" class="pb-3"/>
                <h6 class="mb-3 font-weight-bold">3. Informe os dados da sua empresa e endereço</h6>
                <p class="mb-0 text-muted">Precisamos garantir que coletaremos suas mercadorias no lugar certo</p>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-lg-3">
                <div class="text-center p-4 mx-auto">
                  <img src="/assets/images/research2.svg" class="pb-3"/>
                  <h6 class="mb-3 font-weight-bold">4. Compre seu frete e acompanhe seu pedido</h6>
                  <p class="mb-0 text-muted">Aguarde sua coleta e rastreie sua mercadoria</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Botão FAÇA SUA COTAÇÃO-->
    <div class="container pt-5 adapt">
        <div class="row align-items-center">
            <div class="col">
                <div class="text-center">
                    <button class="btn btn-primary btn-lg cta px-5 btn-mobile" (click)="simular()" id="IDSimclienteComoFunciona">Faça uma simulação agora</button>
                </div>
            </div>
        </div>
    </div>
</section>







<!-- PARTE DO ATUAMOS EM TODAS REGIÕES DO BRASIL -->
<div id="areaAtuacao"></div>
<section class="py-5 bg-light">
    <div class="container" id="quem-somos">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6 text-center text-lg-left">
                <div class="pr-lg-5">
                    <div class="pr-lg-5 mr-lg-5">
                        <h6 class="mt-5 mb-2">ÁREA DE ATUAÇÃO</h6>
                        <h2 class="h1 font-weight-bold">Atuamos nos estados do sul, sudeste e nordeste</h2>
                        <p class="lead mt-3">
                            Desde 2013 a Pot Speed otimiza o processo de empresas que necessitam de agilidade, confiança atendimento e otimização de custos nas operações de entregas de pequenas e médias encomendas no sul, sudeste e nordeste do
                            Brasil.
                        </p>
                    </div>
                </div>
                <div class="py-4">
                    <button type="button" class="btn btn-primary btn-lg cta px-5 btn-mobile" (click)="openModal(templateModalAbrangencia)">
                        Veja a abrangência
                    </button>
                </div>

                <div class="row mt-4">
                    <div class="col-12 col-lg-4 text-center">
                        <span class="info-text text-primary">4170+</span>
                        <p class="m-0">Municípios atendidos</p>
                    </div>
                    <div class="col-12 col-lg-4 text-center">
                        <span class="info-text text-primary">99%</span>
                        <p class="m-0">Taxa de satisfação</p>
                    </div>
                    <div class="col-12 col-lg-4 text-center">
                        <!-- <p class="m-0"><strong>Clientes felizes</strong></p>
                        <span class="info-text">50+</span> -->
                        <img src="assets/images/CertificadoRA1000.png" height="100" />
                    </div>
                </div>
            </div>
            <div class="col text-center">
                <img src="assets/images/mapa-brasil.png" class="image-hero img-fluid" style="cursor: pointer;" (click)="openModal(templateModalAbrangencia)" />
            </div>
        </div>
    </div>
</section>

<!-- PARTE DO CONHEÇA A CHEGALOG -->
<div id="conheca"></div>
<div class="section1-mobile"></div>
<section class="py-5">
    <div class="container">
        <div class="text-center mb-5 pb-2 titulo-conheca-mobile">
            <h6 class="mt-5 mb-2 text-center">SOBRE</h6>
            <h2 class="h1 text-center font-weight-bold">O Chegalog</h2>
        </div>
        <div class="row">
                <div class="col-md-6 mt-0 pt-0 mt-sm-2 pt-sm-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                    <img src="/assets/images/team.svg" class="pb-3"/>
                </div>
                <div class="col-md-6 text-left lead">
                    <p class="mt-3">
                        Após 7 anos de existência e ótimos serviços prestados, crescimento sustentável e reconhecimento dos clientes, a Pot Speed desenvolveu um estudo aprofundado em sua carteira de clientes e tomou a decisão de mais uma
                        vez inovar.
                    </p>
                    <p>
                        Percebeu que poderia continuar a contribuir ainda mais com o mercado de entrega de pequenas encomendas, principalmente para os pequenos e médios empreendedores digitais.
                    </p>
                    <p>
                        E com o objetivo de oferecer um serviço de logística de coleta e entrega otimizados de forma simples, rápida e barata para os empreendedores que buscam competitividade e parceria para seu crescimento, nasce o
                        ChegaLog.
                    </p>
                    <p>
                        O ChegaLog, tem uma forma diferente de calcular o valor do frete, e é com esse diferencial que a Pot Speed irá conquistar e fidelizar, com o mesmo padrão de qualidade apresentado e comprovado por grandes
                        empreendedores, novos clientes.
                    </p>
                </div>
        </div>
    </div>
</section>

<section class="my-5 empresas-mobile">
    <div class="text-center px-3">
        <h6 class="mb-2 text-center">GRANDES MARCAS</h6>
        <h2 class="h1 text-center font-weight-bold">Empresas que confiam na Pot Speed</h2>
        <div class="lead">Grandes marcas contam com as soluções da Pot Speed para manter a excelência em seus serviços e atendimento.</div>
    </div>

    <div class="container py-5">
        <div class="row lista-empresas d-flex align-items-center justify-content-center no-gutters">
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/via-varejo.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/dafiti_logo.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/kanui_logo.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/loghaus_logo.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/marisa_logo.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/centauro.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
        </div>
        <div class="row lista-empresas d-flex align-items-center justify-content-center no-gutters">
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/cobasi_logo.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/privalia_logo.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/toy_mania_logo.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/tricae_logo.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/westwing_logo.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-2">
                <div class="empresa mx-3 border rounded p-3 text-center">
                    <img src="assets/images/logos-parceiros/magazine_luisa.jpg" height="55" class="img-fluid text-center" />
                </div>
            </div>
        </div>
    </div>
    <!-- Botão FAÇA SUA COTAÇÃO-->
    <div class="container adapt">
        <div class="row align-items-center">
            <div class="col">
                <div class="text-center">
                    <button class="btn btn-primary btn-lg cta px-5 btn-mobile" (click)="simular()" id="IDSimclienteEmpresas">Faça uma simulação agora</button>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>

<ng-template #templateModalAbrangencia>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Tabela de Abrangência</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="min-height: 50vh;">
        <div>
            <p class="ml-1"><strong>Origem:</strong></p>
            <div class="px-3">
                <div class="row">
                    <div class="form-group">
                        <select class="form-control" id="ufOrigem" placeholder="Selecione" [(ngModel)]="ufOrigem" (change)="onChangeSelectUFOrigem($event)">
                          <option value="{{ origem.nome }}" *ngFor="let origem of listaOrigens">{{ origem.nome }}</option>
                        </select>
                    </div>
                    <div class="form-group col mx-lg-3 p-0 ml-3">
                        <select class="form-control" id="regiao" placeholder="Selecione" [(ngModel)]="origem" (change)="onChangeSelect($event)">
                            <option value="0" selected="selected">Selecione</option>
                            <option value="{{ origem }}" *ngFor="let origem of listaFiltradaOrigens">{{ origem }}</option>
                        </select>
                    </div>
                    <div class="form-group col-12 col-lg-8 p-0"></div>
                </div>
            </div>

            <p class="ml-1"><strong>Destino:</strong></p>
            <div class="px-3">
              <div class="row">
                <div class="form-group">
                  <select class="form-control" id="ufOrigem" placeholder="Selecione" [(ngModel)]="ufDestino" (change)="onChangeSelectUFDestino($event)">
                      <option value="{{ destino.uf }}" *ngFor="let destino of listaDestinos">{{ destino.uf }}</option>
                    </select>
                </div>
                <div class="form-group col mx-lg-3 p-0 ml-3">
                    <select class="form-control" id="regiao" placeholder="Selecione" [(ngModel)]="regiao" (change)="onChangeSelect($event)">
                        <option value="0" selected="selected">Selecione</option>
                        <option value="{{ destino }}" *ngFor="let destino of listaFiltradaDestinos">{{ destino }}</option>
                    </select>
                </div>
                <div class="form-group col-12 col-lg-8 p-0">
                  <input type="text" autocomplete="off" class="form-control" id="cidade" placeholder="Pesquisar cidades" [(ngModel)]="cidade" (keyup)="keyup()" />
              </div>
              </div>
            </div>

            <div class="text-center mt-5 px-3" *ngIf="isLoadingAbrangencia">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="mt-5 px-3" *ngIf="cidades.length == 0 && !isLoadingAbrangencia">
                <p class="text-center">Selecione origem, destino e entenda nossos prazos de entrega.</p>
            </div>

            <div class="mt-3 px-3" *ngIf="cidades.length > 0 && !isLoadingAbrangencia">
                <div class="container-lista">
                    <div class="col mb-3">
                        <strong>{{ cidades.length }} resultados</strong>
                    </div>
                    <div *ngFor="let local of cidades; let i = index;" class="col">
                        <!-- <p class="h5"><strong>{{ local.uf }}</strong></p> -->
                        <p class="m-0">
                            <small><strong>{{ local.unidade }}</strong></small>
                        </p>
                        <p class="m-0 text-muted">
                            <small><strong>CEP inicial: </strong>{{ local.cep_inicial }} e <strong>CEP final: </strong>{{ local.cep_final }}</small>
                        </p>
                        <p class="mb-0 text-muted">
                            <small><strong>Prazo de entrega: </strong>{{ local.prazo }}</small>
                        </p>
                        <hr *ngIf="(i + 1) != cidades.length" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
