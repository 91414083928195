<div class="container">
    <div class="mt-4 text-center">
        <h5 class="p-3 bg-primary text-white">SIMULE SEU FRETE E PAGUE EM ATÉ 3X SEM JUROS!</h5>
    </div>
</div>
<div class="container">
    <div class="row mt-4 mb-2 align-center justify-content-center">
        <div class="text-center mb-4">
            <p class="titulo-paginas">Utilize os campos abaixo para simular o seu frete.</p>
        </div>
        <div class="col-12 col-lg-9 mx-auto">
            <app-formulario (isValid)="formularioEhValido($event)" (receberForm)="receberFormulario($event)"></app-formulario>
        </div>

    </div>
</div>
<div class="action-bottom-fixed d-flex">
    <div class="container d-flex">
        <button type="button" class="btn btn-outline-primary" (click)="voltar()" *ngIf="usuario">VOLTAR</button>

        <span class="flex-fill"></span>
        <button class="btn btn-primary" [disabled]="!formValido" (click)="gerarCotacao()">GERAR COTAÇÃO</button>
    </div>
</div>