import { NgModule } from '@angular/core';

import { LocalStorageService } from '../services/localStorage/localstorage.service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { RastreamentoComponent } from './rastreamento.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClienteRoutingModule } from '../cliente/cliente-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FooterModule } from '../footer/footer.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

var options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    RastreamentoComponent
  ],
  imports: [
    FooterModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ClienteRoutingModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxMaskModule.forRoot(options),
    PaginationModule.forRoot()
  ],
  exports: [RastreamentoComponent],
  entryComponents: [RastreamentoComponent],
  providers: [LocalStorageService]
})
export class RastreamentoModule { }
