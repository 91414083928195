<div class="container-principal">
    <div class="header">
        <a href="/" class="logo">
            <img src="/assets/images/chegalog.svg" height="50" >
        </a>
        <div class="container-menu-painel">
            <ul>
                <li><a routerLink="/cliente/painel" routerLinkActive="active">Painel</a></li>
                <li><a routerLink="/cliente/dados-cadastrais" routerLinkActive="active">Dados cadastrais</a></li>
                <!-- <li><a routerLink="/cliente/pedidos" routerLinkActive="active">Pedidos</a></li> -->
                <!-- <li><a href="#perguntas" routerLink="/faq">Dúvidas</a></li> -->
            </ul>
        </div>
        <user-dropdown absolute="true"></user-dropdown>


    </div>
    <div class="main">
        <router-outlet></router-outlet>
    </div>
</div>

