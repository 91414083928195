import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-documento-fiscal',
  templateUrl: './documento-fiscal.component.html',
  styleUrls: ['./documento-fiscal.component.scss']
})
export class DocumentoFiscalComponent implements OnInit {


  public radioModel;
  public frete;
  public coletas;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService,
    private usuarioService: UsuarioService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.frete = this.localStorage.obterFrete();
    this.coletas = this.localStorage.obterColetas();
  }

  confirmar() {
    var escolha = this.radioModel;
    var frete = this.frete;
    frete.tipoDocumentoFiscal = escolha == 2 ? 'declaracao' : 'notaFiscal';

    frete.declaracao = escolha == 2 ? true : false;
    frete.pedidos.map(pedido => {
      pedido.declaracao = escolha == 2 ? true : false;
    });
    this.localStorage.setarFrete(frete)

    // TODO: CRIAR ENDPOINT PARA INFORMAR O TIPO DE DOCUMENTO
    if (escolha == 2) {
      this.router.navigateByUrl('/simulacao/dados-destinatarios');
    }
    else {
      this.router.navigateByUrl('/simulacao/pagamento');
    }

  }

  voltar() {
    this.router.navigateByUrl('/simulacao/cotacao');
  }
}
