import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SimulacaoComponent } from './simulacao.component';
import { DadosColetaComponent } from './dados-coleta/dados-coleta.component';
import { DadosDestinatarioComponent } from './dados-destinatario/dados-destinatario.component';
import { UploadArquivoComponent } from './upload-arquivo/upload-arquivo.component';
import { FileComponent } from './file/file.component';
import { CotacaoComponent } from './cotacao/cotacao.component';
import { PagamentoComponent } from './pagamento/pagamento.component';
import {
    AuthGuardService as AuthGuard
  } from './../guards/auth-guard.service';

import {
    AtivoGuardService as AtivoGuard
  } from './../guards/AtivoGuard/ativo-guard.service';
import { PreenchimentoManualComponent } from './preenchimento-manual/preenchimento-manual.component';
import { CadastroSimulacaoComponent } from './cadastro/cadastro-simulacao.component';
import { ValidarAcessoComponent } from './cadastro/validar-acesso/validar-acesso.component';
import { DocumentoFiscalComponent } from './documento-fiscal/documento-fiscal.component';

const routes: Routes = [
    {
        path: 'simulacao', component: SimulacaoComponent,
        children: [
            {   path: 'dados-coleta', component: DadosColetaComponent, data: {passo: 1}},
            {   path: 'dados-destinatarios', component: DadosDestinatarioComponent, data: {passo: 1}},
            {   path: 'upload-arquivo', component: UploadArquivoComponent },
            {   path: 'file', component: FileComponent, data: {passo: 1}},
            {   path: 'preenchimento-manual', component: PreenchimentoManualComponent, data: {passo: 1} },
            {   path: 'cadastro', component: CadastroSimulacaoComponent, data: {passo: 2} },
            {   path: 'validar-acesso', component: ValidarAcessoComponent },
            {   path: 'cotacao', component: CotacaoComponent, data: {passo: 2}},
            {   path: 'pagamento', component: PagamentoComponent, data: {passo: 3}},
            {   path: 'documento-fiscal', component: DocumentoFiscalComponent, data: {passo: 2}},
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SimulacaoRoutingModule { }
