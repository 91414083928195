import { NgModule } from '@angular/core';
import { LocalStorageService } from '../services/localStorage/localstorage.service';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer.component';
import { TermoModule } from '../termo/termo.module';

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TermoModule
  ],
  exports: [FooterComponent],
  entryComponents: [FooterComponent],
  providers: [LocalStorageService]
})
export class FooterModule { }
