import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorage/localstorage.service';
import { Cotacao, Tipo } from "./../../interfaces/cotacao";

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit, AfterViewInit {
  // public files: any[] = [];
  public docs: any[] = [];
  public uploaded = false;
  public fileToUpload: File = null;
  public formUpload;
  public progress;
  public files: Set<File> = new Set();
  public uploading = false;
  public usuario;
  public possuiDuplicadas = false;
  public possuiNotaDiferenteCNPJ = false;
  public isLogado = false;

  @ViewChild('file', { static: false }) file;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private localStorage: LocalStorageService
  ) {
    this.formUpload = this.formBuilder.group({
      fileUpload: ''
    });
    this.usuario = this.localStorage.obterUsuario();
  }

  ngOnInit(): void {
    this.isLogado = this.localStorage.obterUsuario() ? true : false;

    if (this.isLogado) {
      var usuario = this.usuario;
      return;
      // if (!usuario.cnpj || !usuario.razaoSocial || !usuario.inscricaoEstadual)  {
      //   this.router.navigateByUrl('/simulacao/dados-coleta');
      // }
    } else {
      this.router.navigateByUrl('/simulacao/preenchimento-manual');
    }



  }
  ngAfterViewInit() {
    // this.usuarioService.limparNotasNaoEfetivadas(this.usuario.id).toPromise();
    this.localStorage.limparCotacao();
  }
  // BOTÃO GERAR COTAÇÃO
  public async gerarCotacao() {
    
    this.router.navigateByUrl('/simulacao/cotacao');

    // let cotacoesAtuais = this.localStorage.obterCotacao();
    // var cotacoesUploaded = await this.usuarioService.prepararCotacao(this.docs, this.usuario);
    // if (cotacoesAtuais.length == undefined) {
    //   this.localStorage.setarCotacao(cotacoesUploaded.cotacoes);
    // } else {
    //   cotacoesUploaded.cotacoes.map( itemCotacao => {
    //     cotacoesAtuais.push(itemCotacao);
    //   });
    //   this.localStorage.setarCotacao(cotacoesAtuais);
    // }

    // this.usuarioService.gravarCotacoes(this.usuario, this.localStorage.obterCotacao()).subscribe( res => {
    //   this.router.navigateByUrl('/simulacao/cotacao');
    // }, err => {
    //   debugger;
    // });
  }
  // BOTÃO VOLTAR
  public voltar() {
    // this.router.navigateByUrl('/simulacao/dados-coleta');
    window.history.back();
  }
  tipoProgressBar(progresso, duplicada, isMesmoCNPJ) {
    if (progresso == 100 && isMesmoCNPJ == true) {
      return 'success'
    }
    if (duplicada == true) {
      return 'danger'
    }
    if (isMesmoCNPJ == false) {
      return 'danger'
    }
    return 'warning';
  }
  acessarModoManual() {
    this.router.navigateByUrl('simulacao/preenchimento-manual');
  }
  terminouUpload(event) {
    
    this.uploaded = !event.possuiNotaDiferenteCNPJ;
    this.uploading = false;
    this.docs = event.docs;
    /*
    this.possuiNotaDiferenteCNPJ = false;
    if (event.possuiNotaDiferenteCNPJ) {
      this.possuiNotaDiferenteCNPJ = true;
    }
    this.uploading = false;

    this.uploaded = false;

    if (event.docs.length > 0) {
      this.docs = event.docs;
      this.uploaded = true;
    }
    */
  }

}
