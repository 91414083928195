<div class="container-principal">
    <div class="header">
        <a href="/" class="logo">
            <img src="/assets/images/chegalog.svg" height="50" >
        </a>
        <div class="progresso col-12 col-sm-6 d-none d-sm-block">


            <ul>
                <li
                    *ngFor="let passo of passos; let i = index;"
                    [ngClass]="{
                        'current': (i + 1) == passoAtual,
                        'done': (i + 1) < passoAtual
                    }">{{ passo.nome }}</li>
            </ul>
        </div>

        <user-dropdown [absolute]="true"></user-dropdown>
    </div>




    <div class="main">
        <router-outlet></router-outlet>
    </div>
</div>
