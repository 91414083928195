import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { UtilsService } from './../utils/utils.service';

import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EtiquetaService {
  private environment = environment;

  constructor(
    private readonly http: HttpClient,
    private readonly utilsService: UtilsService
  ) {}

  public obterPorFreteId(freteId): Observable<any> {
    const url = `${this.environment.API_AWS}/api/Etiquetas/BuscarPorFrete/${freteId}`;

    return this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() });
  }
}
