import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {Cotacao, Tipo} from 'src/app/interfaces/cotacao';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-preenchimento-manual',
  templateUrl: './preenchimento-manual.component.html',
  styleUrls: ['./preenchimento-manual.component.scss']
})
export class PreenchimentoManualComponent implements OnInit {
  public volumes: any [] = [];
  embalagemForm;
  usuario;
  public formValido = false;
  mercadorias: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private localStorage: LocalStorageService
  ) {
    this.embalagemForm = this.formBuilder.group({
      cepOrigem: '',
      cepDestino: '',
      valorMercadoria: '',
      tipoMercadoria: '',
      numeroVolumes: '',
      pesoVolume: '',
      altura: '',
      largura: '',
      comprimento: ''
    });

    this.usuario = this.localStorage.obterUsuario();
  }
  ngOnInit() {
  }
  onSubmit(data) {
  }
  voltar() {
    this.router.navigateByUrl('/simulacao/file');
  }
  async gerarCotacao() {
    let cotacoes = [];

    for (let mercadoria of this.mercadorias) {
      let endereco = await this.usuarioService.buscarEnderecoByCEP(mercadoria.cepDestino).toPromise();
      let quantidadeTotal = 0;
      let pesoCubico = 0;

      mercadoria.itensVolumes.map(function( volume ) {
        quantidadeTotal = quantidadeTotal + Number(volume.quantidade);
      });

      // Comprimento x Largura x Altura x Fator cubagem;
      mercadoria.itensVolumes.map(function( volume ) {
        pesoCubico = Math.round(parseFloat(`0.${volume.comprimento}`) * parseFloat(`0.${volume.largura}`) * parseFloat(`0.${volume.altura}`) * 300);
      });

      cotacoes.push(new Cotacao(<Cotacao>{
        _id: mercadoria.id ? mercadoria.id : (Math.floor(Math.random() * 5000) + 1000).toString(),
        id: '-',
        chave: '-',
        quemEntrega: 'pot',
        numeroNFCTe: '-',
        quantidade: quantidadeTotal,
        pesoCubico: pesoCubico,
        estado: endereco.state,
        cidade: endereco.city,
        cepOrigem: mercadoria.cepOrigem,
        CEP: endereco.cep,
        prazo: '-',
        valor: mercadoria.valorMercadoria,
        valorFrete: { valor: 0, isLoading: true },
        cepAtendido: true,
        tipo: Tipo.FORM,
        pendenteNotaFiscal: true,
        mercadoria: {
          tipo: mercadoria.tipoMercadoria,
          volumes: mercadoria.itensVolumes
        }
        // mercadorias: [
        //   tipoMercadoria: '',
        //   volumes: mercadoria.itensVolumes
        // ]
      }).cotacao);
    }
    this.localStorage.setarCotacao(cotacoes);

    if (!this.usuario) {
      this.usuario = this.localStorage.obterUsuario();
    }

    if (this.usuario) {
      this.router.navigateByUrl('/simulacao/cotacao');
      // this.usuarioService.gravarCotacoes(this.usuario, cotacoes).subscribe( res => {
      // }, (err) => {
      //   debugger;
      // });
      return;
    }
    this.router.navigateByUrl('/simulacao/cadastro');

  }

  formularioEhValido(event) {
    this.formValido = event;
  }
  receberFormulario(event) {
    var formulario = event;
    this.mercadorias = formulario.value.mercadoriasItems
  }
}
