import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CadastroModule } from './cadastro/cadastro.module';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { SimulacaoModule } from './simulacao/simulacao.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuardService } from './guards/auth-guard.service';
var options: Partial<IConfig> | (() => Partial<IConfig>);
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { AtivoGuardService } from './guards/AtivoGuard/ativo-guard.service';
import { AccordeonComponent } from './accordeon/accordeon.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ClienteModule } from './cliente/cliente.module';
import { ClienteChegalogComponent } from './cliente-chegalog/cliente-chegalog.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FaqComponent } from './faq/faq.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { RastreamentoModule } from './rastreamento/rastreamento.module';
import { FooterModule } from './footer/footer.module';
import { TermoModule } from './termo/termo.module';
import { ContatoComponent } from './contato/contato.component';
import { HeaderComponent } from './header/header.component';
import { EtiquetaComponent } from './etiqueta/etiqueta.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxBarcodeModule } from 'ngx-barcode';
import { DeclaracaoConteudoComponent } from './declaracao-conteudo/declaracao-conteudo.component';
import { CpfCnpjPipeModule } from './pipe/cpfCnpj.pipe.module';
import { UserDropdownModule } from './components/user-dropdown.module';

registerLocaleData(localePt);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AccordeonComponent,
    ClienteChegalogComponent,
    FaqComponent,
    ContatoComponent,
    HeaderComponent,
    EtiquetaComponent,
    DeclaracaoConteudoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CadastroModule,
    SimulacaoModule,
    ClienteModule,
    AppRoutingModule,
    CollapseModule,
    RastreamentoModule,
    FooterModule,
    TermoModule,
    NgxQRCodeModule,
    NgxBarcodeModule,
    CpfCnpjPipeModule,
    NgxMaskModule.forRoot(options),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    UserDropdownModule
  ],
  providers: [
    AuthGuardService,
    AtivoGuardService,
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
