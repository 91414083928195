import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDropdownComponent } from './user-dropdown.component';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
    imports: [
        BsDropdownModule.forRoot(),
        RouterModule,
        CommonModule
    ],
    declarations: [
        UserDropdownComponent,
    ],
    entryComponents: [
        UserDropdownComponent,
    ],
    exports: [
        UserDropdownComponent,
    ],
    providers: [
        UserDropdownComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class UserDropdownModule{}