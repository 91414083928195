<div class="text-center pt-5 pb-3">
    <p class="text-center h4">Documento Fiscal</p>
</div>

<!-- <pre>{{ coletas | json }}</pre> -->
<div class="container mb-5 pb-5 mb-sm-0 pb-sm-0">
    <div class="row">
        <div class="col-12 col-sm-8 mx-auto text-center">
            <span class="px-3">
                É essencial que todo objeto transportado esteja acompanhado da documentação
                correta. A sua mercadoria será enviada através da
                <strong>Nota Fiscal</strong>
                ou
                <strong>Declaração de conteúdo</strong>
                ?
            </span>
        </div>
    </div>

    <div class="container-opcoes">

        <div class="container-opcoes__item mr-3" (click)="radioModel = 1">
            <div class="p-4">
                <div class="mr-3 mb-2">
                    <img src="assets/images/icone-file.png"/>
                </div>

                <p class="h5 mb-3">Nota Fiscal</p>
                <small>
                    A nota fiscal é utilizada para comprovar a existência de uma relação comercial,
                    formalizada por meio de um documento reconhecido pela lei.
                </small>
                <div class="custom-control custom-radio mr-3 mt-4 container-radio">
                    <input  type="radio" id="usarNota" name="usarNota"  class="custom-control-input" [value]="1" [(ngModel)]="radioModel" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label p-0" for="usarNota">Usar Nota Fiscal</label>
                </div>
            </div>

        </div>

        <div class="container-opcoes__item ml-3" (click)="radioModel = 2">

            <div class="p-4">
                <div class="mr-3 mb-2">
                    <img src="assets/images/icone-file.png"/>
                </div>

                <p class="h5 mb-3">Declaração de conteúdo</p>

                <small>
                    A declaração de conteúdo é um documento que deve acompanhar todos os envios para os quais
                    a nota fiscal não é exigida.
                </small>
                <div class="custom-control custom-radio mr-3 mt-4 container-radio">
                    <input  type="radio" id="usarDeclaracao" name="usarDeclaracao"  class="custom-control-input" [value]="2" [(ngModel)]="radioModel" [ngModelOptions]="{standalone: true}">
                    <label class="custom-control-label p-0" for="usarDeclaracao">Usar Declaração de conteúdo</label>
                </div>


            </div>
        </div>

    </div>
</div>

<div class="action-bottom-fixed d-flex">
    <div class="container align-items-center d-none d-sm-flex">
        <div>
            <button type="button" class="btn btn-outline-primary btn-lg" (click)="voltar()">VOLTAR</button>
        </div>
        <span class="flex-fill"></span>

        <div>
            <button id="idConfirmarFiscal" class="btn btn-primary btn-lg" (click)="confirmar()" [disabled]="!radioModel">
                CONFIRMAR
            </button>
        </div>
    </div>
</div>
