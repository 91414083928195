import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpfCnpjPipe } from './cpfCnpj.pipe';

@NgModule({
    declarations: [CpfCnpjPipe],
    imports: [ CommonModule ],
    exports: [ CpfCnpjPipe ],
    providers: [CpfCnpjPipe],
})
export class CpfCnpjPipeModule {}