<div class="text-center pt-5 pb-3">
    <p class="text-center titulo-paginas">Veja as cotações do ChegaLog e contrate seu frete agora</p>
</div>

<div class="container p-3 p-sm-0">
    <div class="row">
        <div class="alert alert-warning text-center w-100">
            <span class="material-icons position-absolute pl-3">warning</span>
            <span class="pl-5">Para contratar o serviço ChegaLog, a sua NF-e deverá estar corretamente preenchida</span>
        </div>
        <div class="cotacao">
            <div class="header d-none d-sm-flex">
                <div>
                    <img src="assets/images/delivery-fast.svg" />
                </div>
                <div class="ml-2 mr-5">
                    <p class="m-0">De {{ origem.text }}</p>
                </div>

                <div>
                    <img src="assets/images/pin-copy.svg" />
                </div>
                <div class="ml-2 mr-5">
                    <p class="m-0">{{ cidades.length }} Destino{{ cidades.length > 1 ? 's' : '' }}</p>
                </div>

                <!-- <div>
                    COMENTEI POR QUE ESTÁ UM VALOR FIXO
                    <img src="assets/images/caixa-simples.svg" />
                </div>
                <div class="ml-2 mr-5">
                    <p class="m-0">80 x 80 x 80 cm</p>
                </div> -->

                <span class="flex-fill"></span>

                <div class="text-right mr-4">
                    <p class="m-0">Valor total NFe: <strong>{{ total.valor | currency : 'BRL'}}</strong></p>
                </div>
                <div class="text-right ml-4">
                    <p class="m-0">Total do frete selecionado: <strong>
                            {{ totalFreteSelecionado() | currency : 'BRL' }}
                        </strong></p>
                </div>
            </div>

            <div class="conteudo">
                <!-- <pre>totalSelecionado(): {{ totalSelecionado() | json }}</pre> -->
                <!-- <pre>getSelectedRows(): {{ getSelectedRows() | json }}</pre> -->
                <!-- <pre>isAllSelected(): {{ isAllSelected() | json }}</pre> -->

                <table class="com-checkbox">
                    <thead>
                        <tr>
                            <th style="width: 70px;" class="text-center">
                                <div class="custom-control custom-checkbox ml-3">
                                    <input type="checkbox" class="custom-control-input" [(ngModel)]="todosSelecionados"
                                        id="selectAll" (change)="changeSelectAll($event)">
                                    <label class="custom-control-label" for="selectAll"></label>
                                </div>
                            </th>
                            <th>Quem entrega</th>
                            <th class="d-none d-sm-table-cell">Nº da NFe / CTE </th>
                            <th class="text-center d-none d-sm-table-cell">Qtde.</th>
                            <th class="text-center d-none d-sm-table-cell">Estado</th>
                            <th class="text-center d-none d-sm-table-cell">Cidade</th>
                            <th class="text-center d-none d-sm-table-cell">Prazo de entrega</th>
                            <th class="text-right d-none d-sm-table-cell">Valor do pedido</th>
                            <th class="text-right">Valor do frete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cotacao of cotacoes" [class.selecionado]="rowIsSelected(cotacao._id)"
                            [ngClass]="{'invalido': !cotacao.cepAtendido}"
                            (click)="(!cotacao.adicionando ? onRowClick(cotacao) : null); (!cotacao.adicionando ? isAllSelected() : null)">
                            <!-- <pre>{{ cotacao | json }}</pre> -->
                            <ng-container *ngIf="!cotacao.adicionando">
                                <!-- <pre>{{ cotacao | json }}</pre> -->
                                <td style="width: 70px;" class="text-center">
                                    <div class="custom-control custom-checkbox ml-3">
                                        <input type="checkbox" class="custom-control-input" id="{{ cotacao._id }}"
                                            [checked]="rowIsSelected(cotacao._id) && !cotacao.excluido"
                                            (change)="isAllSelected()" (click)="onRowClick(cotacao)"
                                            [disabled]="!cotacao.cepAtendido">
                                        <label class="custom-control-label" for="{{ cotacao._id }}"></label>
                                    </div>
                                </td>
                                <td>
                                    <img *ngIf="cotacao.quemEntrega != '-'" src="assets/images/potspeed.svg"
                                        height="25" />
                                    <!-- <img *ngIf="cotacao.quemEntrega == 'pot'" src="assets/images/potspeed.svg" height="25" /> -->
                                    <!-- <img *ngIf="cotacao.quemEntrega != 'pot'" src="assets/images/logo-correios-256@2x.png" width="76" /> -->

                                    <div class="d-block d-sm-none">
                                        <div>
                                            <a class="link-icon" href="javascript:void(0);"
                                                *ngIf="cotacao.numeroNFCTe.length > 1"
                                                (click)="$event.stopPropagation(); abrirModalInformacoesXML(cotacao)"
                                                tooltip="Visualizar informações do XML">
                                                <div class="row align-items-center">
                                                    <strong>{{ cotacao.numeroNFCTe }}</strong>
                                                    <i class="material-icons ml-1" style="font-size: 22px;">search</i>
                                                </div>
                                            </a>
                                            <span *ngIf="cotacao.numeroNFCTe.length == 1">{{ cotacao.id }}</span>
                                        </div>
                                        <div>
                                            <small><strong>Qtde.:</strong> {{ cotacao.quantidade }}</small>
                                        </div>
                                        <div>
                                            <small><strong>Estado</strong>: {{ cotacao.estado }}</small>
                                        </div>
                                        <div>
                                            <small><strong>Cidade:</strong> {{ cotacao.cidade }}</small>
                                        </div>
                                        <div>
                                            <small><strong>Valor do pedido:</strong> {{ cotacao.valor | currency : 'BRL'
                                                }}</small>
                                        </div>

                                    </div>
                                </td>
                                <td class="d-none d-sm-table-cell">
                                    <a class="link-icon" href="javascript:void(0);"
                                        *ngIf="cotacao.numeroNFCTe.length > 1"
                                        (click)="$event.stopPropagation(); abrirModalInformacoesXML(cotacao)"
                                        tooltip="Visualizar informações do XML">
                                        <div class="row align-items-center">
                                            <strong>{{ cotacao.numeroNFCTe }}</strong>
                                            <i class="material-icons ml-1" style="font-size: 22px;">search</i>
                                        </div>
                                    </a>
                                    <span *ngIf="cotacao.numeroNFCTe.length == 1">{{ cotacao.id }}</span>
                                </td>
                                <td class="text-center d-none d-sm-table-cell">{{ cotacao.quantidade }}</td>
                                <td class="text-center d-none d-sm-table-cell">{{ cotacao.estado }}</td>
                                <td class="text-center d-none d-sm-table-cell">{{ cotacao.cidade }}</td>
                                <td class="text-center d-none d-sm-table-cell">{{ cotacao.prazo }}</td>
                                <td class="text-right d-none d-sm-table-cell">{{ cotacao.valor | currency : 'BRL' }}
                                </td>
                                <td class="text-right">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <div class="spinner-border text-primary" style="width: 1.5rem; height: 1.5rem;"
                                            role="status" *ngIf="cotacao.valorFrete.isLoading">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <span *ngIf="!cotacao.valorFrete.isLoading && cotacao.cepAtendido">{{
                                            cotacao.valorFrete.valor | currency : 'BRL' }}</span>
                                        <span *ngIf="!cotacao.valorFrete.isLoading && !cotacao.cepAtendido"><strong>CEP
                                                não atendido</strong></span>
                                        <i class="material-icons ml-2 text-warning"
                                            *ngIf="!cotacao.valorFrete.isLoading && cotacao.transportadoraDiferente"
                                            tooltip="A transportadora não é a mesma da Pot Speed">warning_amber</i>
                                    </div>

                                </td>

                            </ng-container>
                            <ng-container *ngIf="cotacao.adicionando">
                                <td colspan="9" class="text-center pl-4">
                                    <div class="d-flex align-items-center pl-2">
                                        <progressbar class="progress-striped active" [animate]="true" [striped]="true"
                                            [value]="cotacao.progress | async "><i>Aguarde...</i></progressbar>
                                    </div>
                                </td>
                            </ng-container>

                        </tr>

                    </tbody>
                    <tfoot class="rodape-tabela">
                        <tr>
                            <td colspan="3" class="pl-4">
                                <span class="ml-2">Total</span>
                            </td>
                            <td class="text-center">{{ total.quantidade }}</td>
                            <td colspan="3"></td>
                            <td class="text-right">{{ total.valor | currency : 'BRL' }}</td>
                            <td class="text-right">{{ totalFreteSelecionado() | currency : 'BRL' }}</td>
                        </tr>
                    </tfoot>
                </table>
                <div class="d-xs-block d-sm-none">
                    <div class="row py-3 ">
                        <div class="col">
                            Total
                        </div>
                        <div class="col text-right">
                            {{ total.valor | currency : 'BRL' }}
                        </div>
                    </div>

                </div>

            </div>
            <div class="conteudo">
                <div class="d-flex py-3 mb-5">
                    <div class="col d-none d-sm-flex">
                        <div>
                            <div class="d-flex align-items-center">
                                <small><i>* Entenda como excluir</i></small>
                                <small><i class="material-icons helper text-muted ml-2" tooltip="
                                    Para excluir algum item basta desmarcar a linha desejada clicando no icone de seleção ou clicando em qualquer parte da linha.
                                ">help</i></small>
                            </div>
                            <div class="d-flex align-items-center">
                                <small><i>* Entenda como editar</i></small>
                                <small><i class="material-icons helper text-muted ml-2" tooltip="
                                    Para editar alguma informação de um pedido desmarque e adicione o pedido novamente.
                                ">help</i></small>
                            </div>
                        </div>
                    </div>
                    <div class="col text-right">
                        <div class="d-flex justify-content-end">
                            <a href="javascript:void(0);" (click)="adicionarPedidos()"
                                class="d-flex align-items-center no-decoration-hover">
                                <i class="material-icons">add</i>Adicionar mais pedidos
                            </a>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    </div>
</div>

<div class="action-bottom-fixed d-flex">
    <div class="container align-items-center d-none d-sm-flex">
        <div>
            <button type="button" class="btn btn-outline-primary btn-lg" (click)="novaSimulacao()">Nova cotação</button>
        </div>
        <span class="flex-fill"></span>
        <div class="text-right mr-4" style="line-height: 120%;">
            <span>Total do frete: <strong>{{ totalFreteSelecionado() | currency : 'BRL' }}</strong></span><br />
            <small style="color: #373A3C;"><i>valor mínimo do frete R$ 306,00</i></small>
        </div>

        <div>
            <button id="idContratar" class="btn btn-primary btn-lg red" (click)="pagar()"
                [disabled]="totalFreteSelecionado() < freteMinimo || foraAbrangencia">

                <ng-template #popTemplate>
                    <p style="font-weight: bold; color: #373A3C; margin: 0;" class="text-center">A quantidade mínima
                        para prosseguir é de {{ freteMinimo }}</p>
                </ng-template>

                <span *ngIf="totalFreteSelecionado() < freteMinimo" [popover]="popTemplate"
                    triggers="mouseenter:mouseleave">
                    Contratar frete
                </span>

                <span *ngIf="totalFreteSelecionado() >= freteMinimo">
                    Contratar frete
                </span>
            </button>
        </div>
    </div>

    <div class="container align-items-center d-block d-sm-none">

        <div class="row">
            <div class="col">
                <span style="font-size: 14px;">Total do frete <small style="color: #373A3C;"><i>(min. R$
                            306,00)</i></small> </span>
            </div>
            <div class="col-4 text-right">
                <strong style="font-size: 14px;">{{ totalFreteSelecionado() | currency : 'BRL' }}</strong>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col">
                <button type="button" class="btn btn-outline-primary" (click)="novaSimulacao()">Nova cotação</button>
            </div>
            <div class="col text-right pl-0">
                <button id="idContratar" class="btn btn-primary red" (click)="pagar()"
                    [disabled]="totalFreteSelecionado() < freteMinimo">

                    <ng-template #popTemplate>
                        <p style="font-weight: bold; color: #373A3C; margin: 0;" class="text-center">A quantidade mínima
                            para prosseguir é de {{ freteMinimo }}</p>
                    </ng-template>

                    <span *ngIf="totalFreteSelecionado() < freteMinimo" [popover]="popTemplate"
                        triggers="mouseenter:mouseleave">
                        Contratar frete
                    </span>

                    <span *ngIf="totalFreteSelecionado() >= freteMinimo">
                        Contratar frete
                    </span>
                </button>
            </div>
        </div>

    </div>
</div>

<ng-template #temmplateModalAdicionar>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Adicionar mais pedidos</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="resetModalUpload();modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <pre>{{ docs | json }}</pre> -->
        <ng-container *ngIf="tipoCotacao == 'xml'">
            <!-- <pre>{{ tipoCotacao | json }}</pre> -->
            <app-drag-area (finish)="finishDragEvent($event)" isModal="true" [tiposArquivos]="['xml']"></app-drag-area>
        </ng-container>
        <ng-container *ngIf="tipoCotacao == 'xls'">
            <!-- <pre>{{ tipoCotacao | json }}</pre> -->
            <app-drag-area (finish)="finishDragEvent($event)" isModal="true" [tiposArquivos]="['xls']"></app-drag-area>
        </ng-container>
        <ng-container *ngIf="tipoCotacao == 'form' ">
            <p class="titulo-paginas">Insira os dados da sua mercadoria</p>

            <app-formulario isModal="true" (isValid)="formularioEhValido($event)"
                (receberForm)="receberFormulario($event)" [origem]="origem">
            </app-formulario>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="cancelarModal()">Cancelar</button>
        <button type="button" class="btn btn-primary" *ngIf="tipoCotacao == 'form'" [disabled]="!formValido"
            (click)="adicionarItensCotacao()">Adicionar</button>
        <button type="button" class="btn btn-primary" *ngIf="tipoCotacao == 'xml' || tipoCotacao == 'xls'"
            [disabled]="!xmlsValido" (click)="adicionarItensCotacao()">Adicionar</button>
    </div>
</ng-template>
<ng-template #templateModalInformacoesXML>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Detalhes da NFe</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            <small>Nº da NFe / Pedido: <strong>{{ informacoesXML.numeroNFCTe }}</strong></small>
        </div>
        <div class="mb-3">

            <small class="m-0"><strong>{{ informacoesXML.cliente }}</strong></small><br />
            <small>
                {{ informacoesXML.logradouro }}, {{ informacoesXML.numero }} <br />
                {{ informacoesXML.bairro }} <br />
                {{ informacoesXML.cidade }} - {{ informacoesXML.estado }}<br />
                {{ informacoesXML.cep | mask : '00000-000' }}
            </small>
        </div>
        <!-- <pre>{{ informacoesXML | json }}</pre> -->
        <div>
            <div class="mb-2">
                <small><strong>Itens {{ informacoesXML.mercadoria.volumes.length }}</strong></small>
            </div>
            <div *ngFor="let volume of informacoesXML.mercadoria.volumes; let i = index;">
                <small>{{ volume.produto }}</small>
                <hr *ngIf="(i + 1) < informacoesXML.mercadoria.volumes.length" class="my-2" />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">OK</button>
    </div>
</ng-template>

<ng-template #temmplateModalPagarColeta>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Contratar frete</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelarModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="px-3 mt-3">
                <img src="assets/images/warning.svg" />
            </div>
            <div class="col">
                <p>Atenção!</p>

                <p>
                  Você deverá emitir o documento fiscal para que possamos fazer a entrega da(s) sua(s) encomendas.
                  Se você já trabalha com NFe, você deve estar atendo ao preenchimento do campo destinado aos dados da
                  transportadora para garantir que o upload do(s) arquivo(s) XML fique correto. Abaixo está indicando os dados da transportadora.
                  Agora se você for trabalhar com Declaração de conteúdo, fique atento aos avisos nas telas seguintes.
                </p>
                <!-- <p>Mas não se preocupe se você já possui a NFe ou utilizou ela para realizar a simulação, você poderá envia-la através do <span class="text-primary"><strong>Painel</strong></span></p> -->
            </div>

        </div>
        <div class="my-3">
            <p class="mb-0"><strong>CNPJ:</strong> {{ potSpeed.cnpj | mask : '00.000.000/0000-00' }}</p>
            <span><strong>Estadual:</strong> {{potSpeed.estadual}}</span>
        </div>
        <div>
            <span>{{ potSpeed.logradouro }}, {{ potSpeed.numero }}</span><br />
            <span>{{ potSpeed.bairro }}</span><br />
            <span>{{ potSpeed.cidade}} - {{ potSpeed.uf }}</span>
        </div>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="!visualizacaoModalTransportadora">
            <button type="button" class="btn btn-outline-primary" (click)="cancelarModal()">Cancelar</button>
            <button type="button" class="btn btn-primary" (click)="continuar()">Continuar</button>
        </ng-container>
        <ng-container *ngIf="visualizacaoModalTransportadora">
            <button type="button" class="btn btn-outline-primary" (click)="cancelarModal()">Ok, entendi</button>
        </ng-container>
    </div>
</ng-template>

<ng-template #temmplateModalPendenciaTransportadora>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Contratar frete</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row align-items-center">
            <div class="px-3">
                <img src="assets/images/warning.svg" />
            </div>
            <div class="col">
                <p class="mb-0">Detectamos que o XML para fazer a cotação ainda não consta o CNPJ da transportadora Pot
                    Speed, para dar continuidade a sua compra de frete, preencha a NFe com o CNPJ e faça novamente o
                    upload dos arquivos.</p>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="cancelarModal()">OK, ENTENDI</button>
    </div>
</ng-template>

<ng-template #temmplateModalForaCuritiba>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Abrangência</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row align-items-center">
            <div class="col">
                <p>
                    Oi, tudo bem?<br />
                    Percebemos a sua localização está fora da nossa região inicial de Coleta.
                </p>
                <p>
                    O valor de frete que você está vendo, está considerando a Origem como Curitiba e Região
                    Metropolitana ou Joinville e região.
                </p>
                <p>
                    Estamos trabalhando para atender sua região, para mais informações nos envie uma mensagem pelo <a
                        href="/contato">Fale Conosco</a>.
                </p>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="cancelarModal()">OK, ENTENDI</button>
    </div>
</ng-template>
