
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    AuthGuardService as AuthGuard
  } from './../guards/auth-guard.service';

import {
    AtivoGuardService as AtivoGuard
  } from './../guards/AtivoGuard/ativo-guard.service';
import { ClienteComponent } from './cliente.component';
import { DadosCadastraisComponent } from './dados-cadastrais/dados-cadastrais.component';
import { PainelComponent } from './painel/painel.component';
import { DetalheHistoricoComponent } from './detalhe-historico/detalhe-historico.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { DeclaracaoConteudoComponent } from './declaracao-conteudo/declaracao-conteudo.component';

const routes: Routes = [
    {
        path: 'cliente', component: ClienteComponent,
        canActivate: [AuthGuard],
        children: [
            {   path: 'painel', component: PainelComponent},
            {   path: 'dados-cadastrais', component: DadosCadastraisComponent},
            {   path: 'detalhe-historico/:chave', component: DetalheHistoricoComponent},
            {   path: 'pedidos', component: PedidosComponent},
            {   path: 'declaracao-conteudo', component: DeclaracaoConteudoComponent},
        //     {   path: 'upload-arquivo', component: UploadArquivoComponent },
        //     {   path: 'file', component: FileComponent },
        //     {   path: 'embalagem', component: EmbalagemComponent },
        //     {   path: 'cotacao', component: CotacaoComponent },
        //     {   path: 'pagamento', component: PagamentoComponent},
        ]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class ClienteRoutingModule { }