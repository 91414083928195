import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorage/localstorage.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

export const cnpjValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  var cnpj = control.value.replace(/[^\d]+/g,'');

  if(cnpj == '') return {invalido: true};

  if (cnpj.length != 14)
      return {invalido: true};

  // Elimina CNPJs invalidos conhecidos
  if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return {invalido: true};

  // Valida DVs
  var tamanho = cnpj.length - 2
  var numeros = cnpj.substring(0,tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
          pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0))
      return {invalido: true};

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
          pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1))
        return {invalido: true};

  return null;
};
@Component({
  selector: 'app-dados-cadastrais',
  templateUrl: './dados-cadastrais.component.html',
  styleUrls: ['./dados-cadastrais.component.scss']
})
export class DadosCadastraisComponent implements OnInit {
  public cadastroForm;
  public usuario;
  public isLoading = false;
  public loadingSuporte = true;
  public comoConheceuOpcoes = [];
  public representanteOpcoes = [];

  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    this.usuario = this.localStorageService.obterUsuario();
    this.cadastroForm = this.formBuilder.group({
      cnpj: [this.usuario.cnpj || '', [
        Validators.required,
        cnpjValidator]
      ],
      inscricaoEstadual: [this.usuario.inscricaoEstadual || '', Validators.required],
      razaoSocial: [this.usuario.razaoSocial || '', Validators.required],
      cep: [(this.usuario.endereco ? this.usuario.endereco.cep : null) || '', Validators.required],
      endereco: [(this.usuario.endereco ? this.usuario.endereco.endereco : null) || '', Validators.required],
      numero: [(this.usuario.endereco ? this.usuario.endereco.numero : null) || '' , Validators.required],
      complemento: [(this.usuario.endereco ? this.usuario.endereco.complemento : null) || ''],
      bairro: [(this.usuario.endereco ? this.usuario.endereco.bairro : null ) || '', Validators.required],
      cidade: [(this.usuario.endereco ? this.usuario.endereco.cidade : null ) || '', Validators.required],
      estado: [(this.usuario.endereco ? this.usuario.endereco.estado : null ) || '', Validators.required],
      // mesmoEnderecoColeta: this.ehMesmoEnderecoColeta() ? 'sim' : 'nao',
      // cepColeta: (this.usuario.endereco ? this.usuario.endereco.cep : '') || '',
      // enderecoColeta: [(this.usuario.endereco ? this.usuario.endereco.enderecoColeta.endereco : null) || this.usuario.endereco],
      // numeroColeta: [(this.usuario.endereco ? this.usuario.endereco.enderecoColeta.numero : null) || (this.usuario.endereco ? this.usuario.endereco.numero : '')],
      // complementoColeta: [(this.usuario.endereco ?this.usuario.endereco.enderecoColeta.complemento : null) || (this.usuario.endereco ? this.usuario.endereco.complemento : '')],
      // bairroColeta: [(this.usuario.endereco ? this.usuario.endereco.enderecoColeta.bairro : null) || (this.usuario.endereco ? this.usuario.endereco.bairro : '')],
      // cidadeColeta: [(this.usuario.endereco ? this.usuario.endereco.enderecoColeta.cidade : null) || (this.usuario.endereco ? this.usuario.endereco.cidade : '')],
      // estadoColeta: [(this.usuario.endereco ? this.usuario.endereco.enderecoColeta.estado : null) || (this.usuario.endereco ? this.usuario.endereco.estado : '')],
      nomeEmpresa: (this.usuario.nomeEmpresa ? this.usuario.nomeEmpresa : '') || '',
      nome: this.usuario.nome || '',
      email: [this.usuario.email || '',[
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]
      ],
      celular: this.usuario.celular || '',
      comoConheceu: this.usuario.comoConheceu || '',
      nomeRepresentante: this.usuario.nomeRepresentante || ''
    })
  }

  ngOnInit(): void {
    this.obterComoConheceu();
    this.obterRepresentante();
  }

  public obterInformacoesCompletas() {
    // this.usuarioService.obterInformacoesCompletas()
    //   .subscribe()
    this.usuario = this.localStorageService.obterUsuario();
    console.log()
  }

  ehMesmoEnderecoColeta() {
    if (this.usuario.endereco) {
      let endereco: any = JSON.stringify(this.usuario.endereco);
      endereco = JSON.parse(endereco);
      delete endereco.enderecoColeta;
      endereco = JSON.stringify(endereco);

      let enderecoColeta = JSON.stringify(this.usuario.endereco.enderecoColeta);
      return endereco == enderecoColeta;

    }
    return false;

  }
  onSubmit(form) {
    // debugger;
    // this.usuarioService.cadastrar(cadastroData).subscribe((data) => {
    //   this.localStorageService.setarUsuario({id: data.id, nome: data.nome, nomeEmpresa: data.nomeEmpresa, email: ''});
    // });
    // this.router.n  avigateByUrl('/cadastro/criar-login');
  }
  salvar(dadosForm) {
    this.isLoading = true;
    this.usuarioService.atualizarDadosCadastrais(this.usuario.id, dadosForm).subscribe( data => {
      if (data.id) {
        this.usuario = data;
        this.localStorageService.setarUsuario(data);
      }
      this.isLoading = false;
      window.scrollTo(0, 0);
    }, (err) => {
      this.isLoading = false;
    });
  }
  desabilitaCamposEndereco() {
    return this.cadastroForm.value.cep != undefined && this.cadastroForm.value.cep != null && this.cadastroForm.value.cep != ''
  }
  desabilitaCamposEnderecoColeta() {
    return this.cadastroForm.value.enderecoColeta != undefined && this.cadastroForm.value.enderecoColeta != null && this.cadastroForm.value.enderecoColeta != ''
  }
  focusOutCepColeta(event) {

  }

  private obterComoConheceu() {
    this.usuarioService.obterOpcoesComoConheceu()
      .subscribe((res) => this.comoConheceuOpcoes = res);
  }
  private obterRepresentante() {
    this.usuarioService.obterOpcoesRepresentantes()
      .subscribe(res => {
        this.representanteOpcoes = res;
        this.loadingSuporte = false;
      }, (err) => {
        this.loadingSuporte = false;
      });
  }
}
