// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: 'http://localhost:4000',
  API_URL: 'https://chegalog-api-homolog.herokuapp.com',
  // API_AWS: 'https://89qdmbzn9f.execute-api.sa-east-1.amazonaws.com/Prod',
  API_AWS: 'https://i320sgeh0m.execute-api.sa-east-1.amazonaws.com/Prod',
  // API_URL: 'https://chegalog-api.herokuapp.com',
  // API_URL_VINDI: 'https://app.vindi.com.br'
  API_URL_VINDI: 'https://sandbox-app.vindi.com.br'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
