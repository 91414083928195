import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UsuarioService } from '../services/usuario/usuario.service';
import { LocalStorageService } from '../services/localStorage/localstorage.service';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {


  constructor(

  ) {

  }

  ngOnInit(): void {
  }


}
