import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorage/localstorage.service';
import { UsuarioService } from '../services/usuario/usuario.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

    public mensagemRetornoFormulario = '';
    public retornoContato: any = {};
    public isLoadingContato = false;
    public contatoForm;
    public navbarOpen = false;

  constructor(
    private storage: LocalStorageService,
    private usuarioService: UsuarioService,
    private formBuilder: FormBuilder,
    private router: Router,
    private localStorage: LocalStorageService,
    ) {
        this.contatoForm = this.formBuilder.group({
          nome: ['', Validators.required],
          email: ['',[
              Validators.required,
              Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]
          ],
          celular: ['', Validators.required],
          mensagem: ['', Validators.required],
        });
    }

  ngOnInit() { }

  simular() {
    let user = this.storage.obterUsuario();
    if (user) {
        this.router.navigateByUrl('/simulacao/file');
    } else {
        this.router.navigateByUrl('/cadastro/dados-empresa');
    }
  }

  switchSlide(i){}

  login() {
    this.router.navigateByUrl('/cadastro/login');
  }

  onSubmit(data) {
    this.isLoadingContato = true;
    this.usuarioService.contato(data).subscribe(retorno => {
        this.retornoContato = retorno;
        if (retorno.success) {
            this.mensagemRetornoFormulario = 'Simule agora mesmo o seu frete, é Grátis...';
        } else {
            this.mensagemRetornoFormulario = retorno.error;
        }
        this.isLoadingContato = false;
    }, (error) => {
        this.isLoadingContato = false;
    });
  }

  faq() {
    this.router.navigate(['/faq']);
  }






}
