import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { UtilsService } from './../utils/utils.service';

import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FreteService {
  private environment = environment;

  constructor(
    private readonly http: HttpClient,
    private readonly utilsService: UtilsService
  ) {}

  public async calcularFrete(produtoId = 2677, tipoProduto, altura, comprimento, largura, quantidade, cep, peso, valor, pesoCubico) {
    let url = `${this.environment.API_AWS}/api/Shipping/GetShipping?produtoId=${produtoId}&Description=${tipoProduto}&Altura=${altura}&Comprimento=${comprimento}&Largura=${largura}&Amount=${quantidade}&ZipCode=${cep}&Weight=${peso}&Value=${valor}&CubedWeight=${pesoCubico}`;

    return await this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() }).toPromise();
  }

  public listarPedidos(): Observable<any> {
    let url = `${this.environment.API_AWS}/api/Shipping/ListAllShipping`;

    return this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() });
  }

  public listarPedidosPendentes(skip, take): Observable<any> {
    let url = `${this.environment.API_AWS}/api/Shipping/resumo?skip=${skip}&take=${take}`;
    //let url = `https://localhost:63623/api/Shipping/resumo?skip=${skip}&${take}`;

    return this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() });
  }

  public listarOcorrenciasPedido(freteid, nfe): Observable<any> {
    let url = `${this.environment.API_AWS}/api/Shipping/OcorrenciasFrete?freteId=${freteid}&nfe=${nfe}`;
    return this.http.get<any>(url, { headers: this.utilsService.obterHeaderAuthorization() });
  }

  public listarOrigens(): Observable<any> {
    let url = `${this.environment.API_AWS}/api/Frete/origem`;
    // let url = `http://localhost:3000/api/Frete/origem`;

    return this.http.get<any>(url);
  }

  public listarDestino(): Observable<any> {
    let url = `${this.environment.API_AWS}/api/Frete/destino`;
    // let url = `http://localhost:3000/api/Frete/destino`;

    return this.http.get<any>(url);
  }

  public obterAbrangencia(ufOrigem, ufDestino, regiao, cidade, origem): Observable<any> {
    let url = `${this.environment.API_AWS}/api/Frete/abrangencias/${ufOrigem}/${ufDestino}/${regiao}/${cidade}?origem=${origem}`;

    return this.http.get<any>(url);
  }

  public uploadPorFrete(files: Set<File>, freteId): { [key: string]: { progress: Observable<number> } } {
    const status: { [key: string]: {
      progress: Observable<number>,
      doc?: any,
      duplicada?: boolean,
      isMesmoCNPJ?: boolean,
      _id?: string,
      tipo?: string,
      idArquivo?: string
    }} = {};

    files.forEach(file => {
      const formData: FormData = new FormData();
      formData.append('file', file);

      const req = new HttpRequest('POST', `${this.environment.API_AWS}/api/Shipping/file/${freteId}`, formData, {
        headers: this.utilsService.obterHeaderAuthorization(true),
        reportProgress: true,
      });

      const progress = new Subject<number>();
      this.http.request<any>(req).subscribe((event :any) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          progress.complete();
        }
      });

      status[file.name] = { progress: progress.asObservable() };
    });

    return status;
  }

  public uploadSimulacao(files: Set<File>, idUsuario, isCotacao = true): { [key: string]: { progress: Observable<number> } } {
    // this will be the our resulting map
    const status: { [key: string]: {
      progress: Observable<number>,
      doc?: any,
      duplicada?: boolean,
      isMesmoCNPJ?: boolean,
      id?: string,
      tipo?: string,
      idArquivo?: string,
      msg?: string
    }} = {};

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', `${this.environment.API_AWS}/api/Shipping/GetShippingByFile`, formData, {
      // const req = new HttpRequest('POST', `http://localhost:3000/api/Shipping/GetShippingByFile`, formData, {
        headers: this.utilsService.obterHeaderAuthorization(true),
        reportProgress: true,
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      const startTime = new Date().getTime();
      this.http.request<any>(req).subscribe((event :any) => {
        if (event.type === HttpEventType.UploadProgress) {
          // calculate the progress percentage
          const percentDone = Math.round((100 * event.loaded) / event.total);
          // pass the percentage into the progress-stream
          console.log("...1");
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          status[event.body.fileName].doc = event.body.obj; // event.body.doc;
          status[event.body.fileName].duplicada = false; //event.body.chaveDuplicada ? event.body.chaveDuplicada : false;
          //status[event.body.fileName].isMesmoCNPJ = true; //event.body.isMesmoCNPJ;
          status[event.body.fileName].isMesmoCNPJ = event.body.value;
          status[event.body.fileName].msg = event.body.message;
          status[event.body.fileName].id = event.body.obj?.id;
          status[event.body.fileName].tipo = event.body.tipo;
          status[event.body.fileName].idArquivo = event.body.obj?.idArquivo;

          progress.complete();
        }
      });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable(),
        // data: {}
      };
    });

    // return the map of progress.observables
    return status;
  }
}
