<div>
    <div class="container">
        <div class="pt-5"></div>

        <p class="h5 mb-4 ml-3 text-center">Declaração de conteúdo </p>

        <!-- COLLAPSE 1 -->
        <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <p class="mb-0">
                    <strong>Dados do destinatário</strong>
                </p>
                <button
                    class="btn"
                    type="button"
                    (click)="expandirCollapse1()">
                    <i class="material-icons">
                        {{ expandidoCollapse1 ? 'keyboard_arrow_down' : 'expand_less' }}
                    </i>
                </button>
              </div>

              <div
                *ngIf="expandidoCollapse1"
                class="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample">

                <div class="card-body">
                    <form
                        class="mx-auto"
                        [formGroup]="declaracoConteudoForm"
                        (onSubmit)="onSubmit(declaracoConteudoForm.value)">

                        <div>
                            <div class="form-group">
                                <label for="nome">Nome *</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="nome"
                                    placeholder="Nome"
                                    formControlName="nome">
                            </div>

                            <div class="row">

<!--
                                <div class="col" *ngIf="declaracoConteudoForm.value.tipoDocumentoCliente == 'CPF'">
                                    <div class="form-group">
                                        <label for="cpf">CPF *</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="cpf"
                                            placeholder="000.000.000-00"
                                            formControlName="cpfCnpj"
                                            mask="000.000.000-99"
                                        >
                                    </div>
                                </div> -->

                                <div class="col-12 col-sm-6" >
                                    <div class="form-group">
                                        <label for="cnpj">CPF ou CNPJ *</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="cnpj"
                                            placeholder="Digite o CPF ou CNPJ completo"
                                            formControlName="cpfCnpj"
                                            mask="000.000.000-00||00.000.000/0000-00">
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="telefone">Telefone *</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="telefone"
                                            placeholder="Telefone"
                                            formControlName="telefone"
                                            mask="(00) 000000009"
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-sm-3">
                                    <div class="form-group">
                                        <label for="cep">CEP *</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="cep"
                                            mask="00000-000"
                                            placeholder="CEP"
                                            formControlName="cep"
                                            (blur)="focusOutCep($event)">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                      <label for="endereco">Endereço *</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="endereco"
                                        placeholder="Av, Rua, Viela, etc"
                                        formControlName="endereco">
                                    </div>
                                </div>
                                <div class="col-12 col-sm-2">
                                    <div class="form-group">
                                      <label for="numero">Número *</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="numero"
                                        placeholder="Nº"
                                        formControlName="numero">
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <div class="form-group">
                                        <label for="complemento">Complemento</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="complemento"
                                            placeholder="Ex: Casa B, Apt 28"
                                            formControlName="complemento">
                                      </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                      <label for="bairro">Bairro *</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="bairro"
                                        placeholder="Bairro"
                                        formControlName="bairro"
                                      >
                                    </div>
                                </div>

                                <div class="col-12 col-sm-4">
                                    <div class="form-group">
                                        <label for="cidade">Cidade *</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="cidade"
                                            placeholder="Cidade"
                                            formControlName="cidade">
                                      </div>
                                </div>
                                <div class="col-12 col-sm-2">
                                    <div class="form-group">
                                        <label for="estado">Estado *</label>
                                        <select
                                            id="estado"
                                            class="form-control"
                                            name="estado"
                                            formControlName="estado">
                                            <option value="AC">AC</option>
                                            <option value="AL">AL</option>
                                            <option value="AP">AP</option>
                                            <option value="AM">AM</option>
                                            <option value="BA">BA</option>
                                            <option value="CE">CE</option>
                                            <option value="DF">DF</option>
                                            <option value="ES">ES</option>
                                            <option value="GO">GO</option>
                                            <option value="MA">MA</option>
                                            <option value="MT">MT</option>
                                            <option value="MS">MS</option>
                                            <option value="MG">MG</option>
                                            <option value="PA">PA</option>
                                            <option value="PB">PB</option>
                                            <option value="PR">PR</option>
                                            <option value="PE">PE</option>
                                            <option value="PI">PI</option>
                                            <option value="RJ">RJ</option>
                                            <option value="RN">RN</option>
                                            <option value="RS">RS</option>
                                            <option value="RO">RO</option>
                                            <option value="RR">RR</option>
                                            <option value="SC">SC</option>
                                            <option value="SP">SP</option>
                                            <option value="SE">SE</option>
                                            <option value="TO">TO</option>
                                        </select>
                                      </div>
                                </div>

                            </div>
                            <div class="form-group">
                                <label for="obs">Observações</label>
                                <textarea
                                    type="text"
                                    class="form-control"
                                    id="obs"
                                    rows="3"
                                    placeholder="Escreva uma observação"
                                    formControlName="obs">
                                </textarea>
                            </div>
                        </div>
                    </form>
                </div>
              </div>
            </div>
        </div>

        <!-- COLLAPSE 2 -->
        <div class="accordion mt-5" id="accordionExampleTwo">
            <div class="card">
              <div class="card-header" id="headingOne">
                <p class="mb-0">
                    <strong>Identificação dos bens</strong>
                </p>
                <button
                    class="btn"
                    type="button"
                    (click)="expandirCollapse2()">
                    <i class="material-icons">
                        {{ expandidoCollapse2 ? 'keyboard_arrow_down' : 'expand_less' }}

                    </i>
                </button>
              </div>

              <div *ngIf="expandidoCollapse2" aria-labelledby="headingOne" data-parent="#accordionExampleTwo">
                <div class="card-body">
                    <p>Dados da declaração</p>
                    <form class="mx-auto" [formGroup]="declaracoConteudo" (onSubmit)="onSubmit(declaracoConteudo.value)">

                        <div formArrayName="itemRows">
                            <div *ngFor="let itemrow of declaracoConteudo.get('itemRows')['controls'];let i=index" >
                                <div [formGroupName]="i">
                                    <div class="row">
                                        <div class="col-6 col-sm" >
                                            <div class="form-group mb-0">
                                                <label for="item">
                                                    Item (um por vez)

                                                </label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="item"
                                                    placeholder="Item"
                                                    max="100"
                                                    formControlName="item">
                                            </div>
                                        </div>

                                        <div class="col-6 col-sm-2" >
                                            <div class="form-group mb-0">
                                                <label for="valor">
                                                    Valor *
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="valor"
                                                    placeholder="R$"
                                                    max="100"
                                                    formControlName="valor">

                                            </div>
                                        </div>

                                        <div class="col-6 col-sm-3" >
                                            <div class="form-group mb-0">
                                                <label for="qtd">
                                                Quantidade *
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="qtd"
                                                    placeholder="Qtde"
                                                    max="100"
                                                    formControlName="qtd">

                                            </div>
                                        </div>


                                        <div class="align-items-end d-none d-sm-flex " style="width: 80px;">
                                            <i
                                                class="material-icons btn-icon p-1"
                                                *ngIf="i == declaracoConteudo.get('itemRows')['controls'].length - 1"
                                                (click)="addNewRow()">
                                                add
                                            </i>

                                            <i
                                                class="material-icons btn-icon p-1"
                                                *ngIf="i > 0 || declaracoConteudo.get('itemRows')['controls'].length > 1"
                                                (click)="deleteRow(i)">
                                                delete
                                            </i>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />


                    </form>
                </div>
              </div>
            </div>
        </div>

        <!-- COLLAPSE 3 -->
        <div class="accordion mt-5  mb-4" id="accordionExampleThree">
            <div class="card">
              <div class="card-header" id="headingOne">
                <p class="mb-0">
                    <strong>Dados adicionais do remetente</strong>
                </p>
                <button
                class="btn"
                type="button"
                (click)="expandirCollapse3()">
                <i class="material-icons">
                    {{ expandidoCollapse3 ? 'keyboard_arrow_down' : 'expand_less' }}
                </i>
            </button>
              </div>

              <div *ngIf="expandidoCollapse3" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExampleThree">
                <div class="card-body">
                    <form
                        class="mx-auto"
                        [formGroup]="declaracoConteudoForm"
                        (onSubmit)="onSubmit(declaracoConteudoForm.value)">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <label for="telefone">Telefone</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="telefone"
                                        placeholder="Telefone"
                                        formControlName="telefone"
                                        mask="(00) 000000009">
                                    </div>
                            </div>
                        </div>
                    </form>
                    <hr />
                    <span >
                        <strong>Dados do remetente</strong>
                    </span>

                    <div class="dados-remetente mt-2">
                        <div class="dados-info">
                            <span class="d-flex">{{ usuario.nome }}</span>
                            <span class="d-flex">{{ usuario.cnpj | cpfCnpj }}</span>
                            <span class="d-flex">{{ usuario.endereco.endereco }}, {{ usuario.endereco.endereco.numero }}</span>
                            <span class="d-flex">{{ usuario.endereco.complemento }}</span>
                            <span class="d-flex">{{ usuario.endereco.bairro }} - {{ usuario.endereco.cep }}</span>
                            <span class="d-flex">{{ usuario.endereco.cidade }} - {{ usuario.endereco.estado }}</span>
                        </div>

                        <!-- <div class="dados-acao">
                            <button class="btn btn-outline-primary btn-icon px-3 btn-sm mb-2">
                                Editar
                            </button>
                        </div> -->
                    </div>

                </div>
              </div>
            </div>
        </div>

    </div>

</div>

<div class="action-bottom-fixed d-flex">
    <div class="container d-flex">
        <button type="button" class="btn btn-outline-primary" (click)="voltar()">VOLTAR</button>
        <span class="flex-fill"></span>
        <!-- <button type="button" class="btn btn-outline-primary btn-sm mr-3">IMPRIMIR</button> -->
        <button type="button" class="btn btn-primary red btn-sm" (click)="salvarEGerar()" [disabled]="declaracoConteudoForm.valid == false || declaracoConteudo.valid == false" >SALVAR E IMPRIMIR</button>
    </div>
</div>
