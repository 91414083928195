import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../services/localStorage/localstorage.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-simulacao',
  templateUrl: './simulacao.component.html',
  styleUrls: ['./simulacao.component.scss']
})
export class SimulacaoComponent implements OnInit {
  public isLogado;
  public usuario;
  public nomeUsuario;
  public rotaAtual;

  public passos = [
    { nome: 'Carga' },
    // { nome: 'Identificação' },
    { nome: 'Fretes' },
    // { nome: 'Empresa' },
    { nome: 'Pagamento' }
  ];
  public passoAtual = 1;

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    router.events.subscribe( (val: any) => {
      this.passoAtual = val.snapshot?.data?.passo ? val.snapshot?.data?.passo : this.passoAtual;
      var url = val.url || '/simulacao/file';
      this.rotaAtual = url.split("/")[url.split("/").length - 1];
    });
  }

  ngOnInit(): void {
    this.isLogado = this.localStorage.obterUsuario() ? true : false;
    if (this.isLogado) {
      this.usuario = this.localStorage.obterUsuario();
      this.nomeUsuario = this.usuario.nome.split(" ")[0];
    }
  }
  sair() {
    this.localStorage.deslogar();
    this.router.navigateByUrl('/');
  }


}
