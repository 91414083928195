import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorage/localstorage.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.scss']
})
export class EsqueciSenhaComponent implements OnInit {
  public isLoading = false;
  public mensagemRetorno = '';
  public email = '';
  public user;
  public success = false;
  constructor(
    private userService: UsuarioService,
    private storage: LocalStorageService,
    private router: Router
  ) {
    this.user = this.storage.obterUsuario();
  }

  ngOnInit(): void { }
  onSubmit(value) {
    debugger;
  }
  enviar() {
    this.isLoading = true;

      this.userService.recuperarSenhaByEmail(this.email).subscribe(() => {
        this.success = true;
        this.isLoading = false
      });
  }
  continuar() {
    this.router.navigateByUrl('/cadastro/login');
  }

}
