import {Component, Input} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DeclaracaoConteudoService} from './../../services/declaracao-conteudo/declaracao-conteudo.service';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';
import {environment} from './../../../environments/environment';
import {cnpj} from './../../common/services/cpf-cnpj-validator';
import {cpf} from './../../common/services/cpf-cnpj-validator';

export const cpfCnpjValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  var cpfCnpj = control.value.replace(/[^\d]+/g,'');

  if(cpfCnpj == '') return { invalido: true };
  if (!cpf.isValid(cpfCnpj) && !cnpj.isValid(cpfCnpj)) return { invalido: true };

  return null;
};

@Component({
  selector: 'app-dados-destinatario',
  templateUrl: './dados-destinatario.component.html',
  styleUrls: ['./dados-destinatario.component.scss']
})
export class DadosDestinatarioComponent {
  public dadosDestinatarioForm: FormGroup;
  public destinatarioItems: FormArray;
  public dadosCotacoes;
  public isLoading = false;
  public loadingCep = false;
  public usuario;

  get destinatarios(): any {
    return this.dadosDestinatarioForm;
  }
  @Input() set destinatarios(value: any) {
    this.dadosDestinatarioForm = value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private readonly declaracaoConteudoService: DeclaracaoConteudoService,
    private usuarioService: UsuarioService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    const cotacoes = this.localStorageService.obterCotacao();
    //const tipos = [];
    //cotacoes.map( cotacao => {
    //  if (tipos.length == 0) {
    //    tipos.push(cotacao.tipo);
    //  } else {
    //    tipos.map( tipo => {
    //      if (tipo != cotacao.tipo) {
    //        tipos.push(cotacao.tipo);
    //      }
    //    });
    //  }
    //});

    if(cotacoes.length > 0 && cotacoes[0].tipo == "xml") {
      this.router.navigate(['simulacao', 'pagamento']);
    }


    this.destinatarios = this.dadosDestinatarioForm;

    this.usuario = this.localStorageService.obterUsuario();

    this.dadosCotacoes = this.localStorageService.obterCotacao();

    this.dadosDestinatarioForm = this.formBuilder.group({
      destinatarioItems: this.formBuilder.array([])
    });
    this.adicionarDestinatariosFormItems();
  }

  destinatarioFormItem(dadosCotacao): FormGroup {
    let numeroCpfCnpj;
    if (!environment.production) {
      const random = Math.floor(Math.random() * 2) + 1
      numeroCpfCnpj = random === 1 ? cpf.generate() : cnpj.generate();
    }

    return this.formBuilder.group({
      cotacaoId: new FormControl(dadosCotacao._id),
      nome: new FormControl('', Validators.required),
      cpfCnpj: [!environment.production ? numeroCpfCnpj : '', [Validators.required, cpfCnpjValidator]],
      cep: new FormControl(dadosCotacao.CEP ? dadosCotacao.CEP : '', [Validators.required], [this.usuarioService.cepValidator()]),
      logradouro: new FormControl('', Validators.required),
      numero: new FormControl('', Validators.required),
      complemento: new FormControl(''),
      bairro: new FormControl('', Validators.required),
      cidade: new FormControl('', Validators.required),
      uf: new FormControl('', Validators.required)
    });
  }

  adicionarDestinatariosFormItems() {
    this.dadosCotacoes.forEach(dadoCotacao => {
      const destinatario = this.dadosDestinatarioForm.get('destinatarioItems') as FormArray;
      const formItem = this.destinatarioFormItem(dadoCotacao);
      destinatario.push(formItem);

      this.procurarEndereco(dadoCotacao.CEP, formItem);
      if (!environment.production) {
        this.destinatarioFormItemMock(formItem);
      }
    });
  }

  onSubmit(dadosForm) {
    this.declaracaoConteudoService.salvarDestinatario(this.criarDadosDestinatariosRequest(dadosForm))
      .subscribe(() => this.router.navigateByUrl('/simulacao/pagamento'));
  }

  private criarDadosDestinatariosRequest(dadosForm) {
    const dadosDestinatarios = [];
    dadosForm.destinatarioItems.forEach(destinatarioItem => {
      const dadosColetas = this.localStorageService.obterColetas();
      const freteId = dadosColetas.find(coleta => coleta._id === destinatarioItem.cotacaoId).id;

      const destinatario = {
        nome: destinatarioItem.nome,
        logradouro: destinatarioItem.logradouro,
        numero: destinatarioItem.numero,
        complemento: destinatarioItem.complemento,
        bairro: destinatarioItem.bairro,
        cidade: destinatarioItem.cidade,
        uf: destinatarioItem.uf,
        cep: destinatarioItem.cep,
        cpfCnpj: destinatarioItem.cpfCnpj,
        freteId: freteId
      }

      dadosDestinatarios.push(destinatario);
      this.setarEnderecoDestinatario(destinatarioItem, freteId);
    });

    return dadosDestinatarios;
  }

  private setarEnderecoDestinatario(dadosDestinatarios, freteId) {
    const dadoCotacao = this.dadosCotacoes.find(cotacao => cotacao._id === dadosDestinatarios.cotacaoId);

    dadoCotacao['freteId'] = freteId;
    dadoCotacao['cotacaoId'] = dadosDestinatarios.cotacaoId;
    dadoCotacao['nome'] = dadosDestinatarios.nome;
    dadoCotacao['cpfCnpj'] = dadosDestinatarios.cpfCnpj;
    dadoCotacao['destinatario'] = {
      cep: dadosDestinatarios.cep,
      logradouro: dadosDestinatarios.logradouro,
      numero: dadosDestinatarios.numero,
      complemento: dadosDestinatarios.complemento,
      bairro: dadosDestinatarios.bairro,
      cidade: dadosDestinatarios.cidade,
      uf: dadosDestinatarios.uf
    };

    this.localStorageService.setarCotacao(this.dadosCotacoes)
    this.dadosCotacoes = this.localStorageService.obterCotacao();
  }

  procurarEndereco(cep, item) {
    this.loadingCep = true;
    this.usuarioService.buscarEnderecoByCEP(cep).subscribe(res => {
      item.controls['logradouro'].setValue(res.street);
      item.controls['bairro'].setValue(res.neighborhood);
      item.controls['cidade'].setValue(res.city);
      item.controls['uf'].setValue(res.state);
      this.loadingCep = false;
    }, (erro) => {
      this.loadingCep = false;
    });
  }

  private destinatarioFormItemMock(formItem) {
    const random = Math.floor(Math.random() * 100) + 1;
    const numeroCpfCnpj = random <= 49 ? cpf.generate() : cnpj.generate();

    formItem.patchValue({
      cpfCnpj: numeroCpfCnpj
    });
  }
}
