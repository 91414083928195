<section class="hero">
    <!-- <header *ngIf="!isEmbeded">
        <nav class="navbar py-4">
            <div class="container container-header">

                <div class="mr-3">
                    <a href="/">
                        <img src="/assets/images/LOGOTIPO-POT-SPEED-1@2x.png" height="50">
                    </a>
                </div>

                <span class="mr-auto"></span>
                <span class="links">
                    <button type="button" class="btn btn-outline-primary d-none d-lg-block">
                        Cliente ChegaLog
                    </button>
                    <button type="button" class="btn btn-primary d-none d-lg-block" (click)="simular()">
                        SIMULE SUA COLETA
                    </button>
                </span>
            </div>
        </nav>
    </header> -->
    <header *ngIf="!isEmbeded" class="fixed-top shadow-sm" style="background: #fff;">
        <nav class="navbar navbar-expand-xl py-3">
            <div class="container-fluid container-header">

                <div class="mr-3">
                    <a href="/">
                        <img src="/assets/images/chegalog.svg" height="50">
                    </a>
                </div>

                <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'show': navbarOpen}">
                    <span class="links">
                        <a [routerLink]="['']" fragment="servicos" class="mx-3">Serviços</a>
                        <a [routerLink]="['']" fragment="como-funciona" class="mx-3">Como Funciona</a>
                        <a [routerLink]="['']" fragment="areaAtuacao" class="mx-3">Área de Atuação</a>
                        <a [routerLink]="['']" fragment="conheca" class="mx-3">Sobre</a>
                        <a href="#perguntas" routerLink="/faq" class="mx-3">Dúvidas</a>
                    </span>

                    <span class="mr-auto"></span>

                    <span class="links">
                        <a routerLink="/rastreamento" class="mx-3">Rastrear Encomenda</a>
                    </span>

                    <span class="links">
                        <button type="button" (click)="login()" class="btn btn-outline-primary btn-md">
                            Entrar
                        </button>
                        <button type="button" class="btn btn-primary btn-md" (click)="simularColeta()"
                            id="simularColeta">
                            Simule seu frete
                        </button>
                    </span>
                </div>
            </div>
        </nav>
    </header>
    <div class="mt-4 pt-5" *ngIf="!importado"></div>


</section>


<section>
    <div class="py-5 header-busca" *ngIf="!importado">
        <div class="container">
            <div class="row align-items-center">
                <div class="col">
                    <div class="text-center">
                        <h2>Acompanhe a entrega do seu pedido</h2>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-12 col-lg-6 text-center">
                            <form class="form-inline d-sm-flex">
                                <div class="form-group mx-sm-3 mr-3 mb-2 flex-fill">
                                    <label for="pesquisa" class="sr-only">Pesquisar</label>
                                    <input type="text" class="form-control pesquisa" id="pesquisa"
                                        placeholder="Informe o CNPJ ou o CPF do destinatário" [(ngModel)]="pesquisa"
                                        [ngModelOptions]="{standalone: true}">
                                </div>
                                <button type="submit" class="btn btn-primary mb-2 flex-fill flex-sm-grow-0"
                                    (click)="pesquisar()" [disabled]="pesquisa.length == 0">PROCURAR</button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-5 container-rastreamento">
        <div class="container">
            <div class="text-center" *ngIf="isLoading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="text-center p-3" *ngIf="nenhumPedido">
                <div class="d-flex justify-content-center text-danger">
                    <i class="material-icons mr-2">warning</i>
                    <p>Nenhum pedido para o CNPJ/CPF informado</p>

                </div>
            </div>
            <!-- <pre>{{ pedido | json}}</pre> -->
            <!-- <div class="d-block d-sm-none border p-3 rounded" *ngIf="pedido.destinatario">
                <div class="mb-2">
                    <p class="m-0"><strong>Nome</strong></p>
                    <span>{{ pedido.destinatario }}</span>
                </div>
                <div class="mb-2">
                    <p class="m-0"><strong>Localização</strong></p>
                    <span>{{ pedido.rastreamento[0].local }}</span>
                </div>
                <div class="mb-2">
                    <p class="m-0"><strong>Previsão da entrega</strong></p>
                    <span>{{ pedido.data_prevista }}</span>
                </div>
                <div class="mb-2">
                    <p class="m-0"><strong>Ação</strong></p>
                    <a href="javascript:void(0);">Ver detalhes</a>
                </div>
            </div> -->
            <div class="tabela-rastreamento" *ngIf="pedidos.length > 0">
                <table>
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col" class="text-center">Situação</th>
                            <!-- <th scope="col">Localização</th> -->
                            <th scope="col" class="text-right">Previsão da entrega</th>
                            <th scope="col" class="text-right">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pedido of pedidos">
                            <td>{{ pedido.destinatario }}</td>
                            <td class="text-center">{{ pedido.situacao }}</td>
                            <!-- <td>{{ pedido.rastreamento[0].local }}</td> -->
                            <td class="text-right">{{ pedido.data_prevista }}</td>
                            <td class="text-right">
                                <a href="javascript:void(0);" (click)="verDetalhe(pedido.rastreamento)"
                                    tooltip="Vizualizar">
                                    <i class="material-icons">remove_red_eye</i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="text-center mt-5" *ngIf="!isLoading && isLoadingRastreamento">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="!isLoadingRastreamento">
                <div class="col-12 col-sm-5">
                    <div class="timeline-rastreamento">
                        <div class="item" *ngFor="let item of rastreamento"
                            [ngClass]="{'success': item.ocorrencia == 'Pedido entregue com sucesso!!! Até a próxima'}">
                            <div class="header">
                                <p class="text-primary m-0 titulo"
                                    [ngClass]="{'text-success': item.ocorrencia == 'Pedido entregue com sucesso!!! Até a próxima'}">
                                    {{ item.ocorrencia }}</p>
                                <p class="m-0">{{ item.obs }}</p>
                                <small style="color: #373A3C; font-size: 12px; opacity: .54;">{{ item.data_cadastro }} -
                                    {{ item.local }}</small>
                            </div>
                            <!-- <div class="conteudo mt-4">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <p class="m-0"><strong>São Paulo</strong></p>
                                        <p class="m-0">São Paulo Via Transversal Sul, 160 Osasco - Novo Osasco</p>
                                    </div>
                                </div>
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</section>



<app-footer *ngIf="!importado"></app-footer>