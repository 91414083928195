
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DadosEmpresaComponent } from './dados-empresa/dados-empresa.component';
import { LoginComponent } from './login/login.component';
import { CadastroComponent } from './cadastro.component';
import { AuthGuardService as AuthGuard } from '../guards/auth-guard.service';
import { EsqueciSenhaComponent } from './esqueci-senha/esqueci-senha.component';

const routes: Routes = [
    {
        path: 'cadastro', component: CadastroComponent,
        children: [
            {   path: 'dados-empresa', component: DadosEmpresaComponent },
            {   path: 'login',   component: LoginComponent },
            {   path: 'esqueci-senha',   component: EsqueciSenhaComponent },
        ]
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CadastroRoutingModule { }