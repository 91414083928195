import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RastreamentoService } from '../services/rastreamento/rastreamento.service';
import { LocalStorageService } from '../services/localStorage/localstorage.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  public navbarOpen = false;
  constructor(
    private router: Router,
    private rastreamentoService: RastreamentoService,
    private localStorage: LocalStorageService,
    private activatedRoute: ActivatedRoute
    ) {

    }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
  simular() {
    let user = this.localStorage.obterUsuario();
    if (user) {
      this.router.navigateByUrl('/simulacao/dados-coleta');
    } else {
      this.router.navigateByUrl('/cadastro/dados-empresa');
    }
  }
  login() {
    this.router.navigateByUrl('/cadastro/login');
  }

  contato() {
    this.router.navigate(['/contato']);
  }
}
