<div class="container mb-5 pb-5 mb-sm-0 pb-sm-0">
    <!-- <pre>{{ frete | json }}</pre> -->
    <form [formGroup]="cartaoForm" (ngSubmit)="onSubmit(cartaoForm.value)" class="mb-5 pb-5 mb-sm-0 pb-sm-0">

        <div class="text-center pt-0 pt-sm-5"></div>
        <div class="row mb-5" *ngIf="!pagamentoRealizado">
            <div class="col-12 col-sm-8" [ngClass]="{'mx-auto': pagamentoRealizado}">
                <div class="" [ngClass]="{'justify-content-center  d-flex mb-4': pagamentoRealizado}">
                    <div class="selo-ambiente-seguro">
                        <i class="material-icons">https</i>
                        <div class="conteudo-texto">
                            <span class="font-weight-bold">Ambiente seguro</span>
                            <small>Este é um ambiente seguro utilizando <span class="text-success">certificado de
                                    segurança</span>, <a href="https://www.certisign.com.br/certificado-digital"
                                    target="_blank">entenda</a></small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!pagamentoRealizado">
            <div class="col text-center">
                <p class="titulo-paginas">Escolha a forma de pagamento</p>
            </div>
        </div>

        <div class="text-center d-block d-sm-none mb-3">
            <div class="d-flex align-items-center justify-content-center">
                <img src="assets/images/bandeira-visa.svg" width="45" height="35" class="mx-1">
                <img src="assets/images/bandeira-mastercard.svg" width="45" height="35" class="mx-1">
                <img src="assets/images/bandeira-hipercard.png" width="45" height="35" class="mx-1">
                <img src="assets/images/ico-elo.svg" class="mx-1">
            </div>
        </div>

        <div class="row">
            <div class="col-12 " [ngClass]="{'mx-auto': pagamentoRealizado, 'col-sm-8': !pagamentoRealizado}">

                <ng-container *ngIf="pagamentoRealizado">
                    <div class="alert alert-success text-center" role="alert">
                        Pagamento realizado com sucesso!
                    </div>
                    <div class="mt-4">
                        <div class="text-center">
                            <p *ngIf="frete.tipoDocumentoFiscal == 'declaracao' ">A partir de agora você já pode
                                imprimir sua etiqueta e realizar o envio do seu produto.</p>
                            <p *ngIf="frete.tipoDocumentoFiscal != 'declaracao' ">A partir de agora você já pode
                                vizualizar o seu pedido no painel.</p>
                        </div>

                        <div class="container-info-pedido d-sm-flex">
                            <div class="ml-2 mr-4">
                                <img src="assets/images/delivery-fast.svg" />
                            </div>

                            <div class="ml-2 mr-4">
                                <p class="m-0">Data do pedido</p>
                                <small><strong>{{ dataPedido | date : 'shortDate' }}</strong></small>
                            </div>

                            <div class="ml-2 mr-4">
                                <p class="m-0">Prazo</p>
                                <small><strong>{{ coletas[0].prazo }} úteis</strong></small>
                            </div>

                            <div class="ml-2 mr-4">
                                <p class="m-0">Valor</p>
                                <small><strong>{{ total | currency : 'BRL' }}</strong></small>
                            </div>

                            <span class="flex-fill"></span>

                            <button class="btn btn-outline-primary btn-icon px-3 mb-2 btn-sm" (click)="goPainel()">
                                Painel
                            </button>

                            <button class="btn btn-outline-primary btn-icon px-3 mb-2 btn-sm ml-2"
                                *ngIf="frete.tipoDocumentoFiscal == 'declaracao' && dadosFrete?.destinos.length == 1"
                                (click)="abrirDeclaracaoConteudo()">
                                <i class="material-icons">description</i>
                                Declaração de conteúdo
                            </button>
                        </div>

                        <ng-container *ngIf="usuario.tipoCliente =='pegaki' ">
                            <div class="container-header-pontos mt-5">
                                <div class="ml-2 mr-4">
                                    <p class="m-0"><strong>Pontos de coleta</strong></p>
                                </div>

                                <span class="flex-fill"></span>

                                <!-- <button
                                    class="btn btn-outline-primary btn-icon px-3 mb-2 btn-sm ml-2"
                                    (click)="abrirModalImprimirEtiqueta(temmplateModalEtiqueta);$event.stopPropagation();"
                                    *ngIf="dadosFrete?.destinos.length == 1">
                                    <i class="material-icons">print</i>
                                    Emprimir etiqueta
                                </button> -->

                                <div class="py-3 col-2">
                                    <p class="m-0"><strong>Zona:</strong></p>
                                    <div class="form-group">
                                        <select class="form-control" id="zona" placeholder="Selecione"
                                            [(ngModel)]="zona" (change)="onChangeSelect($event)"
                                            [ngModelOptions]="{standalone: true}">
                                            <option value="Norte">Norte</option>
                                            <option value="Sul">Sul</option>
                                            <option value="Leste">Leste</option>
                                            <option value="Oeste">Oeste</option>
                                            <option value="Centro">Centro</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div class="sub-container-info-pedido">
                                <div class="ml-2">
                                    <p>Imprima sua etiqueta, cole no pacote e leve até a unidade de coleta de sua
                                        preferência para fazer a postagem.</p>

                                    <small class="d-flex mt-2" *ngFor="let ponto of pontosPegaki">
                                        <i class="material-icons mr-2">room</i>
                                        <strong>
                                            {{ ponto.CIDADE }} - {{ ponto.LOGRADOURO }}, {{ ponto.NUMERO }} - {{
                                            ponto.BAIRRO }} - {{ ponto.CEP }}
                                        </strong>
                                    </small>
                                    <!-- <small class="d-flex mt-2">
                                        <i class="material-icons mr-2">room</i>
                                        <strong>
                                            São Paulo - Av. Jaguaré, 325
                                        </strong>
                                    </small> -->

                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <div class="accordion" id="accordionExample" *ngIf="!pagamentoRealizado">
                    <div class="card">
                        <div class="card-header d-flex" id="headingOne" (click)="radioModel = 0" aria-expanded="true"
                            aria-controls="collapseOne">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline1" name="customRadioInline1"
                                    class="custom-control-input" [value]="0" [(ngModel)]="radioModel"
                                    [ngModelOptions]="{standalone: true}">
                                <label class="custom-control-label" for="customRadioInline1"></label>
                            </div>
                            <p class="mb-0 w-100">
                                Cartão de crédito
                            </p>
                            <div class="d-none d-sm-flex">
                                <img src="assets/images/bandeira-visa.svg" width="45" height="35" class="mx-1">
                                <img src="assets/images/bandeira-mastercard.svg" width="45" height="35" class="mx-1">
                                <img src="assets/images/bandeira-hipercard.png" width="45" height="35" class="mx-1">
                                <img src="assets/images/ico-elo.svg" class="mx-1">
                            </div>

                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                            data-parent="#accordionExample" [collapse]="radioModel != 0" [isAnimated]="true">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="nomeCartao">Nome do cartão *</label>
                                            <input type="text" class="form-control" id="nomeCartao"
                                                placeholder="Nome do cartão" formControlName="nomeCartao" required
                                                [ngClass]="{ 'is-invalid': cartaoForm.controls.nomeCartao.errors && (cartaoForm.controls.nomeCartao.dirty || cartaoForm.controls.nomeCartao.touched) }">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="numeroCartao">Nº do cartão *</label>
                                            <input type="text" class="form-control" id="numeroCartao"
                                                mask="0000 0000 0000 0000" placeholder="9999.9999.9999.9999"
                                                formControlName="numeroCartao" autocomplete="off" required
                                                [ngClass]="{ 'is-invalid': cartaoForm.controls.numeroCartao.errors && (cartaoForm.controls.numeroCartao.dirty || cartaoForm.controls.numeroCartao.touched) }">
                                            <div class="invalid-feedback">
                                                Preencha um número de cartão válido
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <div class="form-group mb-0">
                                            <label for="bandeira">Bandeira *</label>
                                            <select class="form-control" id="bandeira" formControlName="bandeira"
                                                placeholder="Selecione" required
                                                [ngClass]="{ 'is-invalid': cartaoForm.controls.bandeira.errors && (cartaoForm.controls.bandeira.dirty || cartaoForm.controls.bandeira.touched) }">
                                                <option value="mastercard">Mastercard</option>
                                                <option value="visa">Visa</option>
                                                <option value="elo">Elo</option>
                                                <option value="hipercard">Hipercard</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm">
                                        <div class="form-group">
                                            <label for="vencimento">Validade *</label>
                                            <input type="text" class="form-control" id="vencimento"
                                                placeholder="MÊS/ANO" [dropSpecialCharacters]="false" mask="00/0000"
                                                formControlName="vencimento" required
                                                [ngClass]="{ 'is-invalid': cartaoForm.controls.vencimento.errors && (cartaoForm.controls.vencimento.dirty || cartaoForm.controls.vencimento.touched) }">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="cvv">CVV *</label>
                                            <input type="text" class="form-control" id="cvv" placeholder="123"
                                                mask="000" formControlName="cvv" required
                                                [ngClass]="{ 'is-invalid': cartaoForm.controls.cvv.errors && (cartaoForm.controls.cvv.dirty || cartaoForm.controls.cvv.touched) }">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="card">
                        <div class="card-header" id="headingTwo" (click)="radioModel = 1;">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input" [value]="1" [(ngModel)]="radioModel">
                                <label class="custom-control-label" for="customRadioInline2"></label>
                            </div>
                            <p class="mb-0">
                                Boleto bancário
                            </p>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample"  [collapse]="radioModel != 1" [isAnimated]="true">
                            <div class="card-body">
                                <p><strong>Você está economizando R$ 2,10 no pagamento em boleto!</strong></p>
                                <p>Boleto tem até 15% de desconto na compra e é a forma de pagamento que recebe o maior desconto sob o valor total da compra. Esta é a forma mais vantajosa para quem deseja pagar à vista. Você poderá efetuar o pagamento do boleto em qualquer Banco ou Casa Lotérica em qualquer lugar do Brasil, sem necessidade de confirmação do pagamento.</p>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="card">
                        <div class="card-header" id="headingThree" (click)="radioModel = 2;">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input" [value]="2" [(ngModel)]="radioModel">
                                <label class="custom-control-label" for="customRadioInline2"></label>
                            </div>
                            <p class="mb-0">
                                Depósito bancário
                            </p>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample" [collapse]="radioModel != 2" [isAnimated]="true">
                        <div class="card-body">
                            Entrar em contato coma  Pot Speed
                        </div>
                        </div>
                    </div> -->
                </div>
                <div class="card mt-3" *ngIf="!pagamentoRealizado">

                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div class="form-group mb-0">
                                    <label for="parcelas">Você pode parcelar em até 3x</label>
                                    <select class="form-control" id="parcelas" formControlName="parcelas"
                                        placeholder="Selecione" required
                                        [ngClass]="{ 'is-invalid': cartaoForm.controls.parcelas.errors && (cartaoForm.controls.parcelas.dirty || cartaoForm.controls.parcelas.touched) }">
                                        <option value="1">1x {{ total | currency : 'BRL' }}</option>
                                        <option value="2">2x {{ (total / 2) | currency : 'BRL' }}</option>
                                        <option value="3">3x {{ (total / 3) | currency : 'BRL' }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col pt-3">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [(ngModel)]="aceiteTermo"
                                        id="aceite" [ngModelOptions]="{standalone: true}">
                                    <label class="custom-control-label" for="aceite">Ao clicar em contratar frete você
                                        concorda com os <a href="javascript:void(0);"
                                            (click)="abrirModalTermo(temmplateModalTermo);$event.stopPropagation();">termos
                                            de uso</a></label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 container-resumo-header" *ngIf="!pagamentoRealizado">


                <div class="container-resumos">

                    <div class="resumo mt-3 mt-sm-0 mb-12 mb-sm-0">

                        <div class="header-resumo">

                            <p><strong>Total de coletas</strong></p>
                            <span style="font-weight: 600;">Origem</span>
                            <div class="row">
                                <div class="col">
                                    Cidade
                                </div>
                                <div class="col text-right">
                                    <strong>{{ usuario.endereco?.cidade }}, {{ usuario.endereco?.estado }}</strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    Total
                                </div>
                                <div class="col text-right">
                                    <strong class="text-primary">{{ total | currency : 'BRL' }}</strong>
                                </div>
                            </div>
                        </div>

                        <div class="expandir-resumo">
                            <div class="header-expandir" (click)="expandido = !expandido">
                                <i class="material-icons mr-1" style="color: #00000080"
                                    *ngIf="!expandido">keyboard_arrow_right</i>
                                <i class="material-icons mr-1" style="color: #00000080"
                                    *ngIf="expandido">keyboard_arrow_down</i>

                                <span class="titulo-header-expandir">Destinos</span>
                                <div>
                                    <img src="assets/images/pin-copy.svg" width="22" height="22">
                                    <span>{{ coletas.length }}</span>
                                </div>
                            </div>
                            <div class="conteudo-expandir" *ngIf="expandido">
                                <div class="item" *ngFor="let coleta of coletas">
                                    <div>
                                        <strong>{{ coleta.cidade }}, {{ coleta.estado }}</strong>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <small class="text-muted">{{ coleta.CEP | mask : '00000-000' }}</small>
                                        <span>{{ coleta.valorFrete.valor | currency: "BRL" }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- <div class="resumo" *ngFor="let coleta of coletas">
                        <div>
                            <p class="m-0"><strong>Coleta:</strong> #XG5GEE</p>
                        </div>
                        <div class="trajeto">
                            <div class="item-trajeto">
                                <div class="header-trajeto">
                                    <div class="endereco">
                                        <span>De <strong>{{ usuario.endereco.cidade }}, {{ usuario.endereco.estado }}</strong></span>
                                    </div>
                                    <div class="text-right">
                                        <strong>{{ coleta.valorFrete.valor | currency: "BRL" }}</strong>
                                    </div>
                                </div>
                                <div>
                                    <span class="cep">{{ usuario.endereco.cep | mask : '00000-000' }}</span>
                                </div>
                            </div>
                            <div class="item-trajeto">
                                <div class="header-trajeto">
                                    <div class="endereco">
                                        <span>Para <strong>{{ coleta.cidade }}, {{ coleta.estado }}</strong></span>
                                    </div>
                                </div>
                                <div>
                                    <span class="cep">{{ coleta.CEP | mask : '00000-000' }}</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </form>
</div>


<div class="action-bottom-fixed d-flex" *ngIf="!pagamentoRealizado">
    <div class="container d-none d-sm-flex">
        <ng-container *ngIf="!pagamentoRealizado">
            <button type="button" class="btn btn-outline-primary" (click)="voltar()">VOLTAR</button>
            <!-- <button type="button" class="btn btn-link-primary" (click)="cancelar()">CANCELAR</button> -->
        </ng-container>
        <ng-container *ngIf="pagamentoRealizado">
            <button type="button" class="btn btn-outline-primary" (click)="voltarSimulacao()">NOVO SIMULAÇÃO</button>
        </ng-container>
        <span class="flex-fill"></span>

        <div class="text-right mr-4" style="line-height: 120%;">
            <span>Total do frete: <strong>{{ total | currency : 'BRL' }}</strong></span><br />
            <small style="color: #373A3C;"><i>Pagar em {{ cartaoForm.value.parcelas }}x {{ (total /
                    cartaoForm.value.parcelas) | currency: 'BRL' }}</i></small>
        </div>
        <div>
            <button id="idContratarPagamento" class="btn btn-primary btn-lg red"
                [disabled]="!cartaoForm.valid || isLoading || pagamentoRealizado || !aceiteTermo"
                (click)="pagar(template)">
                Contratar frete
            </button>
        </div>
    </div>
    <div class="container align-items-center d-block d-sm-none">

        <div class="row align-items-end">
            <div class="col pr-0">
                <span style="font-size: 14px;">Total do frete:</span><br />
                <small style="color: #373A3C;"><i>(Pagar em {{ cartaoForm.value.parcelas }}x {{ (total /
                        cartaoForm.value.parcelas) | currency: 'BRL' }}</i>)</small>
            </div>
            <div class="col text-right">
                <strong class="h4">{{ total | currency : 'BRL' }}</strong>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col">
                <button type="button" class="btn btn-outline-primary" (click)="voltar()">VOLTAR</button>
            </div>
            <div class="col text-right pl-0">
                <button id="idContratarPagamento" class="btn btn-primary red"
                    [disabled]="!cartaoForm.valid || isLoading || pagamentoRealizado || !aceiteTermo"
                    (click)="pagar(template)">
                    Contratar frete
                </button>
            </div>
        </div>

    </div>
</div>

<ng-template #template>
    <div class="modal-body py-4">
        <button type="button" class="close pull-right botao-fechar-modal" aria-label="Close" (click)="modalRef.hide()"
            *ngIf="mensagemErro.length > 0">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="my-5">
            <ng-container *ngIf="isLoading">
                <p class="text-center mb-4"><strong>Estamos gerando o seu pagamento, aguarde...</strong></p>
                <div class="text-center">
                    <div class="spinner-border text-primary" role="status" style="width: 40px; height: 40px;">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="mensagemErro.length > 0">
                <div class="text-center mb-3">
                    <img src="assets/images/c-warning.svg" width="58px">
                </div>
                <p class="text-center text-danger mb-4"><strong>{{ mensagemErro }}</strong></p>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #temmplateModalTermo>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Termos de uso</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-termo></app-termo>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modalRef.hide()">OK</button>
    </div>
</ng-template>


<ng-template #temmplateModalEtiqueta>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Imprimir documento</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Antes de imprimir, verifique se todos os dados estão corretos e confira a política da transportadora.
            Após a impressão você precisa colar a etiqueta na carga.
        </p>

        <!-- <a routerLink="/cliente/painel">Revisar documento</a> -->
    </div>
    <div class="modal-footer">
        <span class="flex-fill"></span>
        <button type="button" class="btn btn-primary-outline" (click)="modalRef.hide()">CANCELAR</button>
        <button type="button" class="btn btn-primary" (click)="imprimir()">IMPRIMIR</button>
    </div>
</ng-template>
