import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorage/localstorage.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private isAuthenticated: boolean = false;

  constructor(
    private localStorage: LocalStorageService,
    private router: Router
    ) {
      let user = this.localStorage.obterUsuario();
      if (user != null) {
        this.isAuthenticated = true;
      } else {
        this.router.navigateByUrl('/');
        this.isAuthenticated = false;
      }
  }

  canActivate() {
    // if (this.isAuthenticated) {
    //   this.router.navigateByUrl('/simulacao/dados-coleta');
    // }
    return this.isAuthenticated;
  }
}
