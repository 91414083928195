import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../services/localStorage/localstorage.service';
import { UsuarioService } from '../services/usuario/usuario.service';
import { Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { initTimestamp } from 'ngx-bootstrap/chronos/units/timestamp';
@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit{
  public user;
  public nomeUsuario;
  public comercial = {
    nome: '',
    telefone: ''
  }
  constructor(
    private storage: LocalStorageService,
    private usuarioService: UsuarioService,
    private router: Router,

    ) {
      this.user = this.storage.obterUsuario();
      this.nomeUsuario = this.user.nome.split(" ")[0];
      // override the route reuse strategy
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
      };
  }
  ngOnInit(): void {
    this.obterUsuario();
  }
  sair() {
    this.storage.deslogar();
    this.router.navigateByUrl('/');
  }
  novaCotacao() {
    this.router.navigate(['/simulacao/file'])
  }
  obterUsuario() {
    this.usuarioService.obterUsuario(this.user._id).subscribe( async (res) => {
      if (res.nome) {
        this.user = res;
        this.storage.setarUsuario(res);
      }

      if (res.comoConheceu?.toLowerCase() != "representante") {
        var todosRepresentantes = await this.usuarioService.obterSuporteComercial().toPromise();
        var representanteDefault = todosRepresentantes.filter( rep => {
          return rep.status == 'default'
        })[0];
        this.comercial = representanteDefault
      } else {
        this.comercial = res.comercial;
      }
    });
  }
}
