import { UtilsService } from './../../services/utils/utils.service';
import { FreteService } from './../../services/frete/frete.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/app/services/localStorage/localstorage.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { forkJoin, of } from 'rxjs';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss']
})
export class PedidosComponent implements OnInit {
  public totalAguardandoXML = [];
  public nomeUsuario;
  public user;
  public totalPendentes = 0;
  public totalEntregues = 0;
  public mostrarDetalhe = false;
  public ordensFaturadas = [];
  public pedidosEntregues : any[] = [];
  public chaves: any[] = [];
  public carregandoPedidosPendentes = true;
  public listaHistorico = [];
  public loadingHistorico = true;
  public isLoading = true;
  public arquivosEfetivados = [];
  public totalItems = [];
  public pedidosPendentes = [];
  public mostrandoPendentes = false;
  public mostrandoCancelados = false;
  public mostrandoEntregues = false;
  public itensCancelados = [];
  public totalCancelados = 0;
  public totalAguardandoProcessamento = 0;
  public totalFaturadas = 0;
  public totalItensTabela = 0;
  public totalNotasPendentes = 0;
  public modalRef: BsModalRef;
  public modalRefCancelamento: BsModalRef;

  public statusAguardandoXML = [];
  public cnpjsPotspeed = [];
  public loadingCancelamento = false;
  public objetoEstorno: any = {};
  public quantidadePorPagina = 5;
  public skip = 0;
  public totalPaginas = [];
  public total = 0;
  public totalPedidos = 0;
  public fretes = [];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usuarioService: UsuarioService,
    private modalService: BsModalService,
    private storage: LocalStorageService,
    private freteService: FreteService,
    private utilsService: UtilsService

  ) {
    this.user = this.storage.obterUsuario();

  }

  ngOnInit(): void {
    this.obterUsuario();
    this.obterCnpjs();
  }

  async obterCnpjs() {
    this.cnpjsPotspeed = await this.utilsService.obterCnpjsPotSpeed().toPromise();
  }

  obterUsuario() {
    this.reset();


    this.usuarioService.obterUsuario(this.user._id).subscribe( res => {
      this.user = res;
      this.storage.setarUsuario(this.user);

      this.chaves = [];
      this.nomeUsuario = this.user.nome.split(" ")[0];

      this.obterPedidos(0, this.quantidadePorPagina);
      // this.aguardandoXML();
      // this.verificaOrdensFaturadas();
    });


  }

  obterPedidos(skip = 0, limit = 5, statusPedido = null) {
    var chamadasChave = [];
    var pedidos = [];
    var lista = [];
    var aguardandoProcessamento = []
    this.loadingHistorico = true;
    this.pedidosPendentes = [];
    // this.totalPendentes = 0;

    this.usuarioService.obterPedidoPorUsuario(this.user._id, (skip == 0 ? skip : (skip * limit)), limit, statusPedido).subscribe( retorno => {

      var results = retorno.pedidos;
      this.total = retorno.total;
      var totalPaginas = (this.total / limit);
      this.totalPaginas = [];
      this.fretes = retorno.fretes;

      if (!statusPedido) {
        this.totalPedidos = retorno.total;
        this.totalEntregues = retorno.totalEntregues;
        this.totalPendentes = retorno.totalPendentes;
        this.totalCancelados = retorno.totalCancelados;
      }

      for (var i = 0; i < totalPaginas;i++) {

        this.totalPaginas.push(i);
      }
      results.map( (firstItemArray: any) => {
        if (firstItemArray.length > 0) {
          if (firstItemArray[0].length > 0) {
            firstItemArray[0].map( item => {
              lista.push(item)
              // PENDENTES
              if (item.situacao && item.situacao != 'Finalizado' && !item.situacao.toLowerCase().includes('cancelado') ) {
                pedidos.push(item);
                this.pedidosPendentes.push(firstItemArray);
              }
              if (item.situacao.toLowerCase().includes('cancelado')) {
                this.totalCancelados++;
                this.itensCancelados.push(firstItemArray);
              }

              // ENTREGUES
              if (item.situacao.toLowerCase().includes('entregue') || item.situacao.toLowerCase().includes('finalizado')) {
                this.pedidosEntregues.push(firstItemArray);
              }

            });

          } else {
            aguardandoProcessamento.push(firstItemArray);
          }
        }
      });

      results.map( item => {
        if (item[0].length == 0) {
          this.totalAguardandoProcessamento++;
        }
      });

      this.totalItems = results;
      // let cpfsPorOrdemByChave = [];

      // this.user.fretes.map( frete => {
      //   frete.destinos.map( destino => {
      //     if (!destino.pendenteNotaFiscal) {
      //       if (destino.notaFiscal.chave) {
      //         cpfsPorOrdemByChave.push({
      //           chave: destino.notaFiscal.chave,
      //           cpf: destino.notaFiscal.cpfDestinatario
      //         });
      //       }
      //     }
      //   })
      // });
      // cpfsPorOrdemByChave.reverse();

      // this.totalItems.map( item => {
      //   cpfsPorOrdemByChave.map(cpfChave => {
      //     if (item[0].length > 0) {
      //       item[0][0].cpfDestinatario = cpfChave.cpf;
      //     }
      //   })
      // });

      this.listaHistorico = this.totalItems;
      this.carregandoPedidosPendentes = false;
      this.loadingHistorico = false;
      chamadasChave = [];
      this.isLoading = false;

    });
  }

  reset() {
    this.totalAguardandoXML = [];
    this.totalNotasPendentes = 0;
    this.statusAguardandoXML = [];
  }

  aguardandoXML() {
    var total = 0;
    this.totalAguardandoXML = [];

    // this.user.fretes.map( frete => {
    //   frete.valorTotalEstornado = 0;
    //   var arrayCancelados = frete.pedidos.filter(d => d.status == 'cancelado');
    //   arrayCancelados.map( a => {
    //     if (a.valorFrete != undefined) {
    //       frete.valorTotalEstornado += Number(a.valorFrete);
    //     }
    //   });

    //   frete.pedidos.map( destino => {
    //     destino.isLoading = false;
    //     destino.errors = [];
    //     total++;


    //     if (destino.notaFiscal.numero) {
    //       destino.notaFiscal.volumes = [];
    //       for (var i = 0; i < destino.notaFiscal.qtdVolumes; i++) {
    //         destino.notaFiscal.volumes.push(i);
    //       }
    //     }

    //     // COLOCA APENAS OS FRETES NA LISTA QUE POSSUEM ALGUM DESTINO COM NOTA FISCAL PENDENTE
    //     if (this.totalAguardandoXML.indexOf(frete) === -1) {
    //       this.totalAguardandoXML.push(frete)
    //     }

    //     this.statusAguardandoXML.push({
    //       isLoading: false,
    //       error: ''
    //     })
    //     // if (destino.pendenteNotaFiscal) {
    //     // }
    //   });
    //   this.totalAguardandoXML.reverse();
    // });
    this.totalNotasPendentes = total;
  }

  fileChange(frete, destino, event) {
    const filesParaEnviar: Set<File> = new Set();
    destino.isLoading = true;

    for(let i = 0; i < event.target.files.length; i++) {

      if (event.target.files[i].type != "text/xml") {
        destino.errors.push("Arquivo inválido!");
      } else {
        filesParaEnviar.add(event.target.files[i]);
      }
    }

    if ( event.target.files.length > 0) {
      this.fazerUpload(filesParaEnviar, frete, destino);
    } else {
      event.target.value = '';
      destino.isLoading = false;
    }
  }

  async fazerUpload(arquivos, frete?, destino?) {
    let isCotacao = false;
    var progress: any = await this.freteService.uploadSimulacao(arquivos, this.user._id, isCotacao);
    var docs = [];
    let allProgressObservables = [];
    var possuiNotaDiferenteCNPJ = false;
    var possuiDuplicadas = false;
    var valorNotaDiferente = false;

    for (let key in progress) {
      allProgressObservables.push(progress[key].progress);
    }

    forkJoin(allProgressObservables).subscribe(async (end: any) => {
      for (const key in progress) {
        var chave = progress[key].doc.nfeProc.protNFe.infProt.chNFe;
        var cepNota = progress[key].doc.nfeProc.NFe.infNFe.dest.enderDest.CEP;
        var valorNota = progress[key].doc.nfeProc.NFe.infNFe.total.ICMSTot.vNF;
        var uFEmissor = progress[key].doc.nfeProc.NFe.infNFe.emit.enderEmit.UF;
        var cnpjsValidos = [];
        this.cnpjsPotspeed.map( pot => {
          if (pot.UF == uFEmissor) {
            cnpjsValidos.push(pot);
          }
        });
        var mesmaTransportadora = true;
        var cepDiferente = false;

        // MESMO CNPJ NOTA vs CADASTRO
        if (!progress[key].isMesmoCNPJ) {
          possuiNotaDiferenteCNPJ = true;
          destino.errors.push("A nota fiscal não possui o mesmo CNPJ do emissor, por favor envie uma nota fiscal com o CNPJ de sua empresa.");
        }
        // POSSUI ARQUIVO DUPLICADO
        if (progress[key].duplicada) {
          possuiDuplicadas = true;
          destino.errors.push(`A chave "${chave}" já está em uso, por favor utilize outra`);
          var removerArquivo = await this.usuarioService.removerArquivosDoCliente(progress[key].idArquivo, this.user._id).toPromise();
          // SE AO TENTAR APAGAR O ARQUIVO ACONTECEU ALGUM PROBLEMA MOSTRA MENSAGEM PARA TENTAR NOVAMENTE
          if (!removerArquivo.success) {
            destino.errors.push(`Ops, houve um problema com o servidor, tente novamente em 10 minutos por favor!`);
          }
        }
        // CEP DO ARQUIVO É DIFERENTE DO CEP DA NOTA FISCAL
        if (destino.cepDestino != cepNota) {
          cepDiferente = true;
          destino.errors.push(`O CEP: ${cepNota} é diferente do CEP que você realizou a compra, por favor nos envie a nota fiscal com o endereço correto`);
          var removerArquivo = await this.usuarioService.removerArquivosDoCliente(progress[key].idArquivo, this.user._id).toPromise();
          // SE AO TENTAR APAGAR O ARQUIVO ACONTECEU ALGUM PROBLEMA MOSTRA MENSAGEM PARA TENTAR NOVAMENTE
          if (!removerArquivo.success) {
            destino.errors.push(`Ops, houve um problema com o servidor, tente novamente em 10 minutos por favor!`);
          }
        }

        if (parseFloat(destino.valorNf) != parseFloat(valorNota)) {
          var valorNotaValidacao = parseFloat(valorNota).toString().replace(".", ",");
          valorNotaDiferente = true;
          destino.errors.push(`O valor da nota fiscal de R$ ${valorNotaValidacao}  é diferente do valor que você realizou a cotação.`);
          var removerArquivo = await this.usuarioService.removerArquivosDoCliente(progress[key].idArquivo, this.user._id).toPromise();

          if (!removerArquivo.success) {
            destino.errors.push(`Ops, houve um problema com o servidor, tente novamente em 10 minutos por favor!`);
          }
        }

        // mesma transportadora
        if (!mesmaTransportadora) {
          destino.errors.push(`A transportadora informada no XML não pertence a Pot Speed, você deve emitir a nota fiscal com o CNPJ ${cnpjsValidos[0].cnpj}`);
        }

        destino.isLoading = false;

        if (!possuiNotaDiferenteCNPJ && !possuiDuplicadas && !cepDiferente && mesmaTransportadora && !valorNotaDiferente) {
          destino.pendenteNotaFiscal = false;

          var uploadEngloba = await this.usuarioService.uploadEngloba(this.user._id, [chave], frete).toPromise();
          this.usuarioService.atualizarArquivoPendente(this.user._id, frete._id, destino._id, progress[key].idArquivo).toPromise();
        }
      }
    });
  }

  limparErro(erro, erros) {
    let indice = erros.indexOf(erro);

    if (indice > -1) {
      erros.splice(indice, 1);
    }

  }

  limparErros(destino?) {
    destino.errors = [];
  }

  clicouAquiInputFile(event) {
    event.srcElement.value = "";
  }

  abrirModalCancelarFrete(template: TemplateRef<any>, frete, destino) {
    this.objetoEstorno = {
      frete,
      destino
    }
    this.modalRefCancelamento = this.modalService.show(template, {class: 'w-350 modal-dialog-centered', ignoreBackdropClick: true});
  }

  confirmarCancelamento() {
    this.loadingCancelamento = true;

    this.usuarioService.cancelarPedido(this.user._id, this.objetoEstorno.frete._id, this.objetoEstorno.destino._id, 'cancelado', this.user, this.objetoEstorno.frete).subscribe( res => {

      if (res.n == 1) {
        this.objetoEstorno.destino.status = 'cancelado';

        this.usuarioService.solicitarEstorno(this.objetoEstorno.frete.fatura.idCobranca, this.objetoEstorno.destino.valorFrete).subscribe( vindiRes => {
          this.loadingCancelamento = false;
          this.modalRefCancelamento.hide();
        }, erroVindi => {
          this.loadingCancelamento = false;
          this.modalRefCancelamento.hide();
        });
      } else {
        this.loadingCancelamento = false;
        this.modalRefCancelamento.hide();
      }

    }, erroApi => {
      this.loadingCancelamento = false;
      this.modalRefCancelamento.hide();
    });
  }

  fecharModal(template: TemplateRef<any>) {
    this.modalRefCancelamento.hide();
  }

  verificaOrdensFaturadas() {
    this.ordensFaturadas = this.user.fretes;
    this.totalFaturadas = 0;
    this.ordensFaturadas.map( frete => {
      this.totalFaturadas += parseFloat(frete.fatura.amount);
    });
  }

  mostrarEnderecoCompleto(destino) {
		this.usuarioService.buscarEnderecoByCEP(destino.cepDestino).subscribe( res => {
			destino.enderecoCompleto = `${res.city} - ${res.state}`
		});
	}

  onChangeQuantidadePagina(value) {
    this.skip = 0;
    this.quantidadePorPagina = value;

    this.obterPedidos(this.skip, Number(value));
  }

  anterior() {
    if (this.skip > 0) {
      this.skip--
      this.obterPedidos(this.skip, this.quantidadePorPagina);
    }
  }

  proximo() {

    this.skip++
    this.obterPedidos(this.skip, this.quantidadePorPagina);
  }

  irParaPagina(pagina) {
    this.skip = pagina;
    this.obterPedidos(this.skip, this.quantidadePorPagina);
  }

  imprimirEtiqueta(numeroPedido, codigoBarra?) {

    window.open(`http://localhost:4200/#/etiqueta/${this.user._id}/${numeroPedido}`, '_blank');
  }
  abrirFormularioDeclaracao(frete, destino) {
    const idCliente = this.user._id;
    const idFrete = frete._id;
    const idDestino = destino._id;

    this.router.navigate(['/cliente/declaracao-conteudo'], { state: {metaDeclaracao: {idCliente, idFrete, idDestino, destino }} });
  }
  imprimirDeclaracao(numeroPedido) {
    window.open(`http://localhost:4200/#/declaracao-conteudo/${this.user._id}/${numeroPedido}`, '_blank');
  }
  teste(array) {
    var retorno = [];
    array.map( a => retorno.push(a));
    return retorno;
  }
  prazoExpirado(frete) {
    var dataFrete: any = new Date(frete.data);
    var hoje : any = new Date();

    var diffTime = Math.abs(hoje - dataFrete);
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // Se a diferença de dias for maior que 3 então não habilita o botão estornar
    return diffDays > 3 ? false : true;
  }
}






