<div class="row">
    <div class="col text-right px-5 py-4">
        <p>É cliente ChegaLog? <a href="javascript:void(0);" (click)="acessarLogin();">Acessar</a></p>
    </div>
</div>

<div class="row">
    <div class="col text-center">
        <h4>Seja bem-vindo(a) ao ChegaLog, estamos prontos para de atender,<br>
            crie seu cadastro grátis e simule seus fretes.</h4>
    </div>
</div>

<div class="row justify-content-center">
    <div class="col-12 col-sm-5">
        <div class="py-5">
            <form [formGroup]="cadastroForm" (ngSubmit)="onSubmit(cadastroForm.value)">
                <div class="form-group">
                    <label for="nomeEmpresa">Nome fantasia da empresa *</label>
                    <input type="text" class="form-control" id="nomeEmpresa" placeholder="Nome da empresa"
                        formControlName="nomeEmpresa" required
                        [ngClass]="{ 'is-invalid': cadastroForm.controls.nomeEmpresa.errors && (cadastroForm.controls.nomeEmpresa.dirty || cadastroForm.controls.nomeEmpresa.touched) }">
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="nome">Nome Completo *</label>
                            <input type="text" class="form-control" id="nome"
                                placeholder="Nome completo do representante da empresa" formControlName="nome" required
                                [ngClass]="{ 'is-invalid': cadastroForm.controls.nome.errors && (cadastroForm.controls.nome.dirty || cadastroForm.controls.nome.touched) }">
                        </div>
                    </div>
                </div>

                <div class="form-group">
                  <label for="comoConheceu">Como conheceu o ChegaLog? *</label>
                  <select class="form-control" id="comoConheceu" formControlName="comoConheceu"
                      placeholder="Selecione" required
                      [ngClass]="{ 'is-invalid': cadastroForm.controls.comoConheceu.errors && (cadastroForm.controls.comoConheceu.dirty || cadastroForm.controls.comoConheceu.touched) }">

                      <option value="1" disabled selected>Selecione</option>
                      <ng-container *ngFor="let comoConheceu of comoConheceuOpcoes">
                        <option [value]="comoConheceu.id">{{ comoConheceu.descricao }}</option>
                      </ng-container>
                  </select>
                </div>

                <div class="row" *ngIf="cadastroForm.value.comoConheceu === 'e9b26ff8-b1fa-11ed-b5cd-123e30198a11'">
                    <div class="col d-flex text-center justify-content-center" *ngIf="loadingSuporte">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div class="col" *ngIf="!loadingSuporte">
                        <div class="form-group">
                            <label for="nomeRepresentante">Representante</label>

                            <select class="form-control" id="nomeRepresentante" formControlName="nomeRepresentante" placeholder="Selecione" required [ngClass]="{ 'is-invalid': cadastroForm.controls.nomeRepresentante.errors && (cadastroForm.controls.nomeRepresentante.dirty || cadastroForm.controls.nomeRepresentante.touched) }">
                                <option value="1" disabled selected>Selecione</option>
                                <ng-container *ngFor="let representante of representanteOpcoes">
                                    <option [value]="representante.id">{{ representante.nome }}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="cadastroForm.value.comoConheceu === 'e9b274da-b1fa-11ed-b5d3-123e30198a11'">
                    <div class="col">
                        <div class="form-group">
                        <label for="nomeRepresentante">Quem indicou?</label>
                        <input type="text" class="form-control" id="nomeRepresentante" placeholder="Nome" formControlName="nomeRepresentante"
                        [ngClass]="{ 'is-invalid': cadastroForm.controls.nomeRepresentante.errors && (cadastroForm.controls.nomeRepresentante.dirty || cadastroForm.controls.nomeRepresentante.touched) }">
                        </div>
                    </div>
                </div>

                <!-- <div class="form-group">
                    <label for="nomeRepresentante">Nome do Representante *</label>
                    <input type="text" class="form-control" id="nomeRepresentante"  placeholder="Nome do representante" formControlName="nomeRepresentante">
                </div> -->

                <div class="form-group">
                    <label for="celular">Celular *</label>
                    <input type="text" class="form-control" id="celular" placeholder="Celular" formControlName="celular"
                        required mask="(00) 000000009"
                        [ngClass]="{ 'is-invalid': cadastroForm.controls.celular.errors && (cadastroForm.controls.celular.dirty || cadastroForm.controls.celular.touched) }">
                </div>
                <div class="form-group">
                    <label for="email">E-mail *</label>
                    <input type="email" class="form-control" id="email" placeholder="E-mail" formControlName="email"
                        required
                        [ngClass]="{ 'is-invalid': cadastroForm.controls.email.errors && (cadastroForm.controls.email.dirty || cadastroForm.controls.email.touched) }">
                    <div class="invalid-feedback"
                        *ngIf="cadastroForm.controls.email.errors && (cadastroForm.controls.email.dirty || cadastroForm.controls.email.touched)">
                        Por favor preencha um e-mail válido
                    </div>
                </div>

                <div class="form-group">
                    <label for="senha">Senha *</label>
                    <div class="input-group">
                        <input type="{{ visiblePassword ? 'text' : 'password'}}" class="form-control border-right-0"
                            id="senha" placeholder="Senha" formControlName="senha" required minlength="6"
                            [ngClass]="{ 'is-invalid': cadastroForm.controls.senha.errors && (cadastroForm.controls.senha.dirty || cadastroForm.controls.senha.touched) }">
                        <!-- <span class="input-group-append">
                            <button class="btn btn-outline-secondary bg-white border-0" type="button">
                                <i class="material-icons">delete</i>
                            </button>
                          </span> -->
                        <span class="input-group-append">
                            <div class="input-group-text bg-white">
                                <i class="material-icons no-select icone-olhinho"
                                    (click)="visiblePassword = !visiblePassword"
                                    *ngIf="!visiblePassword">remove_red_eye</i>
                                <i class="material-icons no-select icone-olhinho"
                                    (click)="visiblePassword = !visiblePassword"
                                    *ngIf="visiblePassword">visibility_off</i>
                            </div>
                        </span>
                    </div>
                    <small class="m-0 text-muted">Mínimo de 6 dígitos + 1 letra maiúscula + 1 caracter especial</small>
                </div>

                <div class="text-center">
                    <button type="submit" class="btn btn-primary btn-lg btn-block"
                        [disabled]="!cadastroForm.valid || isLoading" id="lead">CONTINUAR</button>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #temmplateModalEmailExistente>
  <div class="modal-header">
      <h4 class="modal-title pull-left">E-mail já cadastrado</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="row align-items-center">
          <div class="col">
              <p>
                  O e-mail informado já consta associado a uma conta em nossa base de dados. Caso você queira
                  acessar a sua conta, por favor, clique em <a href="javascript:void(0);" (click)="acessarLogin();">Acessar</a> para realizar o login.
              </p>

              <p>
                Se você não se lembra da sua senha, não se preocupe! Você pode recuperá-la acesando a área de
                <a href="javascript:void(0);" (click)="acessarEsqueciMinhaSenha();">Esqueci Minha Senha</a>.
              </p>

              <p>Caso deseje seguir com esse cadastro, por favor, informe um novo endereço de e-mail</p>
          </div>
      </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="cancelarModal()">OK, ENTENDI</button>
  </div>
</ng-template>
