import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EtiquetaService } from '../services/etiqueta/etiqueta.service';

@Component({
  selector: 'app-etiqueta',
  templateUrl: './etiqueta.component.html',
  styleUrls: ['./etiqueta.component.scss']
})
export class EtiquetaComponent implements OnInit {
  public exibirEtiquetas = false;
  public etiquetas = [];
  public freteId;
  public pedidoNaoEncontrado;

  constructor(
    private readonly etiquetaService: EtiquetaService,
    private readonly route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => this.freteId = params.freteId);
  }

  public ngOnInit() {
    this.obterEtiquetas();
  }

  private obterEtiquetas() {
    this.etiquetaService.obterPorFreteId(this.freteId).subscribe({
      next: (etiquetas) => {
        this.etiquetas = etiquetas;

        this.exibirEtiquetas = true;
        setTimeout(() => window.print(), 500);
      },
      error: () => this.pedidoNaoEncontrado = true
    });
  }
}
