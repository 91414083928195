import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DeclaracaoConteudoService } from './../services/declaracao-conteudo/declaracao-conteudo.service';

@Component({
  selector: 'app-declaracao-conteudo',
  templateUrl: './declaracao-conteudo.component.html',
  styleUrls: ['./declaracao-conteudo.component.scss']
})
export class DeclaracaoConteudoComponent implements OnInit {
  public exibirDeclaracaoConteudo = false;
  public faturaId;
  public freteId;
  public pedido;
  public pedidoNaoEncontrado;
  public pesoTotalItens = 0;
  public itensTotais = 0;
  public valorTotalItens = 0;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly declaracaoConteudoService: DeclaracaoConteudoService
  ) {
    this.route.params.subscribe(params => {
      this.faturaId = params.faturaId;
      this.freteId = params.freteId;
    });
  }

  public ngOnInit() {
      this.obterDeclaracaoConteudo();
  }

  private obterDeclaracaoConteudo() {
    const declaracaoConteudoRequest = { faturaId: this.faturaId, freteId: this.freteId };
    this.declaracaoConteudoService.buscarPorFreteOuFaturaId(declaracaoConteudoRequest)
      .subscribe({
        next: (pedido) => {
          this.pedido = pedido;
          pedido.identificacaoBens.forEach(item => {
            this.itensTotais += item.quantidade;
            this.valorTotalItens += item.valor;
            // this.pesoTotalItens += item.peso;
          });

          this.exibirDeclaracaoConteudo = true;
          setTimeout(() => window.print(), 500);
        },
        error: () => this.pedidoNaoEncontrado = true
      });
  }
}
