
<div class="container-cadastro">

    <div class="lateral">
        <a href="/">
            <img src="/assets/images/chegalog.svg" height="50" >
        </a>

        <img class="imagem-lateral" src="/assets/images/hero-image1.png" width="100%">
    </div>
    <div class="main">
        <router-outlet></router-outlet>
    </div>
</div>