import { PagamentoService } from './../../services/pagamento/pagamento.service';
import {Component, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Frete} from 'src/app/interfaces/frete';
import {LocalStorageService} from 'src/app/services/localStorage/localstorage.service';
import {UsuarioService} from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss']
})
export class PagamentoComponent implements OnInit {
  public isCollapsed = false;
  public radioModel = 0;
  public cartaoForm;
  public coletas: any[];
  public usuario;
  public isLoading: boolean = false;
  public mensagemErro = "";
  public pagamentoRealizado = false;
  public total = 0;
  public modalRef: BsModalRef;
  public dataPedido = new Date();
  public aceiteTermo = true;
  public expandido = false;
  public frete: Frete;
  public dadosFrete: any; // SÓ É PREENCHIDO DEPOIS QUE FOI REGISTRADO
  public pontosPegaki = [];
  public zona = 'Leste';

  private chegaLogIds = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService,
    private usuarioService: UsuarioService,
    private modalService: BsModalService,
    private pagamentoService: PagamentoService
  ) {
    this.cartaoForm = this.formBuilder.group({
      nomeCartao: '',
      numeroCartao: ['',
        [
          Validators.required,
          Validators.pattern(/(\d{4}[-. ]?){4}|\d{4}[-. ]?\d{6}[-. ]?\d{5}/)
        ]
      ],
      vencimento: ['',
        [
          Validators.required,
          // PERMITE APENAS VENCIMENTOS ACIMA DE 2020
          Validators.pattern(/(0[1-9]|10|11|12)\/20[2-9][0-9]$/)

        ]
      ],
      cvv: '',
      parcelas: ['1'],
      bandeira: ['', Validators.required,]
    });
    this.usuario = this.localStorage.obterUsuario();

    let coletas = this.localStorage.obterColetas();
    this.frete = this.localStorage.obterFrete();

    if (!coletas) {
      this.voltar();
    } else {
      this.coletas = coletas;

      this.coletas.map( x => {
        this.total += parseFloat(x.valorFrete.valor);
        x.valorFrete.valor = parseFloat(x.valorFrete.valor);
      });
    }
  }

  ngOnInit(): void {
    this.verificaSePossuiIdVindi();
    this.varificaSePossuiCastroVindi(this.usuario.email);
    this.obterPontosPegaki();

    this.total = 0;
    this.coletas.map( x => {
      this.total += parseFloat(x.valorFrete.valor);
      x.valorFrete.valor = parseFloat(x.valorFrete.valor);
    });
  }
  obterPontosPegaki() {
    this.usuarioService.obterPontosPegaki(this.zona).subscribe( res => {
      this.pontosPegaki = res;
    });

  }
  verificaSePossuiIdVindi() {
    this.usuarioService.verificaVindi(this.usuario.email).subscribe( res => {
      if (res.customers.length > 0) {
        let idVindi = res.customers[0].id;
        if (this.usuario.idVindi != idVindi) {
          this.usuario.idVindi = res.customers[0].id
          this.localStorage.setarUsuario(this.usuario);
        }
      }
    });
  }
  async varificaSePossuiCastroVindi(email) {
    var retorno = await this.usuarioService.verificaVindi(email).toPromise();
    return retorno.customers.length > 0
  }
  voltar() {
    this.router.navigateByUrl('/simulacao/cotacao');
  }
  novaSimulacao() {
    this.voltar();
  }
  onSubmit(data) {
    console.log(data);
  }
  resetarMensagens() {
    this.pagamentoRealizado = false;
    this.mensagemErro = '';
  }
  async pagar(modal) {
    this.isLoading = true;
    this.resetarMensagens();

    this.modalRef = this.modalService.show(modal, {class: 'w-500 center', ignoreBackdropClick: true, keyboard: false});

    let tot = 0;

    this.coletas.map( x => {
      tot += x.valorFrete.valor;
    });

    // let product_id = 596773;
    let product_id = 88083;  // SANDBOX
    // let product_id = 748393; // PRODUÇÃO

    this.coletas.forEach((coleta: any) => this.chegaLogIds.push(coleta.id));
    this.chegaLogIds = [...new Set(this.chegaLogIds)]; // Evitar duplicidade

    let dadosPagamento = {
      holder_name: this.cartaoForm.value.nomeCartao,
      card_expiration: this.cartaoForm.value.vencimento,
      card_number: this.cartaoForm.value.numeroCartao,
      card_cvv: this.cartaoForm.value.cvv,
      payment_method_code: "credit_card",
      payment_company_code: this.cartaoForm.value.bandeira,
      installments: this.cartaoForm.value.parcelas,
      bill_items: [
        {
          product_id: product_id,
          amount: tot
        }
      ],
      chegaLogIds: this.chegaLogIds,
      UseContentDeclaration: this.frete.tipoDocumentoFiscal == 'declaracao'
    };

    console.log('ChegalogIds', this.chegaLogIds)

    try {
      let ret = await this.pagamentoService.sendPayment(dadosPagamento);
      this.localStorage.setarFatura(ret.FaturaId);
      console.log(">>> ret.FaturaIdt = ", ret.FaturaId);
      await this.pagamentoService.sendFile(ret.FaturaId);
  
      if (ret.Mensagem) {
        throw new Error(ret.Mensagem);
      }
      this.pagamentoRealizado = true;
      this.isLoading = false;

      this.modalRef.hide();
    } catch (e) {
      console.log(e);
      this.isLoading = false
      this.pagamentoRealizado = false;
      this.mensagemErro = "Não foi possível prosseguir por favor recarregue a pagina, verifique os dados e tente novamente!";
      setTimeout(() => {
        this.modalRef.hide();
      }, 5000); 
    }
  }
  /**
   * Metodo que registra um token na Vindi enviando os dados para a nossa API
   * @param idVindi
   * @param gatewayToken
   * @param holder_name
   * @param item
   */
  registrarToken(idVindi, gatewayToken, holder_name, item, parcelas) {
    this.usuarioService.registrarToken({
      idClienteVindi: idVindi,
      gatewayToken: gatewayToken,
      holder_name: holder_name
    }).subscribe( (response) => {
      this.executarPagamento(idVindi, item, parcelas);
    }, (error) => {
      this.isLoading = false;
      this.mensagemErro = "Não foi possível prosseguir por favor recarregue a pagina e tente novamente!";
    });
  }
  executarPagamento(idVindi, item, parcelas) {
    this.usuarioService.pagamento(idVindi, item, parcelas).subscribe( async (pagamento) => {
      // var chaves = this.localStorage.obterColetas().map(({id}) => id);
      // var coletas = this.localStorage.obterColetas();

      if (pagamento.errors) {
        this.pagamentoRealizado = false;
        this.mensagemErro = "Não foi possível prosseguir por favor recarregue a pagina e tente novamente!";
        this.isLoading = false;
      }

      if (pagamento.bill) {
        if (pagamento.bill.status == "paid") {
          this.pagamentoRealizado = true;
          // REGISTRA A FATURA NO BANCO

          await this.usuarioService.atualizarFaturas(this.usuario.id, pagamento.bill, this.frete).subscribe( res => {

            if (res.success) {

              this.dadosFrete = res.frete;
              // SE AO ATUALIZAR A FATURA ATUALIZOU NOVOS ARQUIVOS ENTÃO ENVIA SOLICITAÇÃO DE UPLOAD PARA ENGLOBA;
              if (res.arquivos.length > 0) {
                // SOLICITA UPLOAD NA ENGLOBA
                let chaves = [];
                res.arquivos.map( ar => {
                  if (!ar.pendenteNotaFiscal) {
                    chaves.push(ar.chave);
                  }
                });
                this.usuarioService.uploadEngloba(this.usuario.id, chaves, res.frete).subscribe( retornoUpload => {
                  if (retornoUpload.listaArquivos) {
                    retornoUpload.listaArquivos.map( async (item) => {
                      await this.usuarioService.atualizarArquivoPendente(this.usuario.id, res.frete._id, item.idPedido, item.idArquivo).toPromise();
                    });
                  }
                  this.finalizar(res.user);
                }, (err) => {
                  debugger;
                  this.isLoading = false;
                  this.pagamentoRealizado = false;
                  this.mensagemErro = "Upload Engloba Failed";
                  this.modalRef.hide();
                });

              } else {
                this.finalizar(res.user);
              }
            }
          }, (err) => {
            this.isLoading = false;
            this.pagamentoRealizado = false;
            this.mensagemErro = "Não foi possível registrar a cobrança!";
            this.modalRef.hide();
          });

          let senha = await this.usuarioService.getSenha(this.usuario.senha, this.usuario.id).toPromise();
          // CADASTRAR ENGLOBA
          this.usuarioService.cadastrarEngloba(this.usuario, this.usuario.codigoEngloba, senha ).subscribe( resCadastro => {

            if (resCadastro.codigo_cliente) {
              this.usuarioService.atualizaIdEngloba(this.usuario.id, resCadastro.codigo_cliente);
            }
            // var restornoCadastro = resCadastro;
          }, erro => {
            debugger;
            // this.isLoading = false;
          });

        } else {
          this.isLoading = false;
          this.pagamentoRealizado = false;
          this.mensagemErro = pagamento.bill.status;
        }
      }
    }, (error) => {
      this.isLoading = false;
      this.mensagemErro = "Não foi possível prosseguir por favor recarregue a pagina e tente novamente!";
    });
  }
  finalizar(usuario) {
    this.localStorage.setarUsuario(usuario);
    this.pagamentoRealizado = true;
    this.isLoading = false;
    this.modalRef.hide();
    this.localStorage.limparFrete();
  }
  pagarFake() {
    var chaves = this.localStorage.obterColetas().map(({id}) => id);

    this.pagamentoRealizado = true;
    // SOLICITA UPLOAD NA ENGLOBA
    this.usuarioService.uploadEngloba(this.usuario.id, chaves).subscribe( res => {
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      this.pagamentoRealizado = false;
      this.mensagemErro = "Falha no upload";
    });

  }
  cancelar() {
    this.router.navigateByUrl('/simulacao/file');
  }
  voltarSimulacao() {
    this.router.navigateByUrl('/simulacao/file');
  }
  abrirModalTermo(template: TemplateRef<any> ) {
    this.modalRef = this.modalService.show(template);
  }
  fecharModalTermo() {
    this.modalRef.hide();
  }

  goPainel(){
    this.router.navigateByUrl('/cliente/painel');
  }

  abrirModalImprimirEtiqueta(template: TemplateRef<any> ) {
    this.modalRef = this.modalService.show(template, {class: 'w-450'});
  }

  imprimir(){
    this.modalRef.hide();
    window.open('http://localhost:4200/#/etiqueta', '_blank');
  }

  abrirDeclaracaoConteudo(){
    window.open('http://localhost:4200/#/declaracao-conteudo', '_blank');
  }
  onChangeSelect(event) {
    this.obterPontosPegaki();
  }

}
